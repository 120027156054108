import { Navigate } from 'react-router-dom';

import { Loader } from '@components/Loader';
import { useAuth } from '@hooks/useAuth';
import { useVendorOnboardingState } from '@hooks/Vendor';
import { UserOnboardingState } from '@lib/firebase';
import { vendorRoutes } from '@routes/vendor';

import { VendorNavigation } from './Navigation';

/**
 * Checks whether the vendor is in a state that requires further onboarding.
 * @param onboardingState Current onboarding state of the vendor
 */
const requiresOnboarding = (onboardingState?: UserOnboardingState) =>
  onboardingState === UserOnboardingState.NotStarted ||
  onboardingState === UserOnboardingState.FinishedStep1;

export const AuthorizedRoute = ({ children }: { children: any }) => {
  const { currentUser } = useAuth();
  // TODO: If onboarding state is Done, set onto current user and skip this check later on
  const { data: onboardingState, isLoading } = useVendorOnboardingState();

  // No user logged in.
  if (!currentUser) {
    return <Navigate to={vendorRoutes.login.path} />;
  }

  // Registered user logged in but email not verified yet.
  // Always true for Google accounts.
  if (!currentUser.emailVerified) {
    return <Navigate to={vendorRoutes.login.path} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (requiresOnboarding(onboardingState)) {
    return <Navigate to={vendorRoutes.onboarding.path} />;
  }

  return <VendorNavigation>{children}</VendorNavigation>;
};
