import { StatusTag } from '@components/StatusTag';
import { useJobStatusStyle } from '@hooks/Job/useJobStatus';
import { JobStatus } from '@lib/firebase';

export interface BookingStatusProps {
  status: JobStatus;
}

/**
 * Returns Booking list item
 */
export const BookingStatus = ({ status }: BookingStatusProps) => {
  const { color, backgroundColor } = useJobStatusStyle(status);

  return <StatusTag text={status} color={color} backgroundColor={backgroundColor} />;
};
