import { useContext, useEffect } from 'react';

import { firebaseApp, firebaseAuth } from '@lib/firebase';

import { AuthContext } from '../providers/AuthProvider';
import { useLocalStorage } from './useLocalStorage';

export const useAuth = () => useContext(AuthContext);

/**
 * Listens for any changes from Firebase Auth and updates local storage accordingly.
 */
export const useAuthListener = () => {
  const [currentUser, setCurrentUser] = useLocalStorage('authUser', null);

  useEffect(() => {
    const listener = firebaseAuth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setCurrentUser(authUser);
      } else {
        console.log('No auth user found. Logging out.');
        setCurrentUser(null);
      }
    });

    return () => listener?.();
  }, [firebaseApp]);

  return { currentUser, setCurrentUser };
};
