import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Timestamp } from 'firebase/firestore';
import * as R from 'ramda';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { FormAddress } from '@components/Address';
import { Card } from '@components/Card';
import { ContactUs } from '@components/Contact';
import { Stepper } from '@components/Stepper';
import { FormSwitch } from '@components/Switch';
import { USE_MOCKED_ADDRESSES } from '@config/address';
import { useCustomer } from '@hooks/Customer';
import { useCreateJob } from '@hooks/Job';
import { useSnackbar } from '@hooks/useSnackbar';
import { Address, Category, JobField, RateMode } from '@lib/firebase';
import { ICreateJob } from '@lib/firestore/Job';
import { getCoordinatesFromAddress } from '@lib/googleApi';
import { customerRoutes } from '@routes/customer';
import { Color } from '@theme/palette';

import { BookingDisclaimer } from '../components/BookingDisclaimer';
import { BookingOverview } from '../components/BookingOverview';
import { BookingDateTime } from '../components/DateTime';
import { BookingServices } from '../components/Services';
import { AvailableVehicles, PreferredVehicles } from '../components/VehicleList';
import { ILocationForm, IServiceForm, IVehicleForm, SHOW_HOURLY_RATE_FORM_FIELD } from '../fields';

// Render first step on load
// Allows easier testing of each step without going through entire flow
const ACTIVE_STEP = 0;
const DISTANCE_IN_KM = 200;

// Purely for testing to skip first two steps! - Clear when done
const DEFAULT_SERVICE_VALUES = {
  selected_categories: [{ id: '1PcekWDBd5M2WfL8Bb2P', name: 'ICI - Construction', parent: null }],
};
const DEFAULT_LOCATION_VALUES = {
  scheduled_time: new Date('2024-07-26T11:00:00.000Z'),
  scheduled_end_time: new Date('2024-07-28T11:00:00.000Z'),
  address1: '25 Ordnance Street',
  country: 'CA',
  state: 'ON',
  postal_code: 'M6K 1A1',
  city: 'Toronto',
  address2: '',

  // Matches Willow park coordinates
  lat: 50.9598522,
  lng: -114.0476536,
  geohash: 'c3ncuucn9h',
};

export const RequestBooking = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { data: currentCustomer, isLoading: isLoadingCustomer } = useCustomer();

  // Form hooks for each step
  const locationDateForm = useForm<ILocationForm>({
    mode: 'onSubmit',
    // defaultValues: DEFAULT_LOCATION_VALUES,
  });

  const serviceForm = useForm<IServiceForm>({
    mode: 'onSubmit',
    defaultValues: {
      [JobField.IsUnionized]: false,
      // ...DEFAULT_SERVICE_VALUES,
    },
  });

  const vehicleForm = useForm<IVehicleForm>({ mode: 'onSubmit' });

  // Watches toggle for showing daily rate
  const shouldShowDailyRate = vehicleForm.watch(SHOW_HOURLY_RATE_FORM_FIELD);

  // Below is just for testing the last step! Remove when done
  const [jobAddress, setJobAddress] = useState<Address>(/*DEFAULT_LOCATION_VALUES*/);
  const [selectedServices, setSelectedServices] = useState<Category[]>();
  //DEFAULT_SERVICE_VALUES.selected_categories,

  // Used for creating a job
  const { submit: createJob } = useCreateJob();

  // Runs when selecting preferred service(s) (second step)
  const handleServiceSubmit = async (data: IServiceForm) => {
    const selectedServices = data.selected_categories || [];
    if (selectedServices.length <= 0) {
      // Returning these will show an error message on the form (see Stepper component)
      return new Error('Please select at least 1 service');
    }

    setSelectedServices(selectedServices);

    // Only calculate address after user has selected a service so we don't over-use Google API fetching coordinates
    // TODO: Manually remove scheduled times as they're in the same form. Should be separated to avoid this!
    const address = R.omit(
      [JobField.ScheduledTime, JobField.ScheduledEndTime],
      locationDateForm.getValues(),
    );
    const coordinates = await getCoordinatesFromAddress(address, USE_MOCKED_ADDRESSES);

    if (!coordinates) {
      // Returning these will show an error message on the form (see Stepper component)
      return new Error('Please provide a valid address');
    }

    // Due to the way react-hook-form works, we need to convert the string values to numbers.
    // Currently the Address component returns strings.
    setJobAddress({ ...address, ...coordinates });
  };

  // Runs when submitting entire form (last step)
  const onFinish = async () => {
    const locationDate = locationDateForm.getValues();
    const services = serviceForm.getValues();
    const vehicle = vehicleForm.getValues();
    console.log('vehicle: ', vehicle);

    if (!jobAddress) {
      return enqueueSnackbar('Please provide a valid address', { variant: 'error' });
    }

    if (!vehicle.vehicle_id) {
      return enqueueSnackbar('Please select a vehicle', { variant: 'error' });
    }

    const values: ICreateJob = {
      selected_categories: services.selected_categories,
      vehicle_id: vehicle.vehicle_id,
      is_unionized: Boolean(services.is_unionized),
      dump_onsite: Boolean(services.dump_onsite),
      water_on_site: Boolean(services.water_on_site),
      remote_hose: Boolean(services.remote_hose),
      other_conditions: services.other_conditions,
      payment_terms: services.payment_terms,
      scheduled_time: Timestamp.fromDate(locationDate.scheduled_time),
      scheduled_end_time: Timestamp.fromDate(locationDate.scheduled_end_time),
      location: jobAddress,

      // Determine rate mode based on user selection. Mainly useful for invoicing
      rateMode: shouldShowDailyRate ? RateMode.Daily : RateMode.Hourly,
    };

    await createJob(values, {
      onCompleted: () => {
        navigate(customerRoutes.home.getPath());
      },
      onError: (error: Error) => {
        enqueueSnackbar(`An error occurred trying to request a service: ${error.message}`, {
          variant: 'error',
        });
      },
    });
  };

  /**
   * Steps definition for onboarding.
   * Includes which components to render when.
   */
  const steps = [
    {
      label: 'Date & Location',
      form: {
        // No need to handle anything onSubmit besides setting the address
        useForm: locationDateForm,
      },
      component: (
        <Card>
          <Stack direction="column" gap={3}>
            <Stack direction="column" gap={1}>
              <Typography variant="h4">Job date & time</Typography>
              <BookingDateTime />
            </Stack>

            <Stack direction="column" gap={1}>
              <Typography variant="h4">Job location</Typography>
              <FormAddress useAutoComplete />
            </Stack>

            <BookingDisclaimer />
          </Stack>
        </Card>
      ),
    },
    {
      label: 'Service(s)',
      form: {
        useForm: serviceForm,
        onSubmit: handleServiceSubmit,
      },
      component: (
        <Card>
          <Stack gap={1} direction="column">
            <Typography variant="h4">Requested Service(s)</Typography>
            <BookingServices />
            <BookingDisclaimer />
          </Stack>
        </Card>
      ),
    },
    {
      label: 'Pick Hydrovac',
      form: {
        // No need to handle anything onSubmit besides setting the vehicle ID
        useForm: vehicleForm,
      },
      component: (
        <Stack gap={2}>
          <Typography variant="h4">Booking Summary</Typography>
          <BookingOverview
            service={serviceForm.getValues()}
            locationDate={locationDateForm.getValues()}
          />

          {/** Show either daily or hourly rates */}
          <FormSwitch id={SHOW_HOURLY_RATE_FORM_FIELD} label="Show daily rates" />

          {/* Only show preferred vehicles if customer has a preferred vendor */}
          {jobAddress &&
            selectedServices &&
            selectedServices.length > 0 &&
            currentCustomer?.preferred_vendor_id && (
              <Stack gap={2}>
                <PreferredVehicles
                  jobLocation={jobAddress}
                  distanceInKm={DISTANCE_IN_KM}
                  vendorId={currentCustomer?.preferred_vendor_id}
                  showDayRate={shouldShowDailyRate}
                  // TODO: Move into reusable function
                  scheduledTime={locationDateForm.getValues().scheduled_time}
                  scheduledEndTime={locationDateForm.getValues().scheduled_end_time}
                />
                <Divider />
              </Stack>
            )}

          <Typography variant="h4">All available vehicles (within {DISTANCE_IN_KM}km)</Typography>
          {!isLoadingCustomer && jobAddress && selectedServices && selectedServices.length > 0 && (
            <AvailableVehicles
              jobLocation={jobAddress}
              vendorIds={currentCustomer?.locked_vendor_ids}
              distanceInKm={DISTANCE_IN_KM}
              showDayRate={shouldShowDailyRate}
              // TODO: Move into reusable function
              scheduledTime={locationDateForm.getValues().scheduled_time}
              scheduledEndTime={locationDateForm.getValues().scheduled_end_time}
            />
          )}

          <Box
            sx={{
              border: `1px solid ${Color.Gray3}`,
              borderRadius: '8px',
              backgroundColor: Color.DisclaimerGray,
              padding: 2,
            }}
          >
            By clicking "Finish", you agree to be bound by our{' '}
            <Link to={customerRoutes.termsOfService.path}>Terms of Service</Link> and{' '}
            <Link to={customerRoutes.privacyPolicy.path}>Privacy Policy</Link>
            . Once the vendor has accepted your request, you agree to pay a 4hr deposit at the
            posted hourly rate +2% +HST which is refunded in full if you cancel at least 24 hours
            prior to the scheduled start time or if the vendor cancels at any time. <br />
            <br /> Payments can be made via etransfer, credit card (a non-refundable 3% processing
            fee applies), or direct deposit. For any questions or direct support, please email{' '}
            <ContactUs />.
          </Box>
        </Stack>
      ),
    },
  ];

  return (
    <Box>
      <Stepper activeStep={ACTIVE_STEP} steps={steps} onFinish={onFinish} />
    </Box>
  );
};
