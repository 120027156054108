import { Navigate } from 'react-router-dom';

import { Loader } from '@components/Loader';
import { useCustomerOnboardingState } from '@hooks/Customer';
import { useAuth } from '@hooks/useAuth';
import { UserOnboardingState } from '@lib/firebase';
import { customerRoutes } from '@routes/customer';

import { CustomerNavigation } from './Navigation';

/**
 * Checks whether the customer is in a state that requires further onboarding.
 * @param onboardingState Current onboarding state of the customer
 */
const requiresOnboarding = (onboardingState?: UserOnboardingState) =>
  onboardingState !== UserOnboardingState.FinishedStep3;

export const AuthorizedRoute = ({ children }: { children: any }) => {
  const { currentUser } = useAuth();

  // TODO: If onboarding state is Done, set onto current user and skip this check later on
  const { data: onboardingState, isLoading } = useCustomerOnboardingState();

  // No user logged in.
  if (!currentUser) {
    return <Navigate to={customerRoutes.login.path} />;
  }

  // Registered user logged in but email not verified yet.
  // Always true for Google accounts.
  if (!currentUser.emailVerified) {
    return <Navigate to={customerRoutes.login.path} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (requiresOnboarding(onboardingState)) {
    return <Navigate to={customerRoutes.onboarding.path} />;
  }

  return <CustomerNavigation>{children}</CustomerNavigation>;
};
