import Button, { ButtonProps } from '@mui/material/Button';

export const PrimaryButton = ({
  children,
  loading = false,
  ...props
}: ButtonProps & { loading?: boolean }) => (
  <Button color="primary" variant="contained" disabled={loading} {...props}>
    {children}
  </Button>
);
