import Box, { BoxProps } from '@mui/material/Box';

import { Color } from '@theme/palette';

export enum StatusTagType {
  Success = 'success',
  Error = 'error',
  Warn = 'warn',
}

type StatusTagStyleProps = {
  /**
   * Optional custom color to display.
   */
  color?: string;

  /**
   * Optional custom background color to display.
   */
  backgroundColor?: string;

  /**
   * Predefined types to avoid redundant styling that gets reused frequently.
   */
  type?: StatusTagType;
};

type StatusTagProps = StatusTagStyleProps & {
  /**
   * Optional className to customize component.
   */
  className?: string;

  /**
   * Text value to display.
   */
  text?: string;

  /**
   * Display a diferent value depending on a specific condition.
   * Every condition needs to be unique or the first match will be used.
   */
  conditions?: (StatusTagStyleProps & {
    /**
     * Function deciding when to display this value.
     */
    match: () => boolean;
  })[];
};

/**
 * Predefined styled types avoiding redundant styling.
 */
const PREDEFINED_TYPES: { [type in StatusTagType]: { color: string; backgroundColor: string } } = {
  success: { color: Color.Green, backgroundColor: Color.BackgroundGreen },
  error: { color: Color.Red, backgroundColor: Color.BackgroundRed },
  warn: { color: Color.Yellow, backgroundColor: Color.BackgroundYellow },
};

/**
 * Returns color & background color based on conditions.
 */
export const getStatusColors = ({
  backgroundColor = Color.BackgroundGrey,
  color = Color.Gray1,
  type,
}: StatusTagStyleProps = {}): {
  color: string;
  backgroundColor: string;
} => (type ? PREDEFINED_TYPES[type] : { color, backgroundColor });

/**
 * Returns StatusTag component.
 */
export const StatusTag = ({
  className,
  text,
  color,
  backgroundColor,
  type,
  conditions = [],
  ...muiProps
}: StatusTagProps & BoxProps) => {
  // If there isn't any displayable text, no point to render anything
  if (!text) {
    return null;
  }

  // TODO: The match function should pass along the text value - .match function vs. const is not useful right now
  // Find matching condition to get conditional styling
  const matchingCondition = conditions.find(({ match }) => match() === true);

  // Default values if no matching condition is found
  const rootCondition = { type, color, backgroundColor };

  // Returns color & background color based on rules defined at parent & conditional level
  const { color: displayColor, backgroundColor: displayBgColor } = getStatusColors(
    matchingCondition || rootCondition,
  );

  return (
    <Box
      {...muiProps}
      component="span"
      color={displayColor}
      bgcolor={displayBgColor}
      className={className}
      sx={{
        display: 'inline-block',
        textAlign: 'center',
        padding: '4px 10px',
        borderRadius: '4px',
        fontWeight: 600,
        fontSize: '0.8rem',
        '&:first-letter': {
          textTransform: 'uppercase',
        },
        ...muiProps.sx,
      }}
    >
      {text}
    </Box>
  );
};
