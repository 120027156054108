import Button, { ButtonProps } from '@mui/material/Button';

import { Color } from '@theme/palette';

export const SecondaryButton = ({
  children,
  className,
  loading = false,
  sx,
  ...props
}: ButtonProps & { loading?: boolean }) => (
  <Button
    color="secondary"
    variant="contained"
    className={className}
    disabled={loading}
    sx={{
      color: (theme) => theme.palette.secondary.contrastText,
      borderColor: Color.Gray2,
      borderWidth: '1px',
      borderStyle: 'solid',
      boxSizing: 'border-box',
      backgroundColor: 'none',
      visibility: props.hidden ? 'hidden' : 'visible',
      ...sx,
    }}
    {...props}
  >
    {children}
  </Button>
);
