import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FormTextField } from '@components/TextField';
import { VehiclePricingField } from '@lib/firebase';

type VehiclePricingProps = {
  /**
   * Indicates whether the form data is loading.
   */
  isLoading?: boolean;
};

/**
 * Returns vehicle pricing.
 */
export const VehiclePricing = ({ isLoading }: VehiclePricingProps) => (
  <Stack gap={1}>
    <Typography variant="h5">Pricing</Typography>
    <Divider />

    <Stack direction="column" gap={1}>
      <Stack direction="row" gap={1}>
        <FormTextField
          id={VehiclePricingField.HourlyRate}
          label="Hourly rate"
          placeholder="Fill out a flat hourly rate"
          type="number"
          isLoading={isLoading}
          icon={{
            value: AttachMoneyIcon,
            position: 'start',
          }}
          inputProps={{
            step: 0.01,
            min: 0,
          }}
          required
        />

        <FormTextField
          id={VehiclePricingField.DailyRate}
          label="Daily rate (defaults to 8x hourly rate)"
          placeholder="Fill out a custom daily rate"
          type="number"
          isLoading={isLoading}
          icon={{
            value: AttachMoneyIcon,
            position: 'start',
          }}
          inputProps={{
            step: 0.01,
            min: 0,
          }}
        />
      </Stack>

      <FormTextField
        id={VehiclePricingField.AdditionalPrice}
        label="Additional fixed cost"
        placeholder="Optional fixed flat cost"
        type="number"
        isLoading={isLoading}
        icon={{
          value: AttachMoneyIcon,
          position: 'start',
        }}
        inputProps={{
          step: 0.01,
        }}
      />
    </Stack>
  </Stack>
);
