import Box from '@mui/material/Box';
// eslint-disable-next-line import/no-duplicates
import { format, parse, startOfWeek, getDay, isPast } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import enUS from 'date-fns/locale/en-US';
import { useCallback, useMemo } from 'react';
import { Calendar, Formats, Views, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { VendorEvent, useVendorEvents } from '@hooks/Calendar';
import { Color } from '@theme/palette';
import { getJobStatusStyling } from '@utils/status';

import { EventPopover } from './components/eventPopover';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

/**
 * Returns Calendar overview.
 */
export const CalendarOverview = () => {
  const { events = [] } = useVendorEvents();

  /**
   * Custom calendar components.
   * On clicking event, show popover with booking details.
   */
  const components = useMemo(
    () => ({
      eventWrapper: EventPopover,
    }),
    [],
  );

  /**
   * Custom event prop getter used for styling event blocks.
   * Mainly used for styling events based on status.
   */
  const eventPropGetter = useCallback(
    ({ resource, end: endDate }: VendorEvent) => ({
      style: {
        ...getJobStatusStyling(resource?.status),
        opacity: endDate && isPast(endDate) ? 0.5 : 1, // Dates in the past are faded out
      },
    }),
    [],
  );

  /**
   * Custom formats for calendar.
   * Used for formatting day headers, day range headers etc.
   */
  const formats = useMemo(
    (): Formats => ({
      dayHeaderFormat: 'EEEE MMM do, yyyy',
      dayRangeHeaderFormat: ({ start, end }, _culture, localizer) =>
        localizer?.format(start, 'MMM do, yyyy') + ' — ' + localizer?.format(end, 'MMM do, yyyy'),
    }),
    [],
  );

  /**
   * Localized messages, titles etc. for calendar.
   */
  const messages = useMemo(
    () => ({
      date: 'Date',
      time: 'Time',
      event: 'Event',
      allDay: 'All Day',
      week: 'Week',
      work_week: 'Work Week',
      day: 'Day',
      month: 'Month',
      previous: 'Prev',
      next: 'Next',
      yesterday: 'Yesterday',
      tomorrow: 'Tomorrow',
      today: 'Today',
      agenda: 'Agenda',
      noEventsInRange: 'There are no events in this range.',
      showMore: (total: number) => `+${total} more`,
    }),
    [],
  );

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        '.rbc-month-view': {
          backgroundColor: Color.White,
        },
        '.rbc-toolbar': {
          backgroundColor: Color.BackgroundGrey,
        },
      }}
    >
      <Calendar
        components={components}
        formats={formats}
        messages={messages}
        views={['month', 'week', 'day']}
        defaultView={Views.MONTH}
        localizer={localizer}
        events={events}
        selectable={false}
        eventPropGetter={eventPropGetter}
        startAccessor="start"
        endAccessor="end"
        popup
      />
    </Box>
  );
};
