import Paper, { PaperProps } from '@mui/material/Paper';

import { Color } from '@theme/palette';

export interface CardProps extends PaperProps {
  children: React.ReactElement;

  /**
   * Optional background color.
   */
  backgroundColor?: string;
}

export const Card = ({ children, backgroundColor = Color.White, sx, ...muiProps }: CardProps) => (
  <Paper
    sx={{
      padding: '20px',
      backgroundColor,
      boxShadow: '0 1px 2px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.08)',
      ...sx,
    }}
    {...muiProps}
  >
    {children}
  </Paper>
);
