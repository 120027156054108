import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Loader } from '@components/Loader';
import { useAvailableVehicles, useClosestVendorVehicle } from '@hooks/Vehicle/useAvailableVehicles';

import { VehicleListItem } from '../VehicleListItem';

type AvailableVehicleProps = {
  /**
   * Job location used to find vehicles near this location.
   * Uses Google Maps API to find vendors near this location.
   */
  jobLocation: { lng: number; lat: number };

  /**
   * Distance in kilometers to search for vehicles.
   * @default 100
   */
  distanceInKm?: number;

  /**
   * List of vendor ids to filter vehicles.
   * Mainly used to filter locked-in vendors for customer.
   */
  vendorIds?: string[];

  /**
   * Scheduled time for job.
   * Needed to see whether vehicle is available at this time.
   */
  scheduledTime: Date;

  /**
   * Scheduled end time for job.
   * Needed to see whether vehicle is available at this time.
   */
  scheduledEndTime: Date;

  /**
   * Indicates whether to show daily rates.
   * Defaults to hourly flat rate.
   */
  showDayRate?: boolean;
};

type PreferredVehicleProps = AvailableVehicleProps & {
  /**
   * Preferred vendor for customer.
   */
  vendorId: string;
};

/**
 * Returns list of all available vehicles for customer.
 */
export const AvailableVehicles = ({
  jobLocation,
  vendorIds,
  scheduledTime,
  scheduledEndTime,
  distanceInKm = 100,
  showDayRate = false,
}: AvailableVehicleProps) => {
  const { data: vehicles = [], isLoading } = useAvailableVehicles(
    // Ignore categoryId for now!
    { ...jobLocation, categoryId: '', vendorIds },
    distanceInKm,
  );

  if (isLoading) {
    return <Loader text="Loading available vehicles" />;
  }

  if (!vehicles && !isLoading) {
    <div>Could not find any vehicles within {distanceInKm} km</div>;
  }

  if (vehicles.length === 0 && !isLoading) {
    return (
      <Box>
        We could not find any available vehicles for your requested location or services. Please
        contact us to get assistance
      </Box>
    );
  }

  return (
    <Stack direction="column" gap={2}>
      {vehicles.map((vehicle, index) => (
        <VehicleListItem
          vehicle={vehicle}
          key={index}
          scheduledTime={scheduledTime}
          scheduledEndTime={scheduledEndTime}
          showDayRate={showDayRate}
        />
      ))}
    </Stack>
  );
};

/**
 * Returns list of all available vehicles for customer.
 */
export const PreferredVehicles = ({
  jobLocation,
  vendorId,
  scheduledTime,
  scheduledEndTime,
  distanceInKm = 100,
  showDayRate = false,
}: PreferredVehicleProps) => {
  const { data: vehicle, isLoading } = useClosestVendorVehicle(
    // Ignore categoryId for now!
    { ...jobLocation, vendorId, categoryId: '' },
    distanceInKm,
  );

  if (isLoading) {
    return <Loader text="Loading preferred vehicle(s)" />;
  }

  // Hide preferred vehicle if there are no available vehicles
  if (!vehicle && !isLoading) {
    return null;
  }

  return (
    <Stack direction="column" gap={1}>
      <Typography variant="h4">Last used vendor</Typography>

      <Stack direction="column" gap={2}>
        {vehicle && (
          <VehicleListItem
            vehicle={vehicle}
            scheduledTime={scheduledTime}
            scheduledEndTime={scheduledEndTime}
            showDayRate={showDayRate}
          />
        )}
      </Stack>
    </Stack>
  );
};
