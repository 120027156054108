import { useEffect, useState } from 'react';

import { useAuth } from '@hooks/useAuth';
import { useEffectFetchData } from '@hooks/useData';
import { Customer, UserOnboardingState } from '@lib/firebase';
import {
  getCustomerById,
  getCustomerOnboardingState,
  BasicCustomer,
  getBasicCustomerList,
} from '@lib/firestore/Customer';

export interface ICustomerListHook {
  users: BasicCustomer[];
  loading: boolean;
  error: unknown;
}

/**
 * Returns hook fetching the customer from Firestore and returns it as a Customer object.
 */
export const useCustomer = (id?: string) => {
  const { currentUser } = useAuth();

  return useEffectFetchData<Customer | undefined>(async () => {
    const customerId = id || currentUser?.uid;

    if (!customerId) {
      return undefined;
    }

    const customer = await getCustomerById(customerId);

    // Exit early if customer not found. Adding ID & Onboarding state is not needed.
    if (!customer) {
      return undefined;
    }

    // Set defaults if properties are missing.
    return {
      ...customer,
      id: customerId,
      onboarding_state: customer?.onboarding_state || UserOnboardingState.NotStarted,
    };
  }, [currentUser]);
};

/**
 * Returns hook fetching the customer's onboarding state from Firestore.
 */
export const useCustomerOnboardingState = () => {
  const { currentUser } = useAuth();

  return useEffectFetchData<UserOnboardingState | undefined>(async () => {
    if (!currentUser?.uid) {
      return undefined;
    }

    // Fetches onboarding state. Defaults to Not Started if not found.
    const onboardingState = await getCustomerOnboardingState(currentUser.uid);
    return onboardingState || UserOnboardingState.NotStarted;
  }, [currentUser]);
};

/**
 * Returns list of customers with basic properties.
 * @todo - Reuse fetchData hooks from useData.tsx
 * @todo - Should remove IVendorListHook after the above todo is done.
 */
export const useCustomerList = (): ICustomerListHook => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);
  const [customers, setCustomers] = useState<BasicCustomer[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const customers = await getBasicCustomerList();
        setCustomers(customers);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return { users: customers, loading, error };
};
