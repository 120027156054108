import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FormAddress } from '@components/Address';
import { FormTextField } from '@components/TextField';
import { VehicleField } from '@lib/firebase';

type VehicleAddressProps = {
  /**
   * Indicates whether data is still loading.
   */
  isLoading?: boolean;

  /**
   * Indicates whether create or edit mode is used.
   */
  isEditMode?: boolean;
};

export const VehicleAddress = ({ isLoading, isEditMode }: VehicleAddressProps) => {
  return (
    <Stack gap={1}>
      <Typography variant="h5">Operating Address</Typography>
      <Divider />
      <FormAddress isLoading={isLoading} useAutoComplete={!isEditMode} />

      <FormTextField
        id={VehicleField.Description}
        label="Description (optional)"
        placeholder="Provide more details about this specific Hydrovac"
        type="text"
        minRows={3}
        isLoading={isLoading}
        multiline
      />
    </Stack>
  );
};
