import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { SnackbarProvider as LibrarySnackbarProvider, closeSnackbar } from 'notistack';
import { ReactNode } from 'react';

type SnackbarProps = {
  children: ReactNode | undefined;
};

export const SnackBarProvider = ({ children }: SnackbarProps) => (
  <LibrarySnackbarProvider
    action={(snackbarId) => (
      <IconButton
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <CloseIcon />
      </IconButton>
    )}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
  >
    {children}
  </LibrarySnackbarProvider>
);
