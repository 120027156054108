import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type WithControllerProps = {
  id: string;

  /**
   * Sets default value.
   */
  defaultValue?: number | string;
};

/**
 * Returns a component with a react-hook-form controller wrapped around.
 * Makes it easier to use react-hook-form with MUI components.
 * @param WrappedComponent - Component to render & wrap around.
 */
export const withController = <TProps,>(WrappedComponent: React.ComponentType<TProps>) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithController = ({
    id,
    defaultValue,
    ...otherProps
  }: TProps & WithControllerProps) => (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={useFormContext().control}
      render={({ field: { ref, ...otherFieldProps } }) => (
        <WrappedComponent innerRef={ref} {...otherFieldProps} {...(otherProps as TProps)} />
      )}
    />
  );

  ComponentWithController.displayName = `withController(${displayName})`;

  return ComponentWithController;
};
