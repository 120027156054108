import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import { ModalProps, Modal } from '@components/Modal';
import { PrimaryButton } from '@components/PrimaryButton';
import { SecondaryButton } from '@components/SecondaryButton';
import { Color } from '@theme/palette';

type ConfirmationModalProps = Omit<ModalProps, 'children'> & {
  /**
   * Optional custom content to render.
   * If not provided, title and description can be rendered.
   */
  children?: React.ReactNode;

  /**
   * Gets called when user clicks confirm button.
   */
  onConfirm: () => void;

  /**
   * Gets called when user clicks cancel button.
   */
  onCancel: () => void;

  /**
   * Automatically render title up top.
   */
  title?: string;

  /**
   * Automatically render description below title.
   */
  description?: string | React.ReactElement;

  /**
   * Icon to render on top of title.
   */
  icon?: React.FC<SvgIconProps>;

  /**
   * Text to render on cancel button.
   * Defaults to "Cancel".
   */
  cancelButtonText?: string;

  /**
   * Text to render on confirm button.
   * Defaults to "Confirm".
   */
  confirmButtonText?: string;
};

/**
 * Modal with confirmation buttons.
 */
export const ConfirmationModal = ({
  children,
  title,
  description,
  onCancel,
  onConfirm,
  icon: Icon,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  ...muiProps
}: ConfirmationModalProps) => (
  <Modal {...muiProps}>
    <Stack direction="column" alignItems="center">
      {/** Built-in rendering of icon */}
      {Icon && (
        <Box
          sx={{
            padding: '15px',
            backgroundColor: Color.BackgroundGreen,
            lineHeight: 1,
            borderRadius: 50,
            marginBottom: '15px',
          }}
        >
          {<Icon sx={{ color: Color.Green, height: '2.5rem', width: '2.5rem' }} />}
        </Box>
      )}

      {/** Built-in rendering of title */}
      {title && (
        <Typography variant="h4" sx={{ marginBottom: 0 }}>
          {title}
        </Typography>
      )}

      {/** Built-in rendering of description */}
      {description && (
        <Box component="p" sx={{ textAlign: 'center' }}>
          {description}
        </Box>
      )}

      {/** Renders any custom component */}
      {children}
      <Stack direction="row" gap={1} justifyContent="center" sx={{ marginTop: '10px' }}>
        <SecondaryButton startIcon={<CloseIcon />} onClick={onCancel}>
          {cancelButtonText}
        </SecondaryButton>

        <PrimaryButton startIcon={<CheckIcon />} onClick={onConfirm}>
          {confirmButtonText}
        </PrimaryButton>
      </Stack>
    </Stack>
  </Modal>
);
