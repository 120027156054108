import { LoadingButtonProps } from '@mui/lab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { ContactUs } from '@components/Contact';
import { Form } from '@components/Form';
import { ConfirmationModal } from '@components/Modal/Confirm';
import { PrimaryButton } from '@components/PrimaryButton';
import { SecondaryButton } from '@components/SecondaryButton';
import { useChangeJobStatus } from '@hooks/Job/useChangeJobStatus';
import { useEditTaxRate } from '@hooks/Job/useEditTaxRate';
import { JobStatus, ProvinceCA } from '@lib/firebase';
import { vendorRoutes } from '@routes/vendor';

import { ITaxesForm } from '../fields';
import { BookingTaxRate } from './BookingTaxRate';

export type BookingActionsProps = {
  id: string;
  status: JobStatus;
  taxProvince?: ProvinceCA;
};

const Action = ({ ...rest }: LoadingButtonProps) => (
  <PrimaryButton sx={{ width: '150px' }} {...rest} />
);

/** Returns booking actions eg. Accept, Reject, Start, Complete, Cancel */
export const BookingActions = ({ id, status, taxProvince }: BookingActionsProps) => {
  const hasUnknownTaxes = !taxProvince || taxProvince === ProvinceCA.Unknown;

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [taxModalOpen, setTaxModalOpen] = useState(false);

  const { submit: changeJobStatus, isLoading: isSubmitting } = useChangeJobStatus();
  const { submit: changeTaxRate } = useEditTaxRate();
  const navigate = useNavigate();
  const location = useLocation();

  // Form used for setting tax rate & jurisdiction
  const taxForm = useForm<ITaxesForm>({
    mode: 'onSubmit',
  });

  const changeStatus = (newStatus: JobStatus) => async () => {
    await changeJobStatus({ id, status: newStatus });
    const targetBookingPath = vendorRoutes.bookingDetail.getPath(id);
    const currentPath = location?.pathname;

    if (currentPath !== targetBookingPath) {
      return navigate(vendorRoutes.bookingDetail.getPath(id));
    }

    navigate(0);
  };

  /**
   * Handles tax rate confirmation & status update.
   * @param newStatus
   * @returns
   */
  const handleTaxRateConfirmation = (newStatus: JobStatus) => async () => {
    const { taxRate, taxJurisdiction } = taxForm.getValues();

    // TODO: Proper form validation within modal!
    if (!taxRate || !taxJurisdiction) {
      return;
    }

    // Updates tax rate
    await changeTaxRate({ id, taxRate, taxJurisdiction });

    // Updates status
    await changeStatus(newStatus)();
  };

  /**
   * Modal closing/cancellation.
   */
  const onCancellationCancel = async () => {
    setConfirmOpen(false);
  };

  /**
   * Modal closing/cancellation.
   */
  const onTaxModalCancel = async () => {
    setTaxModalOpen(false);
  };

  const handleChangeClick = (newStatus: JobStatus) => async () => {
    // Confirmation required for cancelling job
    if (newStatus === JobStatus.VendorCancelled) {
      return setConfirmOpen(true);
    }

    // Confirmation required for accepting job - triggering custom tax rate
    if (newStatus === JobStatus.Accepted) {
      return setTaxModalOpen(true);
    }
  };

  return (
    <Stack direction="row" gap={1}>
      {/* Actions when Pending */}
      {status === JobStatus.Pending && (
        <>
          {/** Unknown tax rate & jurisdiction - US */}
          {hasUnknownTaxes && (
            <Action onClick={handleChangeClick(JobStatus.Accepted)} loading={isSubmitting}>
              Accept
            </Action>
          )}

          {/** Taxes are calculated - Canada */}
          {!hasUnknownTaxes && (
            <Action onClick={changeStatus(JobStatus.Accepted)} loading={isSubmitting}>
              Accept
            </Action>
          )}

          <Action onClick={changeStatus(JobStatus.Rejected)} loading={isSubmitting}>
            Reject
          </Action>
        </>
      )}

      {/* Actions when Accepted */}
      {status === JobStatus.Accepted && (
        <>
          <Action onClick={changeStatus(JobStatus.Initiated)} loading={isSubmitting}>
            Start
          </Action>

          <SecondaryButton
            onClick={handleChangeClick(JobStatus.VendorCancelled)}
            sx={{ width: '150px' }}
            loading={isSubmitting}
          >
            Cancel
          </SecondaryButton>
        </>
      )}

      {/* Actions when Initiated */}
      {status === JobStatus.Initiated && (
        <Action onClick={changeStatus(JobStatus.Completed)} loading={isSubmitting}>
          Complete
        </Action>
      )}

      {/* Actions when Completed */}
      {status === JobStatus.Completed && (
        <Action onClick={changeStatus(JobStatus.Paid)} loading={isSubmitting}>
          Mark as Paid
        </Action>
      )}

      {/* Should be different per user status! For now this works, but refactor to pass along dynamic status */}
      <ConfirmationModal
        open={taxModalOpen}
        onConfirm={handleTaxRateConfirmation(JobStatus.Accepted)}
        onCancel={onTaxModalCancel}
        confirmButtonText="Confirm & Accept"
        title="Confirm tax information"
      >
        <Stack direction="column" gap={2}>
          We could not automatically determine the tax rate & jurisdiction needed for invoicing
          purposes.
          <FormProvider {...taxForm}>
            <Form>
              <BookingTaxRate />
            </Form>
          </FormProvider>
        </Stack>
      </ConfirmationModal>

      {/* Should be different per user status! For now this works, but refactor to pass along dynamic status */}
      <ConfirmationModal
        open={confirmOpen}
        onConfirm={changeStatus(JobStatus.VendorCancelled)}
        onCancel={onCancellationCancel}
        confirmButtonText="Accept"
        title="It looks like you're trying to cancel an order you've already confirmed."
        description={
          <Box>
            Please note that the dispatch fee plus any applicable taxes will be charged to your UVAC
            account. Select "Accept" to confirm or <ContactUs label="contact us" /> if you have any
            questions.
          </Box>
        }
      />
    </Stack>
  );
};
