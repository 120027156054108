import Box, { BoxProps } from '@mui/material/Box';

import uvacLogo from '../../icons/logo.svg';

type LogoProps = {
  width?: number;
};

/**
 * Returns the uvac Logo.
 */
export const Logo = ({ width = 45, ...muiProps }: LogoProps): JSX.Element & BoxProps => (
  <Box
    component="img"
    alt="uvac"
    src={uvacLogo}
    width={width}
    sx={{ borderRadius: '8px' }}
    {...muiProps}
  />
);
