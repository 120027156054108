import { PaletteOptions } from '@mui/material/styles';

export enum Color {
  // Green - Primary
  PrimaryGreen = '#00A350',
  DarkGreen = '#038a46',
  LightGreen = '#05c161',

  // Status colors
  Green = '#4CAF50',
  Yellow = '#FF9800',
  BrightYellow = '#E7C801',
  Red = '#F44336',
  Blue = '#07988b',

  // Backgrounds
  BackgroundColor = '#F5F7FB', // Main BG color
  BackgroundGrey = '#F6F7FB',
  BackgroundGreen = '#F1F9F1',
  BackgroundRed = '#FEF0EF',
  BackgroundYellow = '#FFF7EB',
  BackgroundBlue = '#e5f3f3',

  // Neutrals
  Black = '#171c1f',
  LightBlack = '#1e1e1e',
  White = '#FFFFFF',
  DisclaimerGray = '#f9f9f9',
  Gray1 = '#727272',
  Gray2 = '#BFBFBF',
  Gray3 = '#E0E0E0',
  Gray4 = '#F7F7F7',
}

export const palette: PaletteOptions = {
  primary: {
    main: Color.PrimaryGreen,
    light: Color.LightGreen,
    dark: Color.DarkGreen,
    contrastText: Color.White,
  },
  secondary: {
    main: Color.White,
    light: Color.White,
    dark: Color.Gray4,
    contrastText: Color.Black,
  },

  background: {
    default: Color.BackgroundGrey,
    paper: Color.White,
  },
  text: {
    primary: Color.Black,
    secondary: Color.DarkGreen,
  },
  grey: {
    100: '#B2B2B2',
    200: '#727272',
    300: '#263238',
    50: '#E0E0E0',
  },
  success: {
    main: Color.PrimaryGreen,
  },
  warning: {
    main: Color.Yellow,
  },
  error: {
    main: Color.Red,
  },
};

export const profileBackgroundColors = [
  '#FFB69F',
  '#EB9EC3',
  '#CDC0FF',
  '#B4C6FF',
  '#C7E18F',
  '#FFE091',
];

const getColorBasedForId = (colorList: string[]) => (id: string) => {
  const numbersInId = id.replace(/\D/g, '');
  const index = Number(numbersInId) % colorList.length;

  return colorList[index || 0];
};

export const getProfileColorForId = getColorBasedForId(profileBackgroundColors);
