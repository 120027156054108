import { useAuth } from '@hooks/useAuth';
import { useSubmitData } from '@hooks/useData';
import { VehicleStatus } from '@lib/firebase';
import { getVehicleStatus, updateVehicleStatus } from '@lib/firestore/Vehicle';

/**
 * Returns reversed service status for vehicle.
 * @param status - Service status.
 */
export const reverseStatus = (status: VehicleStatus) => {
  if (status === VehicleStatus.Active) {
    return VehicleStatus.Inactive;
  }

  return VehicleStatus.Active;
};

/**
 * Returns hook for toggling vehicle status by id.
 * @param opts - Hook options such as onCompleted etc.
 */
export const useToggleVehicleStatus = () => {
  const { currentUser } = useAuth();

  return useSubmitData(async ({ id }: { id: string }) => {
    if (!currentUser?.uid) {
      return;
    }

    // Get current status, then reverse it & store in DB
    const currentStatus = await getVehicleStatus(id);
    if (currentStatus) {
      const newStatus = reverseStatus(currentStatus);
      await updateVehicleStatus(id, newStatus);
    }
  });
};
