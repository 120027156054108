/* eslint-disable sort-keys */

import { BookingDetails } from '@Vendor/pages/Booking/Details';
import { BookingOverviewVendor } from '@Vendor/pages/Booking/Overview';
import { CreateCustomerProfile } from '@Vendor/pages/CustomerProfile/Create';
import { EditCustomerProfile } from '@Vendor/pages/CustomerProfile/Edit';
import { CustomerProfileOverview } from '@Vendor/pages/CustomerProfile/Overview';
import { Home } from '@Vendor/pages/Home';
import { NotFound } from '@Vendor/pages/NotFound';
import { Onboarding } from '@Vendor/pages/Profile/Onboarding';
import { Profile } from '@Vendor/pages/Profile/Profile';
import { CreateVehicle } from '@Vendor/pages/Vehicle/Create';
import { VehicleOverview } from '@Vendor/pages/Vehicle/Overview';

import { PrivacyPolicy } from '@pages/Legal/PrivacyPolicy';
import { TermsOfService } from '@pages/Legal/TermsOfService';
import { Login } from '@pages/Login';
import { Registration } from '@pages/Registration';

import { IRouteConfig } from '../interfaces';
import { CalendarOverview } from '../modules/Vendor/pages/Calendar';

export enum VendorRoute {
  /**
   * Authentication.
   */
  Login = 'login',
  Register = 'register',
  Onboarding = 'onboarding',

  /**
   * Bookings
   */
  Bookings = 'bookings',
  BookingDetail = 'bookingDetail',

  /**
   * Vehicles
   */
  Vehicles = 'vehicles',
  AddVehicle = 'addVehicle',
  EditVehicle = 'editVehicle',

  /**
   * Customer profiles
   */
  CustomerProfiles = 'customerProfiles',
  AddCustomerProfile = 'addCustomerProfile',
  EditCustomerProfile = 'editCustomerProfile',

  /**
   * Legal
   */
  PrivacyPolicy = 'privacyPolicy',
  TermsOfService = 'termsOfService',

  /**
   * Misc.
   */
  Home = 'home',
  Calendar = 'calendar',
  Profile = 'profile',
  NotFound = 'notFound',
}

/**
 * Defines all available private routes.
 * Make sure to use plural terms and the following format:
 * /view/:id, /edit/:id, /list, ...
 */
export const vendorRoutes: Record<VendorRoute, IRouteConfig> = {
  [VendorRoute.Home]: {
    name: 'Dashboard',
    path: '/',
    getPath: () => '/',
    component: Home,
    exact: true,
  },

  // TODO: Embed within dashboard page once production ready
  [VendorRoute.Calendar]: {
    name: 'Calendar',
    path: '/calendar',
    getPath: () => '/calendar',
    component: CalendarOverview,
    exact: true,
  },

  /**
   * Authenticaton.
   */
  [VendorRoute.Login]: {
    name: 'Login',
    path: `/login`,
    getPath: () => `/login`,
    component: () => Login({ title: 'uvac for Vendors', homePath: vendorRoutes.home.path }),
    exact: true,
    public: true,
  },
  [VendorRoute.Register]: {
    name: 'Register',
    path: `/register`,
    getPath: () => `/register`,
    component: () => Registration({ loginPath: vendorRoutes.login.path }),
    exact: true,
    public: true,
  },
  [VendorRoute.Onboarding]: {
    name: 'Onboarding',
    path: `/onboarding`,
    getPath: () => `/onboarding`,
    component: Onboarding,
    exact: true,
    public: true,
  },

  /**
   * Bookings
   */
  [VendorRoute.Bookings]: {
    name: 'Bookings',
    path: `/bookings`,
    getPath: () => `/bookings`,
    component: BookingOverviewVendor,
    exact: true,
  },

  [VendorRoute.BookingDetail]: {
    name: 'Booking Detail',
    path: `/bookings/:id`,
    getPath: (id: string) => `/bookings/${id}`,
    component: BookingDetails,
  },

  /**
   * Vehicles
   */
  [VendorRoute.Vehicles]: {
    name: 'My Hydrovacs',
    path: `/vehicles`,
    getPath: () => `/vehicles`,
    component: VehicleOverview,
    exact: true,
  },
  [VendorRoute.AddVehicle]: {
    name: 'Add Hydrovac',
    path: `/vehicles/add`,
    getPath: () => `/vehicles/add`,
    component: CreateVehicle,
    exact: true,
  },
  [VendorRoute.EditVehicle]: {
    name: 'Edit Hydrovac',
    path: `/vehicles/edit/:id`,
    getPath: () => `/vehicles/edit`,
    component: CreateVehicle,
    exact: true,
  },

  /**
   * Pricing
   */
  [VendorRoute.CustomerProfiles]: {
    name: 'Customer Profiles',
    path: `/customers`,
    getPath: () => `/customers`,
    component: CustomerProfileOverview,
    exact: true,
  },
  [VendorRoute.AddCustomerProfile]: {
    name: 'Add Customer Profile',
    path: `/customers/add`,
    getPath: () => `/customers/add`,
    component: CreateCustomerProfile,
    exact: true,
  },
  [VendorRoute.EditCustomerProfile]: {
    name: 'Edit Customer Profile',
    path: `/customers/edit/:id`,
    getPath: () => `/customers/edit`,
    component: EditCustomerProfile,
    exact: true,
  },

  /**
   * Legal
   */
  [VendorRoute.TermsOfService]: {
    name: 'Terms of Service',
    path: `/terms-of-service`,
    getPath: () => `/terms-of-service`,
    component: TermsOfService,
  },

  [VendorRoute.PrivacyPolicy]: {
    name: 'Privacy Policy',
    path: `/privacy-policy`,
    getPath: () => `/privacy-policy`,
    component: PrivacyPolicy,
  },

  /**
   * Misc.
   */
  [VendorRoute.Profile]: {
    name: 'My Account',
    path: `/profile`,
    getPath: () => `/profile`,
    component: Profile,
  },
  [VendorRoute.NotFound]: {
    name: 'Not Found',
    path: `*`,
    getPath: () => `*`,
    component: NotFound,
    public: true,
  },
};
