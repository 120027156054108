import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

export const ErrorPage = () => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        background: (theme) => theme.palette.background.default,
        fontStyle: (theme) => `${theme.typography.fontFamily} !important`,
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Typography variant="h5" sx={{ margin: (theme) => theme.spacing(5) }}>
        Something went wrong. Please try again later. Contact our support if this issue persists.
      </Typography>
    </Box>
  );
};
