/* eslint-disable sort-keys */
import { RequestBooking } from '@Customer/pages/Booking/Create';
import { BookingDetails } from '@Customer/pages/Booking/Details';
import { BookingOverviewCustomer } from '@Customer/pages/Booking/Overview';
import { Home } from '@Customer/pages/Home';
import { NotFound } from '@Customer/pages/NotFound';
import { Onboarding } from '@Customer/pages/Profile/Onboarding';
import { Profile } from '@Customer/pages/Profile/Profile';

import { PrivacyPolicy } from '@pages/Legal/PrivacyPolicy';
import { TermsOfService } from '@pages/Legal/TermsOfService';
import { Login } from '@pages/Login';
import { Registration } from '@pages/Registration';

import { IRouteConfig } from '../interfaces';

export enum CustomerRoute {
  /**
   * Authentication.
   */
  Login = 'login',
  Register = 'register',
  Onboarding = 'onboarding',

  /**
   * Bookings
   */
  Bookings = 'bookings',
  RequestBooking = 'requestBooking',
  BookingDetail = 'bookingDetail',

  /**
   * Legal
   */
  PrivacyPolicy = 'privacyPolicy',
  TermsOfService = 'termsOfService',

  /**
   * Misc.
   */
  Home = 'home',
  Profile = 'profile',
  NotFound = 'notFound',
}

/**
 * Defines all available private routes.
 * Make sure to use plural terms and the following format:
 * /view/:id, /edit/:id, /list, ...
 */
export const customerRoutes: Record<CustomerRoute, IRouteConfig> = {
  [CustomerRoute.Home]: {
    name: 'Dashboard',
    path: '/',
    getPath: () => '/',
    component: Home,
    exact: true,
  },

  /**
   * Authenticaton.
   */
  [CustomerRoute.Login]: {
    name: 'Login',
    path: `/login`,
    getPath: () => `/login`,
    component: () => Login({ title: 'uvac for Customers', homePath: customerRoutes.home.path }),
    exact: true,
    public: true,
  },
  [CustomerRoute.Register]: {
    name: 'Register',
    path: `/register`,
    getPath: () => `/register`,
    component: () => Registration({ loginPath: customerRoutes.login.path }),
    exact: true,
    public: true,
  },
  [CustomerRoute.Onboarding]: {
    name: 'Onboarding',
    path: `/onboarding`,
    getPath: () => `/onboarding`,
    component: Onboarding,
    exact: true,
    public: true,
  },

  /**
   * Bookings
   */
  [CustomerRoute.Bookings]: {
    name: 'Bookings',
    path: `/bookings`,
    getPath: () => `/bookings`,
    component: BookingOverviewCustomer,
    exact: true,
  },
  [CustomerRoute.RequestBooking]: {
    name: 'Request Booking',
    path: `/bookings/request`,
    getPath: () => `/bookings/request`,
    component: RequestBooking,
    exact: true,
  },
  [CustomerRoute.BookingDetail]: {
    name: 'Booking Detail',
    path: `/bookings/:id`,
    getPath: (id: string) => `/bookings/${id}`,
    component: BookingDetails,
  },

  /**
   * Legal
   */
  [CustomerRoute.TermsOfService]: {
    name: 'Terms of Service',
    path: `/terms-of-service`,
    getPath: () => `/terms-of-service`,
    component: TermsOfService,
  },

  [CustomerRoute.PrivacyPolicy]: {
    name: 'Privacy Policy',
    path: `/privacy-policy`,
    getPath: () => `/privacy-policy`,
    component: PrivacyPolicy,
  },

  /**
   * Misc.
   */
  [CustomerRoute.Profile]: {
    name: 'My Account',
    path: `/profile`,
    getPath: () => `/profile`,
    component: Profile,
  },
  [CustomerRoute.NotFound]: {
    name: 'Not Found',
    path: `*`,
    getPath: () => `*`,
    component: NotFound,
    public: true,
  },
};
