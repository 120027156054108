import { FirebaseOptions } from 'firebase/app';

/**
 * Firebase configuration for QA environment.
 */
export const qaFirebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyAZlNm6PAFuWyiK_qMRgNPcJADbnwncmc4",
  authDomain: "uvac-qa.firebaseapp.com",
  projectId: "uvac-qa",
  storageBucket: "uvac-qa.appspot.com",
  messagingSenderId: "413876199626",
  appId: "1:413876199626:web:d36685d5efaad4ba1b1433"
};

/**
 * Firebase configuration for production environment.
 */
export const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyC4xRT74r8YcwkvEeZFJSUawOSDpQMx6Ao',
  authDomain: 'uvac-app.firebaseapp.com',
  projectId: 'uvac-app',
  storageBucket: 'uvac-app.appspot.com',
  messagingSenderId: '633287400804',
  appId: '1:633287400804:web:54c5728db312e26500ee71',
};

/**
 * Returns Firebase configuration based on Environment.
 * @param env - Environment.
 */
export const getFirebaseConfig = (env: string): FirebaseOptions => {
  if (env === 'production') {
    return firebaseConfig;
  }

  return qaFirebaseConfig;
}
