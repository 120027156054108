import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { useCustomerList, useCustomer } from '@hooks/Customer';
import { useAuth } from '@hooks/useAuth';
import { useVendorList, useVendor } from '@hooks/Vendor';
import { ProfileFeatureFlagField, UserType } from '@lib/firebase';
import { Color } from '@theme/palette';

export const UserSpoofer = () => {
  const userType = process.env.OUTPUT_TYPE as UserType;
  const { currentUser: authUser, setCurrentUser: setAuthUser } = useAuth();
  const { users = [] } = userType === UserType.Vendor ? useVendorList() : useCustomerList();
  const currentUser = userType === UserType.Vendor ? useVendor() : useCustomer();
  const [selectedUser, setSelectedUser] = useState<string>(authUser?.uid || '');

  const handleSpoofUser = (event: SelectChangeEvent) => {
    const uid = event.target.value as string;
    setSelectedUser(uid);
    const spoofedUser = {
      ...authUser,
      uid,
      spoofed: true,
    };
    setAuthUser(spoofedUser);
  };

  const allowedToSpoof = currentUser?.data?.[ProfileFeatureFlagField.UserSpoofing];
  const currentlySpoofed = authUser?.spoofed;
  if (!allowedToSpoof && !currentlySpoofed) return <></>;

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <Select value={selectedUser} onChange={handleSpoofUser}>
          {users?.map(({ email, uid }) => (
            <MenuItem key={uid} value={uid}>
              {email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {currentlySpoofed && (
        <Typography sx={{ textAlign: 'center', color: Color.Red, fontSize: '2.0rem' }}>
          Spoofed
        </Typography>
      )}
    </>
  );
};
