import * as Sentry from "@sentry/react";

/**
 * Sets up Sentry.
 * Currently on run on production!
 */
const setupSentry = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn: "https://708484446f00a81cf80a97bbd75b7409@o4505629994975232.ingest.sentry.io/4505630001659904",
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ["localhost", "https://order.uvac.app", "https://vendor.uvac.app/"],
        }),
        // new Sentry.Replay(),
      ],

      // Performance Monitoring
      tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.3 : 1.0, // Capture 100% of the transactions, reduce in production!

      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    Sentry.setTag("project-type", process.env.OUTPUT_TYPE);
  }
}

setupSentry();
