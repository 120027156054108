import { IVendorsSelectionForm } from '@Customer/pages/Profile/fields';

import { useAuth } from '@hooks/useAuth';
import { useSubmitData } from '@hooks/useData';
import { useSnackbar } from '@hooks/useSnackbar';
import { CustomerField, UserOnboardingState } from '@lib/firebase';
import { updateCustomerProfile } from '@lib/firestore/Customer';

/**
 * Returns hook for submitting vehicle selection form.
 * Also updates onboarding state to final customer state (state 3).
 */
export const useVehicleSelection = () => {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  return useSubmitData(async (values: IVendorsSelectionForm) => {
    if (!currentUser?.uid || !currentUser?.email) {
      return;
    }

    // At least 1 vendor must be selected
    if (values.locked_vendor_ids.length === 0) {
      return enqueueSnackbar('Please select at least 1 operator', { variant: 'error' });
    }

    await updateCustomerProfile(currentUser.uid, {
      [CustomerField.VendorIds]: values.locked_vendor_ids,
      onboarding_state: UserOnboardingState.FinishedStep3,
    });
  });
};
