import { UserOnboardingState } from '@uvac-apps/db-models';
import { useState, useEffect } from 'react';

import { useAuth } from '@hooks/useAuth';
import { useEffectFetchData } from '@hooks/useData';
import { Vendor } from '@lib/firebase';
import {
  getVendorById,
  getVendorOnboardingState,
  getVendors,
  BasicVendor,
  getBasicVendorList,
} from '@lib/firestore/Vendor';

export interface IVendorListHook {
  users: BasicVendor[];
  loading: boolean;
  error: unknown;
}

/**
 * Returns list of vendors with basic properties.
 * @todo - Reuse fetchData hooks from useData.tsx
 * @todo - Should remove IVendorListHook after the above todo is done.
 */
export const useVendorList = (): IVendorListHook => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);
  const [vendors, setVendors] = useState<BasicVendor[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const vendors = await getBasicVendorList();
        setVendors(vendors);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return { users: vendors, loading, error };
};

/**
 * Returns hook fetching vendor information from Firestore.
 */
export const useVendor = (id?: string) => {
  const { currentUser } = useAuth();

  return useEffectFetchData<Vendor | undefined>(async () => {
    const vendorId = id || currentUser?.uid;

    if (!vendorId) {
      return undefined;
    }

    const vendor = await getVendorById(vendorId);

    // Exit early if vendor not found. Adding ID & Onboarding state is not needed.
    if (!vendor) {
      return undefined;
    }

    // Set defaults if properties are missing.
    return {
      ...vendor,
      id: vendorId,
      onboarding_state: vendor?.onboarding_state || UserOnboardingState.NotStarted,
    };
  }, [currentUser]);
};

/**
 * Returns hook fetching all vendors from Firestore.
 */
export const useVendors = () =>
  useEffectFetchData<Vendor[]>(() => {
    return getVendors();
  });

/**
 * Returns hook fetching the vendor's onboarding state from Firestore.
 */
export const useVendorOnboardingState = () => {
  const { currentUser } = useAuth();

  return useEffectFetchData<UserOnboardingState | undefined>(async () => {
    if (!currentUser?.uid) {
      return undefined;
    }

    // Fetches onboarding state. Defaults to Not Started if not found.
    const onboardingState = await getVendorOnboardingState(currentUser.uid);
    return onboardingState || UserOnboardingState.NotStarted;
  }, [currentUser]);
};
