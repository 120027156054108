import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FirebaseError } from 'firebase/app';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Form } from '@components/Form';
import { Logo } from '@components/Logo';
import { PrimaryButton } from '@components/PrimaryButton';
import { SecondaryButton } from '@components/SecondaryButton';
import { FormTextField } from '@components/TextField';
import { useSnackbar } from '@hooks/useSnackbar';
import { createFirebaseUser, sendFirebaseEmailVerification } from '@lib/firebase';
import { Color } from '@theme/palette';

enum RegistrationField {
  Email = 'email',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

type IRegistrationForm = { [key in RegistrationField]: string };

type RegistrationProps = {
  /**
   * Path to login page.
   */
  loginPath: string;
};

export const Registration = ({ loginPath }: RegistrationProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<IRegistrationForm>({
    mode: 'onSubmit',
  });

  const onSubmit = async ({ email, password }: IRegistrationForm) => {
    // Tries to create new Firebase account & send confirmation email
    try {
      const { user } = await createFirebaseUser(email, password);
      await sendFirebaseEmailVerification(user);
    } catch (error: FirebaseError | any) {
      if (error?.code === 'auth/email-already-in-use') {
        return enqueueSnackbar('An account with this email address already exists.', {
          variant: 'error',
        });
      }

      return enqueueSnackbar(
        `An issue occurred trying to register an account (CODE: ${error?.code})`,
        {
          variant: 'error',
        },
      );
    }

    enqueueSnackbar('An email has been sent to your inbox. Please verify your email address.', {
      variant: 'success',
    });

    // Reset form after submission.
    form.reset();
  };

  return (
    <Box width="100%" height="100vh" sx={{ backgroundColor: Color.White }}>
      <Box
        sx={{
          minWidth: '300px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Stack direction="column" alignItems="center" gap={1} sx={{ marginBottom: 5 }}>
          <Logo />
          <Typography variant="h3">Welcome to uvac</Typography>
          <Typography variant="h4">Fill out the details below to create your account</Typography>
        </Stack>

        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <FormTextField
                id={RegistrationField.Email}
                label="Email Address"
                placeholder="Provide a valid email address"
                type="email"
                required
              />
              <FormTextField
                id={RegistrationField.Password}
                label="Password"
                type="password"
                placeholder="Password between 8-16 characters"
                inputProps={{ minLength: 8, maxLength: 16 }}
                required
              />
              <FormTextField
                id={RegistrationField.ConfirmPassword}
                label="Confirm Password"
                type="password"
                placeholder="Type your password again"
                inputProps={{ vali: 8, maxLength: 16 }}
                options={{
                  validate: (value) =>
                    value === form.getValues(RegistrationField.Password) ||
                    'Passwords do not match',
                }}
                required
              />

              <Stack direction="column" gap={2}>
                <PrimaryButton type="submit">Sign Up</PrimaryButton>
                <SecondaryButton onClick={() => navigate(loginPath)} sx={{ border: 'none' }}>
                  Back to Login
                </SecondaryButton>
              </Stack>
            </Stack>
          </Form>
        </FormProvider>
      </Box>
    </Box>
  );
};
