import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';

import { Color } from '@theme/palette';

interface MenuItemProps {
  /**
   * Label to show.
   */
  label: string;

  /**
   * Path to navigate to.
   */
  path: string;

  /**
   * [Optional] icon to show.
   */
  icon?: React.ElementType;

  /**
   * Optional onClick handler for control within parent componet.
   * Eg. close drawer menu when item has been clicked.
   */
  onClick?: (path: string) => void;
}

export const MenuItem = ({ icon: Icon, label, path, onClick }: MenuItemProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Partial match besides root path
  const isCurrentPath = path !== '/' ? pathname.includes(path) : pathname === path;

  const goto = (path: string) => {
    navigate(path);

    // Optionally inform parent
    if (onClick) {
      onClick(path);
    }
  };

  return (
    <ListItem
      onClick={() => goto(path)}
      sx={{
        cursor: 'pointer',
        padding: '10px 12px',
        backgroundColor: isCurrentPath ? Color.BackgroundGreen : Color.White,
        color: (theme) => (isCurrentPath ? theme.palette.primary.main : Color.Gray1),
        borderRadius: '8px',
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 35,
          ...(isCurrentPath && { color: (theme) => theme.palette.primary.main }),
        }}
      >
        {Icon && <Icon />}
      </ListItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          fontWeight: 500,
          fontSize: '0.9rem', // Not sure why it's bigger by default
        }}
      />
    </ListItem>
  );
};
