import { useState } from 'react';

import { useAuth } from '@hooks/useAuth';
import { editJobVendor } from '@lib/firestore/Job';

interface IEditJobVendorId {
  id: string;
  vendor_order_id: string;
}

/**
 * Sets the vendor id for a job.
 */
export const useEditJobVendorId = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const { currentUser } = useAuth();

  const submit = async (data: IEditJobVendorId) => {
    setLoading(true);
    if (!currentUser?.uid) return;

    try {
      await editJobVendor(data.id, data.vendor_order_id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  };

  return { submit, loading, error };
};
