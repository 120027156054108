import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';

import { firebaseApp } from './firebase';

export const analytics = getAnalytics(firebaseApp);

enum AnalyticsEvent {
  ScreenView = 'screen_view',
  Login = 'login',
}

enum AnalyticsUserProperty {
  FirstName = 'first_name',
  LastName = 'last_name',
  Email = 'email',
}

export const analyticsEvent = (
  name: AnalyticsEvent,
  data: { [key: string]: string | number | boolean | Date },
) => logEvent(analytics, name as string, data);

export const analyticsSetUserProperty = (data: {
  [Property in keyof AnalyticsUserProperty]: string | number | boolean | Date;
}) => setUserProperties(analytics, data);

export const logScreenView = (data: { [key: string]: string | number | boolean | Date }) =>
  analyticsEvent(AnalyticsEvent.ScreenView, data);
