import InputLabel from '@mui/material/InputLabel';

type FormLabelProps = {
  /**
   * Needed to associate label with input.
   */
  id: string;

  /**
   * Label value to display.
   */
  label: React.ReactNode | string;
};

export const FormLabel = ({ id, label }: FormLabelProps) => (
  <InputLabel
    shrink
    htmlFor={id}
    sx={{
      color: (theme) => theme.palette.secondary.contrastText,
      fontWeight: 500,
      transform: 'inherit', // Needed to counteract fanciness on MUI labels
      position: 'inherit', // Needed to counteract fanciness on MUI labels
      fontSize: '0.8rem',
      marginBottom: '5px',
    }}
  >
    {label}
  </InputLabel>
);
