const CONTACT_EMAIL = 'info@uvac.app';

type ContactUsProps = {
  label?: string;
};

export const ContactUs = ({ label = CONTACT_EMAIL }: ContactUsProps) => (
  <a href={`mailto:${CONTACT_EMAIL}`} target="_blank" rel="noreferrer">
    {label}
  </a>
);
