import { Timestamp } from 'firebase/firestore';

import { useAuth } from '@hooks/useAuth';
import { useSubmitData } from '@hooks/useData';
import { NoteField } from '@lib/firebase';
import { IAddJobNote, addJobNote } from '@lib/firestore/Job';

/**
 * Returns hook for adding job note for job.
 */
export const useAddJobNote = () => {
  const { currentUser } = useAuth();

  return useSubmitData(async ({ id, ...values }: IAddJobNote) => {
    if (!currentUser?.uid) {
      return;
    }

    const now = new Date();
    const note = {
      [NoteField.CreatorId]: currentUser.uid,
      [NoteField.Text]: values.text,
      [NoteField.CreatorType]: values.creator_type,
      [NoteField.CreatedAt]: Timestamp.fromDate(now),
    };

    await addJobNote(id, note);
  });
};
