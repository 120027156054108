import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { Controller, useFormContext } from 'react-hook-form';

import { Color } from '@theme/palette';

type FormDateTimePickerProps = {
  /**
   * ID of the field.
   * Used by react-hook-form to keep track of the field's value.
   */
  id: string;

  /**
   * Indicates whether any values are loading.
   */
  isLoading?: boolean;

  /**
   * Indicates whether the field is required.
   */
  required?: boolean;
};

/**
 * Returns a Datetimepicker component with a react-hook-form controller wrapped around.
 * This is the recommended way to use react-hook-form with MUI components.
 * @todo - Make label match styling of other form input components.
 */
export const FormDateTimePicker = ({
  id,
  isLoading = false,
  required,
  ...muiProps
}: FormDateTimePickerProps &
  DateTimePickerProps<any> &
  React.RefAttributes<HTMLDivElement> & { propTypes?: any }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={null}
      render={({ field, ...otherFieldProps }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            views={['year', 'day', 'hours', 'minutes']} // Default view
            ampm={true} // 12h format
            value={field.value}
            onChange={(date) => {
              field.onChange(date);
            }}
            loading={isLoading}
            slotProps={{
              textField: {
                required,
                variant: 'outlined',
                size: 'small',
                InputLabelProps: { shrink: true },
              },
            }}
            sx={{
              marginTop: '8px', // Compensate for the label
              '& label': {
                color: Color.Black,
              },
            }}
            {...otherFieldProps}
            {...muiProps}
          />
        </LocalizationProvider>
      )}
    />
  );
};
