import Typography, { TypographyProps } from '@mui/material/Typography';

interface PageTitleProps {
  name: string;
}

export const PageTitle = ({ name, sx, ...muiProps }: PageTitleProps & TypographyProps) => (
  <Typography variant="h1" sx={{ marginBottom: '25px', ...sx }} {...muiProps}>
    {name}
  </Typography>
);
