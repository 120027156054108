import { useAuth } from '@hooks/useAuth';
import { useSubmitData, useEffectFetchData } from '@hooks/useData';
import { Job } from '@lib/firebase';
import { ICreateJob, createJob, getJobById } from '@lib/firestore/Job';

/**
 * Returns hook for fetching job by id.
 * @param {string} id - Unique job ID.
 */
export const useJob = (id?: string) => {
  const { currentUser } = useAuth();

  return useEffectFetchData<Job | undefined>(() => {
    if (!currentUser?.uid) {
      return undefined;
    }

    if (!id) return;

    return getJobById(id);
  }, [currentUser]);
};

/**
 * Returns hook for creating a job.
 * Can only be performed by customer.
 */
export const useCreateJob = () => {
  const { currentUser } = useAuth();

  // Vendor ID gets automatically set to current user. No need to pass it in.
  return useSubmitData(async (values: ICreateJob) => {
    if (!currentUser?.uid) {
      return;
    }

    const selectedCategories = values.selected_categories ?? [];
    if (selectedCategories.length <= 0) {
      throw new Error('Could not find selected categories');
    }

    await createJob(values, currentUser.uid);
  });
};
