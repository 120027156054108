import EmailIcon from '@mui/icons-material/Email';
import GoogleIcon from '@mui/icons-material/Google';
import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { Form } from '@components/Form';
import { Logo } from '@components/Logo';
import { PrimaryButton } from '@components/PrimaryButton';
import { SecondaryButton } from '@components/SecondaryButton';
import { FormTextField } from '@components/TextField';
import { useSnackbar } from '@hooks/useSnackbar';
import { firebaseAuth } from '@lib/firebase';
import { vendorRoutes } from '@routes/vendor';
import { Color } from '@theme/palette';
const googleProvider = new GoogleAuthProvider();

enum LoginField {
  Email = 'email',
  Password = 'password',
}

type IEmailPasswordForm = { [key in LoginField]: string };

type LoginProps = {
  /**
   * Customized title (eg. Vendor/Customer)
   */
  title: string;

  /**
   * Path to redirect to after login.
   */
  homePath: string;
};

export const Login = ({ title, homePath }: LoginProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<IEmailPasswordForm>({
    mode: 'onSubmit',
  });

  /**
   * Signs in with email and password.
   */
  const signInEmail = async ({ email, password }: IEmailPasswordForm) => {
    try {
      const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);

      // Only if email was verified - log in user.
      if (!userCredential.user.emailVerified) {
        return enqueueSnackbar('User could not be found or email has not been verified', {
          variant: 'error',
        });
      }

      // Redirect to home page.
      navigate(homePath);
    } catch (error) {
      enqueueSnackbar(
        'Unable to log in. Make sure you are using the correct login email and password, or register a new account',
        { variant: 'error' },
      );

      // Reset form to avoid bots spamming login attempts.
      form.reset();
    }
  };

  /**
   * Signs in with Google.
   * @todo - Handle errors
   */
  const signInGoogle = async () => {
    await signInWithPopup(firebaseAuth, googleProvider);
    navigate(homePath);
  };

  return (
    <Box width="100%" height="100vh" sx={{ backgroundColor: Color.White }}>
      <Box
        sx={{
          minWidth: '300px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Stack gap={2} alignItems="center">
          <Logo />
          <Typography variant="h3">{title}</Typography>

          {/* Email & password login */}
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(signInEmail)}>
              <Stack gap={2}>
                <FormTextField
                  id={LoginField.Email}
                  placeholder="Email Address"
                  type="email"
                  icon={{
                    position: 'start',
                    value: EmailIcon,
                  }}
                  required
                />

                <FormTextField
                  id={LoginField.Password}
                  type="password"
                  placeholder="Password"
                  icon={{
                    position: 'start',
                    value: LockIcon,
                  }}
                  required
                />

                <PrimaryButton type="submit">Login</PrimaryButton>
              </Stack>
            </Form>
          </FormProvider>

          <span>Or</span>

          {/* Google Login */}
          <SecondaryButton fullWidth startIcon={<GoogleIcon />} onClick={signInGoogle}>
            Sign in with Google
          </SecondaryButton>

          <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" justifyItems="center" spacing={1}>
              <span>Don't have an account?</span>
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                <Link to={vendorRoutes.register.path}>Sign Up!</Link>
              </Box>
            </Stack>

            {/* These are not public pages, so clicking will result in a redirect to login... */}
            {/*   <Stack direction="row" justifyItems="center" spacing={2}>
              <Box component="span">
                <a href={vendorRoutes.termsOfService.getPath()}>Terms of Service</a>
              </Box>
              <Box component="span">
                <a href={vendorRoutes.privacyPolicy.getPath()}>Privacy Policy</a>
              </Box>
            </Stack> */}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
