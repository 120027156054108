import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { Card } from '@components/Card';
import { PrimaryButton } from '@components/PrimaryButton';
import { StatusTag, StatusTagType } from '@components/StatusTag';
import { useRemoveVehicle } from '@hooks/Vehicle';
import { useToggleVehicleStatus } from '@hooks/Vehicle/useToggleVehicleStatus';
import { Vehicle, VehicleStatus } from '@lib/firebase';
import { vendorRoutes } from '@routes/vendor';

/**
 * Returns Vehicle list item
 */
export const VehicleListItem = ({ vehicle }: { vehicle: Vehicle }) => {
  const navigate = useNavigate();
  const refresh = () => location.reload();

  const statuses = [
    { type: StatusTagType.Error, match: () => vehicle.status === VehicleStatus.Inactive },
    { type: StatusTagType.Success, match: () => vehicle.status === VehicleStatus.Active },
  ];

  // Hooks for submitting information
  const { submit: removeVehicle } = useRemoveVehicle();
  const { submit: toggleVehicleStatus } = useToggleVehicleStatus();

  // Removes vehicle from DB
  const onRemoveVehicleClick = () => {
    removeVehicle({ id: vehicle.id }, { onCompleted: refresh });
  };

  const onVehicleStatusToggleClick = () => {
    toggleVehicleStatus({ id: vehicle.id }, { onCompleted: refresh });
  };

  const isActive = vehicle.status === VehicleStatus.Active;

  return (
    <Card>
      <Stack gap={2}>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
            <StatusTag conditions={statuses} text={vehicle.status} />
            <Typography variant="h4">{vehicle.name}</Typography>
          </Stack>

          <Stack direction="row" alignItems="right" gap={1} flexWrap="wrap">
            <PrimaryButton
              onClick={onVehicleStatusToggleClick}
              color={isActive ? 'error' : 'success'}
            >
              {isActive ? 'Deactivate' : 'Activate'}
            </PrimaryButton>
            <PrimaryButton
              onClick={() => navigate(`${vendorRoutes.editVehicle.getPath()}/${vehicle.id}`)}
              color="warning"
            >
              Edit
            </PrimaryButton>
            <PrimaryButton onClick={onRemoveVehicleClick} color="error">
              Remove
            </PrimaryButton>
          </Stack>
        </Stack>
        <Typography variant="body1">{vehicle.description}</Typography>
      </Stack>
    </Card>
  );
};
