import Stack from '@mui/material/Stack';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Card } from '@components/Card';
import { Form } from '@components/Form';
import { PrimaryButton } from '@components/PrimaryButton';
import { useUniqueCustomerProfile, useCreateCustomerProfile } from '@hooks/CustomerProfile';
import { useSnackbar } from '@hooks/useSnackbar';
import { CustomerProfileField } from '@lib/firebase';
import { BasicCustomer } from '@lib/firestore/Customer';
import { vendorRoutes } from '@routes/vendor';

import { BasicPricing } from '../components/BasicPricing';

export enum CreateCustomPricingField {
  Customer = 'customer',
}

type ICreateCustomerProfileForm = {
  [CreateCustomPricingField.Customer]: BasicCustomer; // Comes from autocomplete hook
  [CustomerProfileField.DiscountRate]: number;
};

export const CreateCustomerProfile = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<ICreateCustomerProfileForm>({
    mode: 'onSubmit',
  });

  const { submit: addVehicle } = useCreateCustomerProfile();
  const { submit: checkUniquePricing } = useUniqueCustomerProfile();

  const onSubmit = (values: ICreateCustomerProfileForm) => {
    const customerName = values.customer.operatingName ?? values.customer.legalBusinessName;
    const data = {
      customer_id: values.customer.uid,
      discount_rate: values.discount_rate,
    };

    // TODO: Remove this hideous nested callback-shit! Don't wanna push the dynamic pricing feature further out atm...
    checkUniquePricing(data.customer_id, {
      onCompleted: (existingPricing) => {
        // Rate for customer + vendor already exists - avoid duplicates
        // TODO: Once we move to production DB, put unique index constraint on DB model itself!
        if (existingPricing) {
          return enqueueSnackbar(`A custom profile for ${customerName} was already set`, {
            variant: 'error',
          });
        }

        addVehicle(data, {
          onCompleted: () => {
            navigate(vendorRoutes.customerProfiles.path);
          },
        });
      },
    });
  };

  return (
    <>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack direction="column" gap={2}>
            <Card>
              <BasicPricing />
            </Card>

            <PrimaryButton type="submit">Submit Profile</PrimaryButton>
          </Stack>
        </Form>
      </FormProvider>
    </>
  );
};
