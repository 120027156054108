import Box from '@mui/material/Box';

import { ContactUs } from '@components/Contact';
import { Color } from '@theme/palette';

export const BookingDisclaimer = () => (
  <Box
    sx={{
      border: `1px solid ${Color.Gray3}`,
      borderRadius: '8px',
      backgroundColor: Color.DisclaimerGray,
      padding: 2,
    }}
  >
    For any questions or direct support, please email <ContactUs />.
  </Box>
);
