import Stack from '@mui/material/Stack';
import { isAfter } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { IAddressForm } from '@components/Address/fields';
import { Card } from '@components/Card';
import { Form } from '@components/Form';
import { PrimaryButton } from '@components/PrimaryButton';
import { FormTextField } from '@components/TextField';
import { useSnackbar } from '@hooks/useSnackbar';
import { useCreateVehicle, useEditVehicle, useVehicle } from '@hooks/Vehicle';
import {
  AddressField,
  VehicleField,
  VehicleAvailabilityField,
  VehiclePricingField,
} from '@lib/firebase';
import { vendorRoutes } from '@routes/vendor';
import { getAddressWithCoordinates } from '@utils/address';

import { VehicleAddress } from '../components/VehicleAddress';
import { VehicleAvailability } from '../components/VehicleAvailability';
import { VehiclePricing } from '../components/VehiclePricing';

// TODO: Move into its bonsai-models to ensure the firestore API request is correct
type ICreateVehicleForm = {
  [VehicleField.Name]: string;
  [VehicleField.Description]: string;
  [VehicleField.DispatchLocation]: string;
  [VehicleField.Status]: string;
  [VehicleField.VendorId]: string;
  [VehiclePricingField.HourlyRate]: number;
  [VehiclePricingField.DailyRate]: number;
  [VehiclePricingField.AdditionalPrice]?: number | undefined;
  [VehicleAvailabilityField.UnavailableDate]?: {
    start?: Date;
    end?: Date;
    reason?: string;
  };
} & IAddressForm;

export const CreateVehicle = () => {
  // TODO: I would advise to slit create/edit!
  const { id } = useParams();
  const { data: vehicle, isLoading } = useVehicle(id);
  const { enqueueSnackbar } = useSnackbar();

  const defaultAddress = {
    [AddressField.address1]: vehicle?.dispatch_location.address1 || '',
    [AddressField.address2]: vehicle?.dispatch_location.address2 || '',
    [AddressField.city]: vehicle?.dispatch_location.city || '',
    [AddressField.state]: vehicle?.dispatch_location.state || '',
    [AddressField.postalCode]: vehicle?.dispatch_location.postal_code || '',
    [AddressField.country]: vehicle?.dispatch_location.country || '',
  };

  const defaultValues = {
    ...defaultAddress,
    [VehicleField.Name]: vehicle?.name,
    [VehicleField.Description]: vehicle?.description,
    [VehiclePricingField.HourlyRate]: vehicle?.hourly_flat_price,
    [VehiclePricingField.DailyRate]: vehicle?.daily_price,
    [VehiclePricingField.AdditionalPrice]: vehicle?.additional_flat_price || 0,

    // TODO: Avoid the whole Timestamp vs. Date (use unix across the board!)
    [VehicleAvailabilityField.UnavailableDate]: vehicle?.unavailable_date
      ? {
          start: vehicle?.unavailable_date.start.toDate(),
          end: vehicle?.unavailable_date.end.toDate(),
          reason: vehicle?.unavailable_date.reason,
        }
      : undefined,
  };

  const isEditMode = id != null;

  useEffect(() => {
    if (isEditMode) {
      form.reset(defaultValues);
    }
  }, [vehicle]);

  const form = useForm<ICreateVehicleForm>({
    mode: 'onSubmit',
    defaultValues: isEditMode ? undefined : defaultValues,
  });

  const { submit: addVehicle } = useCreateVehicle();
  const { submit: editVehicle } = useEditVehicle();
  const navigate = useNavigate();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onSubmit = async (values: ICreateVehicleForm) => {
    const {
      name = '',
      description = '',
      hourly_flat_price,
      additional_flat_price = 0,
      unavailable_date,
      daily_price,
    } = values;

    const dispatchLocation = await getAddressWithCoordinates(values);

    if (!dispatchLocation) {
      return enqueueSnackbar('Please provide a valid address', { variant: 'error' });
    }

    // TODO: Move into its own function validating all of this.
    // TODO: Avoid the whole Timestamp vs. Date (use unix across the board!)
    const unavailableStart = unavailable_date?.start
      ? Timestamp.fromDate(unavailable_date.start)
      : undefined;
    const unavailableEnd = unavailable_date?.end
      ? Timestamp.fromDate(unavailable_date.end)
      : undefined;
    const unavailableReason = unavailable_date?.reason;

    // Check whether both unavailable dates are provided
    // TODO: Use react-hook-form validation
    if ((unavailableStart && !unavailableEnd) || (unavailableEnd && !unavailableStart)) {
      return enqueueSnackbar('Please provide both unavailable dates', { variant: 'error' });
    }

    if (unavailable_date?.start && unavailable_date?.end) {
      // Check whether end date is after start date
      if (isAfter(unavailable_date?.start, unavailable_date?.end)) {
        return enqueueSnackbar('End date needs to be past start date for unavailability', {
          variant: 'error',
        });
      }

      // Check whether unavailable reason is provided
      if (!unavailableReason) {
        return enqueueSnackbar(
          'You need to provide a reason for setting your vehicle unavailable',
          {
            variant: 'error',
          },
        );
      }
    }

    const data = {
      [VehicleField.Name]: name,
      [VehicleField.Description]: description,
      [VehicleField.DispatchLocation]: dispatchLocation,
      [VehiclePricingField.HourlyRate]: hourly_flat_price,
      [VehiclePricingField.DailyRate]: daily_price || hourly_flat_price * 8,
      [VehiclePricingField.AdditionalPrice]: additional_flat_price,

      // TODO: This is atrocious, but I don't have time to fix it now.
      [VehicleAvailabilityField.UnavailableDate]:
        unavailableStart && unavailableEnd && unavailableReason
          ? {
              start: unavailableStart,
              end: unavailableEnd,
              reason: unavailableReason,
            }
          : null,
      // [VehicleField.Pricing]: pricing,
    };

    if (isEditMode) {
      const updateValues = { id, ...data };

      // Execute DB call & inform on success
      editVehicle(updateValues, {
        onCompleted: () => {
          enqueueSnackbar('Vehicle updated successfully', { variant: 'success' });
        },
      });
    } else {
      addVehicle(data, {
        onCompleted: () => {
          navigate(vendorRoutes.vehicles.path);
        },
      });
    }
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack direction="column" gap={2}>
          <Card>
            <Stack gap={2}>
              <FormTextField
                id={VehicleField.Name}
                label="Registration Name"
                placeholder="Provide an easy to identify Hydrovac name"
                type="text"
                isLoading={isLoading}
                required
              />

              <Stack gap={2} direction="column">
                <VehicleAddress isLoading={isLoading} isEditMode={isEditMode} />
                <VehiclePricing isLoading={isLoading} />
                <VehicleAvailability isLoading={isLoading} />
              </Stack>

              {/** Disabled in favour of flat pricing - might be added back in future */}
              {/* <VehiclePriceList id={VehicleField.Pricing} isLoading={isLoading} /> */}
            </Stack>
          </Card>
          <PrimaryButton type="submit">{isEditMode ? 'Edit' : 'Add'} Vehicle</PrimaryButton>
        </Stack>
      </Form>
    </FormProvider>
  );
};
