/* eslint-disable @typescript-eslint/no-explicit-any */
import FormControl from '@mui/material/FormControl';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { SvgIconProps } from '@mui/material/SvgIcon';
import MuiTextField, { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import { path } from 'ramda';
import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import { FormLabel } from '@components/Label';
import { getUniqueId } from '@utils/id';

import { TextFieldError } from './Error';
import { TextFieldIcon } from './Icon';
import { TextFieldSensitive } from './Sensitive';
import { TextFieldTooltip } from './Tooltip';
import { getReactHookFormProps } from './utils/form';

export type TextFieldProps = MUITextFieldProps & {
  /**
   * Support for icon showing at start or end of input.
   */
  icon?: {
    value: React.FC<SvgIconProps>;
    position?: 'start' | 'end';
  };

  /**
   * Sets empty string values to null.
   */
  setEmptyAsNull?: boolean;

  /**
   * Automatically register field with react-hook-form.
   */
  useHook?: boolean;

  /**
   * React-hook-form options.
   */
  options?: RegisterOptions<any, any>;

  /**
   * Support for showing loading skeleton
   */
  isLoading?: boolean;

  /**
   * Support for copying value to clipboard
   */
  allowCopy?: boolean;

  /**
   * Tooltip to show potential extra information
   */
  tooltip?: string;

  /**
   * Support for showing/hiding sensitive values (show eye icon)
   */
  isSensitive?: boolean;
};

/**
 * Styled MUI text field.
 */
export const StyledTextField = (props: TextFieldProps) => (
  <MuiTextField size="small" InputLabelProps={{ shrink: true }} fullWidth {...props} />
);

/**
 * Input text field with optional label & icon.
 * Supports react-hook-form out-of-the-box.
 */
export const TextField: React.FC<TextFieldProps> = ({
  id = getUniqueId(),
  label,
  error,
  className,
  helperText,
  icon,
  tooltip,
  options, // React-hook-form options
  isLoading = false,
  useHook = false,
  setEmptyAsNull = false,
  isSensitive = false,
  ...muiProps
}) => {
  // React-hook-form support
  const { formState: { errors = {} } = {} } = useFormContext<any>() || {};

  // Support for both react-hook-form errors and passed along errors
  // Use R.path so ids with dot notation can be found
  const errorMessage = (path(id.split('.'), errors) as any)?.message || helperText;
  const hasError = Boolean(errorMessage) || error;

  // [Optional] - React-hooks form support
  const registerProps = useHook
    ? getReactHookFormProps({ id, setEmptyAsNull, options, ...muiProps })
    : {};

  // Allow merging of input props (e.g. icon + copy)
  muiProps['InputProps'] = {};

  // [Optional] Sensitive value support
  const sensitiveProps = TextFieldSensitive({ isSensitive });
  if (sensitiveProps?.component) {
    muiProps['InputProps'].endAdornment = sensitiveProps.component;
  }

  // [Optional] Icon support
  const iconProps = TextFieldIcon({ icon });
  if (iconProps?.component) {
    muiProps['InputProps'][iconProps.position] = iconProps.component;
  }

  return (
    <FormControl variant="outlined" sx={{ width: '100%' }} className={className} error={hasError}>
      {label && (
        <Stack direction="row">
          <FormLabel id={id} label={label} />

          {/* Optional tooltip */}
          <TextFieldTooltip tooltip={tooltip} />
        </Stack>
      )}

      {isLoading ? (
        <Skeleton sx={{ transform: 'none' }} height={`${Number(muiProps.minRows || 1) * 36}px`} />
      ) : (
        <StyledTextField
          id={id}
          error={hasError}
          type={sensitiveProps?.type || muiProps.type}
          {...registerProps}
          {...muiProps}
        />
      )}

      {/** Optional error message */}
      {hasError && <TextFieldError errorMessage={errorMessage} />}
    </FormControl>
  );
};

/**
 * Text field using react-hook-form by default.
 */
export const FormTextField: React.FC<TextFieldProps> = (props) => <TextField useHook {...props} />;
