import Stack from '@mui/material/Stack';

import { FormAutocomplete } from '@components/Autocomplete';
import { Loader } from '@components/Loader';
import { StyledTextField } from '@components/TextField';
import { useCustomerList } from '@hooks/Customer';

export type CustomerAutoCompleteProps = {
  /**
   * Form ID used for react-hook-form.
   */
  id: string;

  /**
   * Indicate if external data is loading and should block rendering the available price list.
   * Mainly used to block rendering when the vehicle categories are loading during edit mode.
   */
  isLoading?: boolean;
};

export const CustomerAutocomplete = ({ id, isLoading }: CustomerAutoCompleteProps) => {
  const { users: customers, loading: isLoadingCustomers, error } = useCustomerList();

  if (error) {
    return <div>Cannot load available customers! Please contact support!</div>;
  }

  if (isLoading || isLoadingCustomers || !customers) {
    return <Loader text="Loading customers..." />;
  }

  return (
    <Stack direction="column">
      <FormAutocomplete
        id={id}
        options={customers}
        getOptionLabel={(option) => option.operatingName ?? option.legalBusinessName}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label="Customers"
            placeholder="Search for customer"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Stack>
  );
};
