import PhoneIcon from '@mui/icons-material/Phone';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FormTextField } from '@components/TextField';
import { PersonalProfileField } from '@lib/firebase';

export const OnboardingPersonal = () => (
  <Stack direction="column" gap={1}>
    <Typography variant="h5">Personal information</Typography>

    <Stack direction="column" gap={2}>
      <Stack direction="row" gap={2}>
        <FormTextField
          id={PersonalProfileField.FirstName}
          label="First name"
          placeholder="Your first name"
          type="text"
          required
        />

        <FormTextField
          id={PersonalProfileField.LastName}
          label="Last name"
          placeholder="Your last name"
          type="text"
          required
        />
      </Stack>
      <FormTextField
        id={PersonalProfileField.PersonalPhone}
        icon={{
          value: PhoneIcon,
          position: 'start',
        }}
        label="Personal phone"
        placeholder="Personal phone number (incl. extension)"
        type="phone"
        required
      />
    </Stack>
  </Stack>
);
