import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FormAddress } from '@components/Address';
import { FormTextField } from '@components/TextField';
import { COMPANY_ADDRESS_PREFIX, PersonalProfileField } from '@lib/firebase';

type OnboardingCompanyAddressProps = {
  /**
   * Indicates whether to show address autocomplete.
   * Mainly used when address is already known. Hide autocomplete to avoid confusion.
   * @default true
   */
  showAddressAutocomplete?: boolean;
};

export const OnboardingCompany = () => (
  <Stack direction="column" gap={1}>
    <Typography variant="h5">Company information</Typography>

    <Stack direction="column" gap={2}>
      <Stack direction="row" gap={2}>
        <FormTextField
          id={PersonalProfileField.LegalBusinessName}
          label="Legal business name"
          placeholder="Company's legal business name"
          type="text"
          required
        />

        <FormTextField
          id={PersonalProfileField.OperatingName}
          label="Operating name"
          placeholder="Company's operating name"
          type="text"
          required
        />
      </Stack>

      <FormTextField
        id={PersonalProfileField.CompanyEmail}
        icon={{
          value: MailOutlineIcon,
          position: 'start',
        }}
        label="Company email"
        placeholder="Business email address"
        type="email"
        required
      />

      <FormTextField
        id={PersonalProfileField.CompanyPhone}
        icon={{
          value: PhoneIcon,
          position: 'start',
        }}
        label="Company phone"
        placeholder="Business phone number (incl. extension)"
        type="tel"
        required
      />
    </Stack>
  </Stack>
);

export const OnboardingCompanyAddress = ({
  showAddressAutocomplete = true,
}: OnboardingCompanyAddressProps) => (
  <Stack direction="column" gap={1}>
    <Typography variant="h5">Company address</Typography>
    <FormAddress prefix={COMPANY_ADDRESS_PREFIX} useAutoComplete={showAddressAutocomplete} />
  </Stack>
);
