import MenuIcon from '@mui/icons-material/Menu';
import Person4Icon from '@mui/icons-material/Person4';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ReactNode, useState } from 'react';

import { Logo } from '@components/Logo';
import { UserSpoofer } from '@components/UserSpoofer';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { Color } from '@theme/palette';

import { useGetPageTitle } from '../../utils/pageTitle';
import { MenuItem } from './MenuItem';

interface MenuProps {
  /**
   * Menu items to show.
   */
  children: ReactNode;

  /**
   * Link to profile page.
   */
  profilePath: string;
}

export const drawerWidth = 280;

/**
 * Returns a side menu with top and bottom sections.
 */
export const Menu = ({ children, profilePath }: MenuProps) => {
  const { title: pageTitle } = useGetPageTitle();
  const [mobileOpen, setMobileOpen] = useState(false);
  const {
    currentUser: { name, email, profilePictureURL },
  } = useCurrentUser();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const content = (
    <>
      {/* Top menu */}
      <Stack
        direction="column"
        sx={{
          paddingLeft: '20px',
          paddingRight: '15px',
        }}
      >
        <Stack direction="row" justifyContent="flex-start" sx={{ marginTop: '30px' }}>
          <Logo />
        </Stack>

        {/* Menu items */}
        <List
          sx={{
            marginTop: '10px',
          }}
        >
          {children}
        </List>
      </Stack>

      {/* Bottom menu */}
      <Stack direction="column" gap={1}>
        <Stack
          direction="column"
          sx={{
            paddingLeft: '20px',
            paddingRight: '15px',
          }}
        >
          <List
            sx={{
              marginTop: '10px',
            }}
          >
            <MenuItem label="My Account" path={profilePath} icon={Person4Icon} />
          </List>
          <Divider />
        </Stack>

        <UserSpoofer />

        <Stack
          direction="row"
          gap={1}
          sx={{
            display: { xs: 'none', sm: 'flex' }, // Hide on mobile
            backgroundColor: Color.White,
            padding: '18px 20px',
          }}
        >
          {profilePictureURL && (
            <Box
              component="img"
              src={profilePictureURL}
              alt="Profile "
              sx={{ height: '50px', borderRadius: '50%' }}
            />
          )}
          <Stack direction="column" gap={0.3} justifyContent="center">
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {name}
            </Typography>
            <Box component="span" sx={{ color: Color.Gray1, fontSize: '0.8rem' }}>
              {email}
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );

  return (
    <>
      <CssBaseline />

      {/* Mobile Toolbar (collapsed menu) */}
      <Toolbar
        sx={{
          backgroundColor: Color.White,
          padding: '10px 10px',
          position: 'fixed',
          width: '100%',
          display: { md: 'none' },
          zIndex: 9,
        }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <IconButton color="inherit" aria-label="open menu" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>

          <Logo width={30} />
          <Typography variant="h6" noWrap component="div">
            {pageTitle}
          </Typography>
        </Stack>
      </Toolbar>

      {/* Mobile menu */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor="left"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        onClick={() => {
          // Since there is no control over children menu items, ensure the mobile menu closes after clicking anywhere
          setTimeout(handleDrawerToggle, 100);
        }}
      >
        {content}
      </Drawer>

      {/* Desktop menu */}
      <Drawer
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            justifyContent: 'space-between',
            width: drawerWidth,
            boxSizing: 'border-box',
            border: 'none',
          },
        }}
        anchor="left"
        variant="permanent"
      >
        {content}
      </Drawer>
    </>
  );
};
