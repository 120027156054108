import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { Card } from '@components/Card';
import { PrimaryButton } from '@components/PrimaryButton';
import { StatusTag, StatusTagType } from '@components/StatusTag';
import { useRemoveCustomerPricing } from '@hooks/CustomerProfile';
import { IExtendedCustomerProfile } from '@lib/firestore/CustomerProfile';
import { vendorRoutes } from '@routes/vendor';

import { CustomerProfileField } from './CustomerProfileField';

/**
 * Returns Custom profile list item
 */
export const CustomerProfileListItem = ({ profile }: { profile: IExtendedCustomerProfile }) => {
  const navigate = useNavigate();
  const refresh = () => location.reload();

  const { submit: removeCustomerPricing } = useRemoveCustomerPricing();

  // Removes custom price from DB
  const onRemoveCustomPriceClick = () => {
    removeCustomerPricing({ id: profile.id }, { onCompleted: refresh });
  };

  // Only show discount if available & above 0%
  const hasDiscount = profile?.discount_rate != null && profile.discount_rate > 0;

  return (
    <Card>
      <Stack gap={2} direction="column">
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Typography variant="h4">
            {profile.customer.operatingName ?? profile.customer.legalBusinessName}
          </Typography>

          <StatusTag text={profile.customer.email} type={StatusTagType.Success} />
        </Stack>

        {hasDiscount && (
          <CustomerProfileField label="Discount Rate" value={`${profile.discount_rate}%`} />
        )}

        <Stack direction="row" alignSelf="flex-start" gap={1} flexWrap="wrap">
          <PrimaryButton
            onClick={() => navigate(`${vendorRoutes.editCustomerProfile.getPath()}/${profile.id}`)}
            color="warning"
          >
            Edit
          </PrimaryButton>
          <PrimaryButton onClick={onRemoveCustomPriceClick} color="error">
            Remove
          </PrimaryButton>
        </Stack>
      </Stack>
    </Card>
  );
};
