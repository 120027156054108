import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';

import { FormAddress } from '@components/Address';
import { FormCheckbox } from '@components/Checkbox';
import { FormTextField } from '@components/TextField';
import { INVOICE_ADDRESS_PREFIX, InvoicingProfileField } from '@lib/firebase';
/**
 * Returns invoicing address form
 */
export const OnboardingInvoicingAddress = () => {
  const { watch } = useFormContext();

  const useSeparateInvoiceAddress = watch(InvoicingProfileField.SeperateInvoiceAddress, false);

  return (
    <Stack direction="column" gap={1}>
      <Typography variant="h5">Invoice Address</Typography>

      <Stack direction="column" gap={2}>
        <FormCheckbox
          id={InvoicingProfileField.SeperateInvoiceAddress}
          label="Use a seperate invoicing address?"
        />

        {/** Invoicing address - render when different from company address*/}
        {useSeparateInvoiceAddress && <FormAddress prefix={INVOICE_ADDRESS_PREFIX} />}
      </Stack>
    </Stack>
  );
};

/**
 * Returns invoicing contact information form
 */
export const OnboardingInvoicingContactInfo = () => (
  <Stack direction="column" gap={1}>
    <Typography variant="h5">Contact Information</Typography>

    <Stack direction="column" gap={2}>
      <FormTextField
        id={InvoicingProfileField.InvoiceEmail}
        icon={{
          value: MailOutlineIcon,
          position: 'start',
        }}
        label="Invoice email address"
        placeholder="Email used for managing invoice communication"
        type="email"
        required
      />
      <FormTextField
        id={InvoicingProfileField.InvoicePhone}
        icon={{
          value: PhoneIcon,
          position: 'start',
        }}
        label="Invoice phone"
        placeholder="Phone number (incl. extension) used for invoices"
        type="phone"
        required
      />
    </Stack>
  </Stack>
);

/**
 * Returns invoicing indiginous form
 */
export const OnboardingInvoicingIndiginous = () => {
  const { watch } = useFormContext();

  const isIndigenous = watch(InvoicingProfileField.IndigenousOwned, false);

  return (
    <Stack direction="column" gap={1}>
      <Typography variant="h5">Indigenous Status</Typography>

      <Stack direction="column" gap={2}>
        <FormCheckbox
          id={InvoicingProfileField.IndigenousOwned}
          label="Indigenous Status (Optional)"
        />

        {isIndigenous && (
          <FormTextField
            id={InvoicingProfileField.IndigenousId}
            placeholder="Indigenous Tax ID"
            type="text"
            required={isIndigenous}
          />
        )}
      </Stack>
    </Stack>
  );
};
