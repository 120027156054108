import { Color } from '@theme/palette';

type ColoredTextFieldProps = {
  /**
   * Optional className to customize component.
   */
  className?: string;

  /**
   * Text value to display.
   */
  text?: string;
};

/**
 * Returns StatusTag component.
 */
export const ColoredTextField = ({
  className,
  text,
  ...propsRest
}: ColoredTextFieldProps & Omit<React.HTMLProps<HTMLInputElement>, 'type'>) => {
  return (
    <input
      type="text"
      className={className}
      value={text}
      style={{
        backgroundColor: Color.BackgroundGreen,
        display: 'inline-block',
        textAlign: 'center',
        border: 'none',
        borderRadius: '4px',
        boxSizing: 'unset',
        height: '2rem',
        width: '8rem',
        fontWeight: 600,
        color: Color.Green,
        fontSize: '0.8rem',
        margin: '0.1rem',
        padding: 0,
      }}
      {...propsRest}
    />
  );
};
