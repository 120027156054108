import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Card } from '@components/Card';
import { ContactUs } from '@components/Contact';

export const TermsOfService = () => {
  return (
    <Card>
      <Stack direction={'column'} gap={2} sx={{ padding: '15' }}>
        <Box>
          <h1>Terms and Conditions for Blue Collar Apps</h1>

          <p>Last Updated: July 2023</p>
        </Box>

        <Box>
          <h2>Introduction and Acceptance</h2>
          <p>
            Welcome to Blue Collar Apps. By accessing and using Blue Collar Apps, you agree to be
            bound by these Terms and Conditions.
          </p>
        </Box>
        <Box>
          <h2>Definitions</h2>
          <p>
            "Marketplace" refers to Blue Collar Apps, the platform connecting buyers and sellers of
            excavation and related services.
          </p>

          <p>"Buyers" refer to users seeking excavation and related services.</p>

          <p>"Sellers" refer to service providers offering excavation and related services.</p>

          <p>"User" refers to any individual or entity using the Marketplace.</p>
        </Box>

        <Box>
          <h2>Description of Service</h2>

          <p>
            Blue Collar Apps is a technology platform that facilitates connections between Buyers
            and Sellers for excavation and related services in Canada and the United States of
            America. The Marketplace offers features to match jobs to service providers, track
            essential documents, manage invoices and payments, and track past work.
          </p>
        </Box>
        <Box>
          <h2>User Accounts</h2>

          <p>
            Users are responsible for maintaining the confidentiality of their account credentials
            and must provide accurate and up-to-date information during registration.
          </p>
        </Box>
        <Box>
          <h2>User Obligations</h2>

          <ol>
            <li>
              Buyers must provide accurate job details to receive appropriate excavation service
              quotes.
            </li>
            <li>Sellers must accurately represent their qualifications and capabilities.</li>
            <li>
              Users must comply with all applicable laws and regulations while using the
              Marketplace.
            </li>
            <li>
              Users must not engage in circumvention, fraudulent, abusive, or harmful activities on
              the platform.
            </li>
          </ol>
        </Box>

        <Box>
          <h2>Payment and Billing</h2>

          <p>
            Blue Collar Apps may charge fees for specific services, which will be clearly
            communicated to Users. Users agree to pay all fees and charges associated with their use
            of the Marketplace.
          </p>
        </Box>
        <Box>
          <h2>Data Privacy and Security</h2>

          <p>
            Blue Collar Apps collects, uses, and stores user data in accordance with its Privacy
            Policy. Users are responsible for maintaining the confidentiality of any sensitive
            information shared on the platform.
          </p>
        </Box>
        <Box>
          <h2>Intellectual Property</h2>

          <p>
            Users must respect the intellectual property rights of Blue Collar Apps and other Users.
            Users retain ownership of any content, including text, images, videos, or other
            materials ("User Content"), that they upload or submit to the Marketplace. By uploading
            or submitting User Content to the Marketplace, Users grant Blue Collar Apps a
            non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and sublicensable right
            to use, reproduce, modify, adapt, publish, translate, create derivative works from,
            distribute, perform, and display such User Content solely for the purposes of operating,
            promoting, and improving the Marketplace. Users represent and warrant that they have all
            necessary rights, licenses, and permissions to grant these rights without violating the
            rights of any third party. Users acknowledge that Blue Collar Apps may use aggregated
            and anonymized data, which does not identify Users or disclose any confidential
            information, for statistical analysis, research, and other legitimate business purposes.
          </p>
        </Box>
        <Box>
          <h2>Prohibited Activities</h2>

          <p>
            Users must not engage in any activities that violate the law or the rights of others.
            Users must not attempt to manipulate the platform or engage in any form of hacking or
            data breaches.
          </p>
        </Box>
        <Box>
          <h2>Limitation of Liability</h2>

          <p>
            Blue Collar Apps is not liable for any direct, indirect, or consequential damages
            arising from the use of the platform. Blue Collar Apps does not guarantee the quality or
            availability of excavation services provided by Sellers.
          </p>
        </Box>
        <Box>
          <h2>Disclaimer of Warranties</h2>

          <p>
            Blue Collar Apps does not make any warranties regarding the accuracy, reliability, or
            completeness of the information provided on the platform. Blue Collar Apps does not
            warrant that the platform will be error-free or uninterrupted.
          </p>
        </Box>

        <Box>
          <h2>Termination</h2>

          <p>
            Either party may terminate the user's access to the Marketplace at any time for any
            reason. Termination of user accounts may result in the deletion of data associated with
            the account.
          </p>
        </Box>

        <Box>
          <h2>Modification of Terms</h2>

          <p>
            Blue Collar Apps reserves the right to modify these Terms and Conditions at any time.
            Users will be notified of any changes, and continued use of the platform after
            notification constitutes acceptance of the updated terms.
          </p>
        </Box>

        <Box>
          <h2>Governing Law and Jurisdiction</h2>

          <p>
            These Terms and Conditions are governed by the laws of Ontario, Canada. Any disputes
            arising from the use of the platform will be subject to the exclusive jurisdiction of
            the courts in Ontario, Canada.
          </p>
        </Box>

        <Box>
          <h2>Contact Information</h2>

          <p>
            For any questions or inquiries, Users can contact Blue Collar Apps at <ContactUs />.
          </p>
        </Box>

        <Box>
          <h2>Miscellaneous</h2>

          <p>
            These Terms and Conditions constitute the entire agreement between Users and Blue Collar
            Apps. If any provision of these Terms and Conditions is found to be invalid or
            unenforceable, the remaining provisions shall remain in full force and effect.
          </p>

          <p>
            Blue Collar Apps reserves all rights not expressly granted in these Terms and
            Conditions.
          </p>
        </Box>
      </Stack>
    </Card>
  );
};
