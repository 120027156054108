import { useAuth } from '@hooks/useAuth';
import { useEffectFetchData, useSubmitData } from '@hooks/useData';
import { VehicleField } from '@lib/firebase';
import {
  ICreateVehicle,
  IEditVehicle,
  createVehicle,
  getVehicleById,
  removeVehicle,
  updateVehicle,
} from '@lib/firestore/Vehicle';

/**
 * Returns hook for fetching vehicle by id.
 * @param id - Unique vehicle ID.
 */
export const useVehicle = (id?: string) => {
  // TODO: This logic should not be here. Edit/Create should be handled separately on different pages.
  if (!id) {
    return { data: undefined, isLoading: false, error: null };
  }

  return useEffectFetchData(() => getVehicleById(id));
};

/**
 * Returns hook for creating vehicle.
 */
export const useCreateVehicle = () => {
  const { currentUser } = useAuth();

  // Vendor ID gets automatically set to current user. No need to pass it in.
  return useSubmitData(async (values: Omit<ICreateVehicle, VehicleField.VendorId>) => {
    if (!currentUser?.uid) {
      return;
    }

    await createVehicle({ ...values, vendor_id: currentUser.uid });
  });
};

/**
 * Returns hook for editing vehicle by id.
 */
export const useEditVehicle = () => {
  const { currentUser } = useAuth();

  return useSubmitData(async ({ id, ...values }: IEditVehicle) => {
    if (!currentUser?.uid) {
      return;
    }

    await updateVehicle(id, values);
  });
};

/**
 * Returns hook for removing vehicle by id.
 */
export const useRemoveVehicle = () => {
  const { currentUser } = useAuth();

  return useSubmitData(async ({ id }: { id: string }) => {
    if (!currentUser?.uid) {
      return;
    }

    await removeVehicle(id);
  });
};
