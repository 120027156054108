import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Option } from 'react-google-places-autocomplete/build/types';
import Select, { ActionMeta, OnChangeValue } from 'react-select';

import {
  getAddressByPlaceId,
  addressAutocompleteMocks,
  AddressAutocompleteResult,
  AddressAutocompleteGeoResult,
} from '@lib/googleApi';

const mockAddressOptions = addressAutocompleteMocks.map(({ place_id, description }) => ({
  label: description,
  value: place_id,
}));

interface AddressAutocompleteProps {
  /**
   * Callback function that gets called when an address gets selected.
   */
  onSelected: (address: AddressAutocompleteGeoResult | null) => void;

  /**
   * Decides whether to use the Google Places Autocomplete component or mocked addresses.
   * Given Google API uses credits, we do not want to use it during development.
   */
  useMockedAddresses?: boolean;

  /**
   * Indicates whether a selected address is required.
   */
  required?: boolean;
}

/**
 * Returns a Google Places Autocomplete component.
 * Uses https://react-select.com as the main select component.
 */
export const AddressAutocomplete = ({
  onSelected,
  useMockedAddresses = false,
  required = false,
}: AddressAutocompleteProps) => {
  /**
   * Handles when an address gets selected.
   */
  const onSelect = async (
    newValue: OnChangeValue<{ label: string; value?: AddressAutocompleteResult }, false>,
    { action }: ActionMeta<Option>,
  ) => {
    if (action === 'select-option' && newValue?.value) {
      const address = await getAddressByPlaceId(newValue.value.place_id, useMockedAddresses);
      onSelected(address);
    }
  };

  return useMockedAddresses ? (
    <Select
      placeholder="Search for an address"
      options={mockAddressOptions}
      onChange={(value, actionMeta) => {
        // Find for the selected value in the mocked addresses.
        const newValue = addressAutocompleteMocks.find((x) => x.place_id === value?.value);
        onSelect({ label: value?.label || '', value: newValue }, actionMeta);
      }}
      isMulti={false}
      isClearable
      closeMenuOnSelect
      required={required}
      // Makes sure the menu is on top of everything else.
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body} //important
    />
  ) : (
    <GooglePlacesAutocomplete
      debounce={1000}
      minLengthAutocomplete={5}
      selectProps={{
        placeholder: 'Search for an address',
        isMulti: false,
        cacheOptions: true,
        isClearable: true,
        closeMenuOnSelect: true,
        onChange: onSelect,
        loadingMessage: () => 'Looking for addresses...',
        required,

        // Makes sure the menu is on top of everything else.
        styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
        menuPortalTarget: document.body, //important
      }}
      autocompletionRequest={{
        componentRestrictions: {
          country: ['us', 'ca'], // Currently only support Canada & US
        },
      }}
    />
  );
};
