import { initializeApp } from 'firebase/app';
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  User,
  UserCredential,
} from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

import { getFirebaseConfig } from '@config/firebase';

/**
 * Firebase App instance.
 * Also initiates Firebase Auth & Functions.
 */
export const firebaseApp = initializeApp(getFirebaseConfig(process.env.NODE_ENV));
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseDb = getFirestore(firebaseApp);
const firebaseFunctions = getFunctions();

/**
 * Allows local testing of Firebase Auth, Functions & Firestore.
 */
if (process.env.EMULATE_FIREBASE === 'true') {
  connectAuthEmulator(firebaseAuth, 'http://127.0.0.1:9099');
  connectFunctionsEmulator(firebaseFunctions, '127.0.0.1', 5001);
  connectFirestoreEmulator(firebaseDb, '127.0.0.1', 9080);
}

/**
 * Creates a new user on Firebase with the given email and password.
 * @param {string} email - Email address
 * @param {string} password - Password.
 */
export const createFirebaseUser = (email: string, password: string): Promise<UserCredential> =>
  createUserWithEmailAndPassword(firebaseAuth, email, password);

/**
 * Sends verification email to the user.
 * @param user - Firebase user.
 */
export const sendFirebaseEmailVerification = (user: User): Promise<void> => {
  const url =
    process.env.OUTPUT_TYPE === 'vendor' ? process.env.PUBLIC_VENDOR_URL : process.env.PUBLIC_URL;
  return sendEmailVerification(user, { url });
};

/**
 * For backwards-compatibility!
 * Ideally we should be using the db-models package directly or use API.
 */
export {
  /**
   * DB models
   */
  type DbAddress as Address,
  type DbCategory as Category,
  type DbAddressCoordinates as AddressCoordinates,
  type DbVehicle as Vehicle,
  type DbJob as Job,
  type DbJobTimestamps as JobTimeStamp,
  type DbJobNote as JobNote,
  type DbInvoice as Invoice,
  type DbVendor as Vendor,
  type DbCustomer as Customer,
  type DbVehicleWithDistance as VehicleWithDistance,
  type DbJobPopulated as JobPopulated,
  type DbProfileCompany as ProfileCompany,
  type DbProfileInvoicing as ProfileInvoicing,
  type DbCustomerProfile as CustomerProfile,

  /**
   * DB fields (enums)
   */
  DbField as Field,
  DbJobField as JobField,
  DbVehiclePricingField as VehiclePricingField,
  DbVehicleAvailabilityField as VehicleAvailabilityField,
  DbJobNoteField as NoteField,
  DbProfileField as PersonalProfileField,
  DbAddressField as AddressField,
  DbVehicleField as VehicleField,
  DbAddressCoordinateField as AddressCoordinateField,
  DbProfileInvoiceField as InvoicingProfileField,
  DbProfileInvoiceAddressField as InvoicingAddressField,
  DbCustomerField as CustomerField,
  DbPaymentTerm as PaymentTerm,
  DbInvoiceField as InvoiceField,
  DbCustomerProfileField as CustomerProfileField,
  DbProfileFeatureFlagField as ProfileFeatureFlagField,

  /**
   * Other constants.
   */
  UserOnboardingState,
  VehicleStatus,
  JobStatus,
  UserType,
  ProvinceCA,
  DbCollection,
  INVOICE_ADDRESS_PREFIX,
  COMPANY_ADDRESS_PREFIX,
  RateMode,
} from '@uvac-apps/db-models';
