import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MUIStepper from '@mui/material/Stepper';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import { Form } from '@components/Form';
import { PrimaryButton } from '@components/PrimaryButton';
import { SecondaryButton } from '@components/SecondaryButton';

export type StepperProps = {
  /**
   * Steps to render.
   */
  steps: {
    /**
     * Label to display.
     */
    label: string;

    /**
     * Renders component for this step.
     */
    component: React.ReactNode;

    /**
     * Decides whether this is an optional step.
     */
    optional?: boolean;

    /**
     * Support for react-hook-form on each step.
     * This allows for form validation on each step.
     * @todo - Use generic type for useForm and onSubmit rather than `any`
     */
    form?: {
      useForm: UseFormReturn<any>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSubmit?: (values: any) => void;
    };
  }[];

  /**
   * Callback function when stepper is finished.
   */
  onFinish?: () => void;

  /**
   * Sets active step on rendering.
   * Useful for allowing updates at specific step.
   */
  activeStep?: number;
};

/**
 * Returns enhanced stepper component.
 */
export const Stepper = ({ steps, onFinish, ...otherProps }: StepperProps) => {
  const [activeStep, setActiveStep] = useState(otherProps?.activeStep || 0);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   * Returns whether current step is the last step.
   */
  const isLastStep = () => activeStep !== steps.length - 1;

  /**
   * Handles basic next step navigation.
   */
  const handleNext = () => {
    if (isLastStep()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (onFinish) {
      onFinish();
    }
  };

  /**
   * Handles next step navigation when using `form` in any step.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNextForm = async (
    formValues: any,
    onFormSubmit?: <T>(values: T) => void | Promise<Error>,
  ) => {
    // Informs parent form has been submitted
    if (onFormSubmit) {
      const result = await onFormSubmit(formValues);
      if (result instanceof Error) {
        return enqueueSnackbar(result.message, { variant: 'error' });
      }
    }

    // After automatic form validation, continue to next step
    handleNext();
  };

  return (
    <Stack direction="column" gap={2} sx={{ width: '100%' }}>
      {/** Renders stepper */}
      <MUIStepper activeStep={activeStep} alternativeLabel>
        {steps.map(({ label, optional }, index) => (
          <Step index={index} key={`${label}_${index}`}>
            <StepLabel optional={optional}>{label}</StepLabel>
          </Step>
        ))}
      </MUIStepper>

      {/** Renders content based on active step */}
      {steps.map(({ component, form }, index) => {
        if (activeStep === index) {
          /**
           * Step rendering with react-hook-form.
           */
          if (form?.useForm) {
            return (
              <FormProvider {...form.useForm} key={index}>
                <Form
                  onSubmit={form.useForm.handleSubmit((data) =>
                    // onSubmit should not be required
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    handleNextForm(data, form.onSubmit || (() => {})),
                  )}
                >
                  {/* Renders component */}
                  {component}

                  {/* Renders step buttons to navigate */}
                  <Stack
                    direction="row"
                    gap={2}
                    justifyContent="space-between"
                    sx={{ marginTop: 2 }}
                  >
                    <SecondaryButton hidden={activeStep === 0} onClick={handleBack}>
                      Back
                    </SecondaryButton>

                    <PrimaryButton type="submit">
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </PrimaryButton>
                  </Stack>
                </Form>
              </FormProvider>
            );
          } else {
            /**
             * Regular step rendering.
             */
            return (
              <Box key={index}>
                {/* Renders component */}
                {component}

                {/* Renders step buttons to navigate */}
                <Stack direction="row" gap={2} justifyContent="space-between" sx={{ marginTop: 2 }}>
                  <SecondaryButton hidden={activeStep === 0} onClick={handleBack}>
                    Back
                  </SecondaryButton>

                  <PrimaryButton onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </PrimaryButton>
                </Stack>
              </Box>
            );
          }
        }
      })}
    </Stack>
  );
};
