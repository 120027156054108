import { ChangeEvent, useState } from 'react';

import { ColoredTextField } from '@components/ColoredTextField';
import { useEditJobVendorId } from '@hooks/Job/useEditJobVendorId';

export interface BookingListItemProps {
  id: string;
  vendor_order_id?: string;
  disabled?: boolean;
}

/**
 * Returns Booking list item
 */
export const BookingVendorId = ({
  id,
  vendor_order_id,
  disabled = false,
}: BookingListItemProps) => {
  const DEFAULT_VENDOR_ORDER_ID = 'Set PO#';
  const [vendorOrderId, setVendorOrderId] = useState<string>(
    vendor_order_id || DEFAULT_VENDOR_ORDER_ID,
  );
  const { submit: editVendorOrderId } = useEditJobVendorId();

  const handleFocus = () => {
    if (vendorOrderId === DEFAULT_VENDOR_ORDER_ID) {
      setVendorOrderId('');
    }
  };

  const handleBlur = () => {
    editVendorOrderId({ id, vendor_order_id: vendorOrderId });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event?.target as HTMLInputElement;
    setVendorOrderId(target.value);
    event.preventDefault();
  };

  return (
    <ColoredTextField
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      value={vendorOrderId}
      disabled={disabled}
    />
  );
};
