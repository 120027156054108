import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { components } from './components';
import { palette } from './palette';

export const theme = createTheme({
  components,
  palette,
  typography: {
    allVariants: { fontFamily: "'Inter', sans-serif" },
    h1: {
      fontSize: '2.5rem',
      fontWeight: 800,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    button: {
      boxShadow: 'none',
      textTransform: 'capitalize',
    },
  },
});

export const Theme = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    {children}
  </ThemeProvider>
);
