import {
  DbCollection,
  DbVehicle,
  DbVehicleField,
  DbVehicleWithDistance,
  VehicleStatus,
} from '@uvac-apps/db-models';
import { where, Timestamp } from 'firebase/firestore';

import {
  createDbDocument,
  getDbDocumentById,
  getDbDocuments,
  getDbFieldValueById,
  removeDbDocumentById,
  updateDbDocumentById,
} from '@lib/firestore';

import { getDocumentsWithinRadius, getClosestDocumentWithinRadiusForVendor } from './geoQuery';
import { ICreateVehicle } from './types';

/**
 * Returns vehicle by id.
 * @param id - Unique vehicle ID.
 */
export const getVehicleById = (id: string) =>
  getDbDocumentById<DbVehicle>(DbCollection.Vehicles, id);

/**
 * Returns list of vehicles for current user.
 * @param currentUserId - Current user ID.
 */
export const getMyVehicles = (currentUserId: string) =>
  getDbDocuments<DbVehicle>(DbCollection.Vehicles, where('vendor_id', '==', currentUserId));

/**
 * Returns available vehicles for customer.
 * @param values - Latitude & longitude.
 * @param radiusInKm - Radius in kilometers.
 * @param currentUserId - Current user ID. Used for providing custom user pricing & discounts.
 */
export const getAvailableVehicles = (
  values: { lat: number; lng: number; categoryId: string; vendorIds?: string[] },
  radiusInKm: number,
  currentUserId: string,
) =>
  getDocumentsWithinRadius<DbVehicleWithDistance>(DbCollection.Vehicles, {
    ...values,
    addressField: DbVehicleField.DispatchLocation,
    currentCustomerId: currentUserId,
    radiusInKm,
  });

/**
 * Returns closest vehicle for specific vendor.
 * @param values - Latitude, longitude & vendor ID.
 * @param radiusInKm - Radius in kilometers.
 * @returns
 */
export const getClosestVehicleFromVendor = (
  values: { lat: number; lng: number; vendorId: string; categoryId: string },
  radiusInKm: number,
  currentUserId: string,
) =>
  getClosestDocumentWithinRadiusForVendor<DbVehicleWithDistance>(DbCollection.Vehicles, {
    ...values,
    addressField: DbVehicleField.DispatchLocation,
    currentCustomerId: currentUserId,
    radiusInKm,
  });

/**
 * Returns vehicle status from specific vehicle by ID.
 * @param id - Unique vehicle ID.
 */
export const getVehicleStatus = (id: string) =>
  getDbFieldValueById<DbVehicle, VehicleStatus>(DbCollection.Vehicles, id, DbVehicleField.Status);

/**
 * Creates vehicle.
 * @param values - Values to insert.
 */
export const createVehicle = (values: ICreateVehicle) => {
  const now = new Date();

  return createDbDocument(DbCollection.Vehicles, {
    ...values,
    status: VehicleStatus.Active,
    created_at: Timestamp.fromDate(now),
    updated_at: Timestamp.fromDate(now),
  });
};

/**
 * Updates vehicle from DB by id.
 * @param id - Unique vendor ID.
 * @param values - Values to update.
 */
export const updateVehicle = (id: string, values: Partial<DbVehicle>) => {
  const now = new Date();

  return updateDbDocumentById(DbCollection.Vehicles, id, {
    ...values,
    updated_at: Timestamp.fromDate(now),
  });
};

/**
 * Removes vehicle from DB by id.
 * @param id - Unique vendor ID.
 */
export const removeVehicle = (id: string) => removeDbDocumentById(DbCollection.Vehicles, id);

/**
 * Updates vehicle status by id.
 * @param id - Unique vehicle ID.
 * @param status - New vehicle status.
 */
export const updateVehicleStatus = async (id: string, status: VehicleStatus) => {
  const now = new Date();

  return updateDbDocumentById<DbVehicle>(DbCollection.Vehicles, id, {
    status,
    updated_at: Timestamp.fromDate(now),
  });
};

/**
 * Makes all types available from this module.
 */
export * from './types';
