import { geohashForLocation } from 'geofire-common';
import { geocodeByAddress, geocodeByPlaceId } from 'react-google-places-autocomplete';

import { getFormattedAddressString } from '@utils/address';

import { Address, AddressCoordinates } from '../firebase';
import { ADDRESS_FIRST_PLACE_ID, addressGeoMocks } from './fixtures';
import { AddressAutocompleteGeoResult, AddressGeoResultType } from './interfaces';

/**
 * Returns value of address component based on type.
 * @param addressComponents - List of address components from Google Geocoding API.
 * @param type - Specific type (eg. country, postal_code, etc.)
 */
export const getAddressComponentValue =
  (addressComponents: google.maps.GeocoderAddressComponent[]) =>
  (type: AddressGeoResultType): google.maps.GeocoderAddressComponent | undefined =>
    addressComponents.find((component) => component.types.includes(type));

/**
 * Returns full address information based on place ID.
 * @param placeId - Place ID from Google Places Autocomplete.
 * @param useMockedAddresses - If true, will return mocked addresses instead of calling Google Geocoding API.
 */
export const getAddressByPlaceId = async (
  placeId: string,
  useMockedAddresses = false,
): Promise<AddressAutocompleteGeoResult | null> => {
  try {
    const [result] = useMockedAddresses
      ? addressGeoMocks[placeId]
      : await geocodeByPlaceId(placeId);

    const components = getAddressComponentValue(result.address_components);

    return {
      streetAddress: components('route')?.long_name,
      streetNumber: components('street_number')?.long_name,
      city: components('locality')?.long_name,
      state: components('administrative_area_level_1')?.long_name,
      stateCode: components('administrative_area_level_1')?.short_name,
      country: components('country')?.long_name,
      countryCode: components('country')?.short_name,
      postalCode: components('postal_code')?.long_name,
    };
  } catch (error) {
    console.error('Error trying to geocode address: ', error);
    return null;
  }
};

/**
 * Returns coordinates from Geo result
 * @param result - Geo result from Google API
 */
export const getCoordinatesFromGeoResult = (
  result: google.maps.GeocoderResult,
): AddressCoordinates | null => {
  if (result.partial_match) {
    console.log('Could only find a partial match. Might be best to check the address.');
  }

  const lng = result.geometry.location.lng();
  const lat = result.geometry.location.lat();

  // If we have coordinates, return them
  if (lat && lng) {
    const geohash = geohashForLocation([lat, lng]);

    return { lat, lng, geohash };
  }

  // Anything else than a matching address is considered an error
  return null;
};

/**
 * Returns coordinates for a given address.
 * @param {object} address - Address object containing all information.
 */
export const getCoordinatesFromAddress = async (
  address: Omit<Address, 'lat' | 'lng' | 'geohash'>,
  useMockedAddresses = false,
): Promise<AddressCoordinates | null> => {
  try {
    const formattedAddress = getFormattedAddressString(address);
    const [result] = useMockedAddresses
      ? addressGeoMocks[ADDRESS_FIRST_PLACE_ID]
      : await geocodeByAddress(formattedAddress);

    return getCoordinatesFromGeoResult(result);
  } catch (error) {
    console.log('Error trying to get coordinates from provided address', error);
    return null;
  }
};

// Make interfaces & mocks available through centralized import
export * from './interfaces';
export * from './fixtures';
