import { useState, useEffect } from 'react';

import { useAuth } from '@hooks/useAuth';
import { getCustomerJobCount, getVendorJobCount, getVendorTotalRevenue } from '@lib/firestore/Job';

export interface CustomerDashboardData {
  jobCount: number;
}

export interface VendorDashboardData {
  jobCount: number;
  totalRevenue: number;
}

/**
 * Returns object with customer dashboard information.
 */
export const getCustomerDashboard = async (uid: string): Promise<CustomerDashboardData> => {
  return {
    jobCount: await getCustomerJobCount(uid),
  };
};

/**
 * Returns object with vendor dashboard information.
 */
export const getVendorDashboard = async (uid: string): Promise<VendorDashboardData> => {
  return {
    jobCount: await getVendorJobCount(uid),
    totalRevenue: await getVendorTotalRevenue(uid),
  };
};

/**
 * Returns hook with vendor dashboard information.
 */
export const useVendorDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);
  const [vendorDashboardData, setVendorDashboardData] = useState<VendorDashboardData>({
    jobCount: 0,
    totalRevenue: 0,
  });
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!currentUser?.uid) return { data: vendorDashboardData, loading, error };

      try {
        const data = await getVendorDashboard(currentUser.uid);
        setVendorDashboardData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return { data: vendorDashboardData, loading, error };
};

/**
 * Returns hook with customer dashboard information.
 */
export const useCustomerDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);
  const [customerDashboardData, setCustomerDashboardData] = useState<CustomerDashboardData>({
    jobCount: 0,
  });
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!currentUser?.uid) {
        return { data: customerDashboardData, loading, error };
      }

      try {
        const data = await getCustomerDashboard(currentUser.uid);
        setCustomerDashboardData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return { data: customerDashboardData, loading, error };
};
