import CalendarIcon from '@mui/icons-material/CalendarMonth';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { BasicInfoCard } from '@components/Card/InfoCard';
import { PrimaryButton } from '@components/PrimaryButton';
import { SecondaryButton } from '@components/SecondaryButton';
import { useMyJobs } from '@hooks/Job/useMyJobs';
import { useCustomerDashboard } from '@hooks/useDashboard';
import { JobStatus, UserType } from '@lib/firebase';
import { customerRoutes } from '@routes/customer';
import { Color } from '@theme/palette';

import { BookingListItem } from '../Booking/components/BookingListItem';

export const Home = () => {
  const navigate = useNavigate();
  const { data: dashboardData } = useCustomerDashboard();
  const { jobs } = useMyJobs(UserType.Customer, {
    status: [JobStatus.Pending, JobStatus.Accepted, JobStatus.Initiated],
  });

  return (
    <Box>
      <Stack direction="column" gap={4}>
        {/** Overview items  */}
        <Stack direction="column" gap={2}>
          <Typography variant="h3">Overview</Typography>

          <Stack gap={2} direction={{ xs: 'column', sm: 'row' }}>
            <BasicInfoCard
              label="Total Bookings"
              value={dashboardData.jobCount}
              icon={{
                value: CalendarIcon,
                color: Color.Green,
                backgroundColor: Color.BackgroundGreen,
              }}
            />
            <Stack direction="column" alignItems="stretch" gap={1} minWidth={200}>
              <PrimaryButton
                onClick={() => navigate(customerRoutes.requestBooking.path)}
                sx={{
                  minHeight: { sm: 100 },
                }}
              >
                New Booking
              </PrimaryButton>
              <SecondaryButton
                onClick={() => navigate(customerRoutes.bookings.path)}
                sx={{
                  minHeight: { sm: 100 },
                }}
              >
                View All
              </SecondaryButton>
            </Stack>
          </Stack>
        </Stack>
        {/** Upcoming bookings */}
        <Stack direction="column" gap={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Upcoming bookings</Typography>
          </Stack>

          <Stack gap={2}>
            {jobs.map((booking) => (
              <BookingListItem key={booking.id} booking={booking} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
