import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const NotFound: React.FC = () => {
  // triggers a message reload between different pages that are not found
  useLocation();

  return (
    <Typography
      variant="h1"
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div>
        <Box sx={{ textAlign: 'center' }}>
          <span role="img" aria-label="shrug">
            🛩
          </span>
        </Box>

        <div>
          <span>Apologies</span>, the page you're looking for could not be found.
        </div>

        <Box sx={{ color: 'rgba(0, 0, 0, 0.16)', display: 'block', textAlign: 'center' }}>404</Box>
      </div>
    </Typography>
  );
};
