import { useEffect, useState } from 'react';
import { Event } from 'react-big-calendar';

import { useAuth } from '@hooks/useAuth';
import { JobPopulated, JobStatus, UserType } from '@lib/firebase';
import { getMyJobs } from '@lib/firestore/Job';

/**
 * Returns hook for fetching current vendor calendar events (react-big-calendar Events).
 * Includes events for pending jobs.
 * Ensures the entire job is added as resource to the event. Needed for potential popover.
 */
export const useVendorEvents = (): { events: VendorEvent[] } => {
  const { currentUser } = useAuth();
  const [events, setEvents] = useState<VendorEvent[]>([]);

  // TODO: Move into its own hook!
  useEffect(() => {
    const getJobs = async () => {
      if (!currentUser?.uid) return;
      const jobs = await getMyJobs(currentUser.uid, UserType.Vendor, {
        status: Object.values(JobStatus),
      });

      const mappedEvents = jobs
        .map((job) => {
          const { scheduled_time, scheduled_end_time, vendor } = job;
          const title = `${vendor?.operating_name} - ${vendor?.first_name} ${vendor?.last_name}`;

          if (scheduled_time && scheduled_end_time) {
            return {
              title,
              start: scheduled_time.toDate(),
              end: scheduled_end_time.toDate(),
              resource: job,
            };
          }

          return null;
        })
        .filter(Boolean) as VendorEvent[];

      setEvents(mappedEvents);
    };
    getJobs();
  }, [currentUser]);

  return { events };
};

/**
 * Returns vendor event type for react-big-calendar.
 */
export type VendorEvent = Event & { resource: JobPopulated };
