import {
  DbCollection,
  DbJob,
  DbJobField,
  DbJobPopulated,
  JobStatus,
  UserType,
} from '@uvac-apps/db-models';
import {
  and,
  collection,
  doc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  Timestamp,
  setDoc,
  where,
} from 'firebase/firestore';
import * as R from 'ramda';

import { firebaseDb } from '@lib/firebase';
import { createDbDocument, getDbDocumentById } from '@lib/firestore';
import { updateCustomerProfile } from '@lib/firestore/Customer';
import { getVehicleById } from '@lib/firestore/Vehicle';
import { getVendorById } from '@lib/firestore/Vendor';

import { ICreateJob, MyJobsOptions } from './types';
import { getInitialInvoice } from './utils/invoice';

/**
 * Returns job by id.
 * @param id - Unique job ID.
 */
export const getJobById = (id: string) => getDbDocumentById<DbJob>(DbCollection.Jobs, id);

/**
 * Returns all jobs for a user.
 * Automatically fetches vendor information for each job.
 */
export const getMyJobs = async (
  uid: string,
  userType: UserType,
  options: MyJobsOptions,
): Promise<DbJobPopulated[]> => {
  const allowedStatus = or(
    ...options.status.map((status) => where(DbJobField.Status, '==', status)),
  );

  const collectionRef = collection(firebaseDb, DbCollection.Jobs);
  const docSnapshots = await getDocs(
    query(
      collectionRef,
      and(where(`${userType.toLowerCase()}_id`, '==', uid), allowedStatus),
      orderBy(DbJobField.ScheduledTime, 'asc'),
      limit(20),
    ),
  );

  return Promise.all(
    docSnapshots.docs.map(async (doc) => {
      const job = doc.data();
      const vendor = await getVendorById(job.vendor_id);

      return {
        ...job,
        id: doc.id,
        vendor,
      } as DbJobPopulated;
    }),
  );
};

/**
 * Returns job count for customer
 * @param userId - Current customer user ID.
 */
export const getCustomerJobCount = async (userId: string): Promise<number> => {
  const allowedStatusForCount = or(
    ...[
      JobStatus.Pending,
      JobStatus.Accepted,
      JobStatus.Completed,
      JobStatus.Confirmed,
      JobStatus.Initiated,
      JobStatus.Paid,
    ].map((status) => where(DbJobField.Status, '==', status)),
  );
  const collectionRef = collection(firebaseDb, DbCollection.Jobs);
  const docSnapshots = await getDocs(
    query(collectionRef, and(where(DbJobField.CustomerId, '==', userId), allowedStatusForCount)),
  );
  return docSnapshots.size;
};

/**
 * Returns job count for vendor
 * @param userId - Current vendor user ID.
 */
export const getVendorJobCount = async (userId: string): Promise<number> => {
  const allowedStatusForCount = or(
    ...[
      JobStatus.Accepted,
      JobStatus.Completed,
      JobStatus.Confirmed,
      JobStatus.Initiated,
      JobStatus.Paid,
    ].map((status) => where(DbJobField.Status, '==', status)),
  );
  const collectionRef = collection(firebaseDb, DbCollection.Jobs);
  const docSnapshots = await getDocs(
    query(collectionRef, and(where(DbJobField.VendorId, '==', userId), allowedStatusForCount)),
  );
  return docSnapshots.size;
};

/**
 * Creates vehicle.
 * @param values - Values to insert.
 * @param customerId - User ID for customer requested job.
 */
export const createJob = async (values: ICreateJob, customerId: string) => {
  const now = new Date();
  const currentTimestamp = Timestamp.fromDate(now);

  const vehicle = await getVehicleById(values.vehicle_id);
  if (!vehicle) {
    throw new Error('Could not find requested vehicle');
  }

  // Get initial invoice numbers to reflect on UI.
  const invoice = await getInitialInvoice(values, vehicle, customerId);

  const job = R.omit(['rateMode'], values);
  const insertValues = {
    ...job,
    customer_id: customerId,
    vendor_id: vehicle.vendor_id,
    selected_categories: values.selected_categories,
    scheduled_time: values.scheduled_time,
    status: JobStatus.Pending,
    notes: [],
    timestamps: {
      created_at: currentTimestamp,
      pending_at: currentTimestamp,
      updated_at: currentTimestamp,
    },
    invoice,
  };

  // When job gets created, no vendor order is assigned yet
  await createDbDocument<Omit<DbJob, DbJobField.VendorOrderId>>(DbCollection.Jobs, insertValues);

  // Update customer profile with preferred vendor (last used vendor)
  await updateCustomerProfile(customerId, { preferred_vendor_id: vehicle.vendor_id });
};

/**
 * Sets vendor order ID for job.
 * @param jobId - Unique job ID.
 * @param vendorOrderId - Unique vendor ID.
 */
export const editJobVendor = async (jobId: string, vendorOrderId: string) => {
  const now = new Date();
  const docRef = doc(firebaseDb, DbCollection.Jobs, jobId);
  const oldTimestamps = (await getJobById(jobId))?.timestamps;
  await setDoc(
    docRef,
    {
      vendor_order_id: vendorOrderId,
      timestamps: { ...oldTimestamps, updated_at: Timestamp.fromDate(now) },
    },
    { merge: true },
  );
};

/**
 * Makes all types available from this module.
 */
export * from './types';
export * from './utils/note';
export * from './utils/status';
export * from './utils/revenue';
export * from './utils/taxes';
