import { DbCollection, DbVendor, UserOnboardingState } from '@uvac-apps/db-models';
import { Timestamp, collection, getDocs, limit, query, where } from 'firebase/firestore';

import { firebaseDb } from '@lib/firebase';
import {
  getDbDocumentById,
  getDbDocuments,
  getDbFieldValueById,
  updateDbDocumentById,
} from '@lib/firestore';

import { BasicVendor } from './types';

/**
 * Returns vendor from DB by id.
 * @param id - Unique vendor ID.
 */
export const getVendorById = (id: string) => getDbDocumentById<DbVendor>(DbCollection.Vendors, id);

/**
 * Returns all available vendors from DB.
 * Only vendors that have finished step 2 of onboarding are returned.
 */
export const getVendors = () =>
  getDbDocuments<DbVendor>(
    DbCollection.Vendors,
    where('onboarding_state', '==', UserOnboardingState.FinishedStep2),
  );

/**
 * Returns list with basic vendor properties.
 * E.g. UID and email for listing.
 */
export const getBasicVendorList = async (): Promise<BasicVendor[]> => {
  const collectionRef = collection(firebaseDb, DbCollection.Vendors);
  const docSnapshots = await getDocs(query(collectionRef, limit(100)));
  return docSnapshots.docs.map((doc) => {
    const data = doc.data();
    return { uid: doc.id, email: data.email } as BasicVendor;
  });
};

/**
 * Returns onboarding state of vendor from DB by id.
 * @param id - Unique vendor ID.
 */
export const getVendorOnboardingState = (id: string) =>
  getDbFieldValueById<DbVendor, UserOnboardingState>(DbCollection.Vendors, id, 'onboarding_state');

/**
 * Updates the vendor profile.
 * @param id - Unique vendor ID.
 * @param values - Values to update.
 */
export const updateVendorProfile = (id: string, values: Partial<DbVendor>) => {
  const now = new Date();

  return updateDbDocumentById(DbCollection.Vendors, id, {
    ...values,
    updated_at: Timestamp.fromDate(now),
  });
};

/**
 * Makes all types available from this module.
 */
export * from './types';
