import { DbCollection } from '@uvac-apps/db-models';
import { Timestamp } from 'firebase/firestore';

import { JobField, InvoiceField } from '@lib/firebase';
import { updateDbDocumentById } from '@lib/firestore';

import { IEditTaxRate } from '../types';

/**
 * Creates vehicle.
 * @param values - Values to insert.
 * @param currentUserId - Current user ID.
 */
export const editTaxRate = async ({ id, taxRate, taxJurisdiction }: IEditTaxRate) => {
  const now = new Date();

  // Due to merge:true in updateDbDocument, this will only update the fields specified.
  const updateValues = {
    [JobField.Invoice]: {
      [InvoiceField.TaxRate]: taxRate / 100,
      [InvoiceField.TaxJurisdiction]: taxJurisdiction,
    },
  };

  return updateDbDocumentById(DbCollection.Jobs, id, {
    ...updateValues,
    updated_at: Timestamp.fromDate(now),
  });
};
