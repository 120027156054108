import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useState } from 'react';

type TextFieldSensitiveProps = {
  /**
   * Whether the text field is sensitive (e.g. password)
   */
  isSensitive?: boolean;
};

/**
 * Returns TextField MUI props for sensitive field incl. component to render & dynamic field type.
 */
export const TextFieldSensitive = ({
  isSensitive,
}: TextFieldSensitiveProps): { type?: string; component: JSX.Element } | undefined => {
  const [showSensitive, setShowSensitive] = useState(!isSensitive);

  if (isSensitive) {
    const handleClickShowSensitive = () => setShowSensitive((show) => !show);

    const handleMouseDownSensitive = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    return {
      type: showSensitive ? 'text' : 'password',
      component: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowSensitive}
            onMouseDown={handleMouseDownSensitive}
            edge="end"
          >
            {showSensitive ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </InputAdornment>
      ),
    };
  }
};
