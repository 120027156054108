import { JobStatus } from '@lib/firebase';
import { Color } from '@theme/palette';

const DEFAULT_STATUS_COLORS = {
  color: Color.Gray1,
  backgroundColor: Color.BackgroundGrey,
};

/**
 * Returns styling for a specific job status
 * @param status - Job status
 */
export const getJobStatusStyling = (status?: JobStatus) => {
  switch (status) {
    case JobStatus.Pending:
      return {
        color: Color.White,
        backgroundColor: Color.BrightYellow,
      };
    case JobStatus.Accepted:
    case JobStatus.Initiated:
    case JobStatus.Completed:
    case JobStatus.Paid:
    case JobStatus.Confirmed:
      return {
        color: Color.White,
        backgroundColor: Color.Green,
      };
    case JobStatus.Rejected:
    case JobStatus.UserCancelled:
    case JobStatus.VendorCancelled:
      return {
        color: Color.White,
        backgroundColor: Color.Red,
        textDecoration: 'line-through',
      };
    default:
      return DEFAULT_STATUS_COLORS;
  }
};
