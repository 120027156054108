import InputAdornment from '@mui/material/InputAdornment';
import { SvgIconProps } from '@mui/material/SvgIcon';

export type TextFieldIconProps = {
  icon?: {
    value: React.FC<SvgIconProps>;
    position?: 'start' | 'end';
  };
};

/**
 * Returns TextField MUI props with optional icon.
 */
export const TextFieldIcon = ({
  icon,
}: TextFieldIconProps):
  | { position: 'startAdornment' | 'endAdornment'; component: JSX.Element }
  | undefined => {
  if (icon?.value) {
    const position = icon.position || 'start';

    return {
      position: position === 'start' ? 'startAdornment' : 'endAdornment',
      component: (
        <InputAdornment position={position}>
          <icon.value sx={{ fontSize: '0.95rem' }} />
        </InputAdornment>
      ),
    };
  }
};
