import { User } from 'firebase/auth';
import { createContext } from 'react';

import { useAuthListener } from '@hooks/useAuth';

export interface IExtendedUser extends User {
  spoofed?: boolean;
}

export interface IAuthContext {
  currentUser?: IExtendedUser;
  setCurrentUser: (user: object) => void;
}

const defaultValues: IAuthContext = {
  currentUser: undefined,
  setCurrentUser: () => undefined,
};

/**
 * Context used for for authentication.
 * See `useAuth` in our hooks for an example of where it is used.
 */
export const AuthContext = createContext(defaultValues);

/**
 * Authentication provider wrapped around our entire React app.
 * Reads the current user from Firebase Auth and provides it to the rest of the app.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthProvider = ({ children }: any) => {
  const { currentUser, setCurrentUser } = useAuthListener();

  const value = {
    currentUser,
    setCurrentUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
