import { Category } from '@lib/firebase';

export interface DisplayCategory {
  label: string;
  value: string;
  options?: DisplayCategory[];
}

/**
 * Maps a list of categories to a list of display categories used in a selectable component.
 * @param categories - List of available DB categories.
 */
export const mapToDisplayCategories = (categories: Category[]): DisplayCategory[] =>
  categories
    .filter((category) => !category.parent)
    .map(({ id, name }) => ({
      label: name,
      value: id,
      options: [
        ...categories
          .filter((category) => category.parent === name)
          .map(({ id, name }) => ({ label: name, value: id })),
      ],
    }));

/**
 * Returns parent category name if it exists, otherwise returns category name.
 * Used to display an autocomplete text field with parent categories as grouping names.
 * @param {object} category - Category object from DB.
 */
export const getParentCategory = (category: Category): string => category.parent ?? category.name;
