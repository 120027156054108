import { useCustomer } from '@hooks/Customer';
import { useEffectFetchData } from '@hooks/useData';
import { VehicleWithDistance } from '@lib/firebase';
import { getAvailableVehicles, getClosestVehicleFromVendor } from '@lib/firestore/Vehicle';

/**
 * Returns hook for returning vehicles that are within 100km of .
 * Sorted by nearest first.
 * @param values - Latitude, longitude, category & list of vendors to filter from.
 * @param radiusInKm - Radius in kilometers.
 */
export const useAvailableVehicles = (
  values: { lat: number; lng: number; categoryId: string; vendorIds?: string[] },
  radiusInKm: number,
) => {
  const currentUser = useCustomer();
  const userId = currentUser.data?.id;

  return useEffectFetchData<VehicleWithDistance[]>(() => {
    if (!userId) {
      return [];
    }

    return getAvailableVehicles(values, radiusInKm, userId);
  }, [userId]);
};

/**
 * Returns hook for returning nearest vehicle from preferred vendor.
 * @param values - Latitude, longitude & vendor ID.
 * @param radiusInKm - Radius in kilometers.
 */
export const useClosestVendorVehicle = (
  values: { lat: number; lng: number; vendorId: string; categoryId: string },
  radiusInKm: number,
) => {
  const currentUser = useCustomer();
  const userId = currentUser.data?.id;

  return useEffectFetchData<VehicleWithDistance | null>(() => {
    if (!userId) {
      return null;
    }

    return getClosestVehicleFromVendor(values, radiusInKm, userId);
  }, [userId]);
};
