import Box from '@mui/material/Box';
import { ReactNode } from 'react';

import { drawerWidth } from '@components/Menu';
import { PageTitle } from '@components/PageTitle';
import { useGetPageTitle } from '@utils/pageTitle';

type MainContentProps = {
  /**
   * Main content to show.
   */
  children: ReactNode;

  /**
   * Background color of main content.
   * @default 'background.default'
   */
  backgroundColor?: string;
};

export const MainContent = ({
  children,
  backgroundColor = 'background.default',
}: MainContentProps) => {
  const { title } = useGetPageTitle();

  return (
    <Box
      component="main"
      // Also compensates for fixed mobile header
      p={{ xs: '75px 20px 15px 20px', sm: '80px 20px 15px 20px', md: '30px 50px 10px 50px' }}
      sx={{
        minWidth: '375px', // Locks minimum width to iPhone 6/7/8 width
        flexGrow: 1,
        bgcolor: backgroundColor,
        minHeight: '100vh',
        width: { xs: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      {/** Shows global page title - do not show on mobile! */}
      <PageTitle name={title} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} />

      {/** Main content */}
      {children}
    </Box>
  );
};
