import { Invoice, RateMode, Vehicle } from '@lib/firebase';
import { getCustomerProfileByCustomerId } from '@lib/firestore/CustomerProfile';
import { getDiscountedPrice, getVehicleBookingPrice } from '@utils/price';
import { getTaxRate } from '@utils/taxes';

import { ICreateJob } from '../types';

/**
 * Returns initial invoice for newly created job.
 * Afterwards it is handled by Cloud Functions.
 * @todo Invoicing & custom rates should be calculated on dedicated API side to avoid tampering.
 * Cloud Function would lead to delay in UI.
 * @param job
 */
export const getInitialInvoice = async (
  values: ICreateJob,
  vehicle: Vehicle,
  customerId: string,
): Promise<Invoice> => {
  // Currently only works for Canada - US gets added manually when accepting a job
  const [taxRate, taxJuristiction] = getTaxRate(values.location.state);

  // Get optional custom rates for customer
  const customerProfile = await getCustomerProfileByCustomerId(vehicle.vendor_id, customerId);

  // Get discounted price for vehicle (if applicable based on customer profile)
  const hasDailyRate = values.rateMode === RateMode.Daily;
  const costs = getVehicleBookingPrice(
    {
      daily_price: vehicle.daily_price,
      hourly_flat_price: vehicle.hourly_flat_price,
      discountRate: customerProfile?.discount_rate || 0,
    },
    hasDailyRate,
  );

  // Daily rate selection will have daily_cost only
  if (hasDailyRate) {
    return {
      rate_mode: RateMode.Daily,
      daily_cost: costs.salePrice, // Use discounted price
      additional_cost: vehicle?.additional_flat_price || 0,
      tax_rate: taxRate,
      tax_jurisdiction: taxJuristiction,
    };
  }

  // Default behaviour is hourly
  return {
    rate_mode: RateMode.Hourly,
    hourly_cost: costs.salePrice, // Use discounted price
    additional_cost: vehicle?.additional_flat_price || 0,
    tax_rate: taxRate,
    tax_jurisdiction: taxJuristiction,
  };
};
