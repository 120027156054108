import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Card } from '@components/Card';
import { ContactUs } from '@components/Contact';

export const PrivacyPolicy = () => {
  return (
    <Card>
      <Stack direction={'column'} gap={2} sx={{ padding: '15' }}>
        <Box>
          <h1>Privacy Policy for Blue Collar Apps</h1>
          <p>Last Updated: July 2023</p>
        </Box>
        <Box>
          <h2>Introduction</h2>
          <p>
            Welcome to Blue Collar Apps ("we," "us," or "Blue Collar Apps"). This Privacy Policy
            governs the collection, use, and disclosure of personal information when you use our
            platform in Canada and the United States of America. By accessing and using Blue Collar
            Apps, you agree to the terms and practices outlined herein.
          </p>
        </Box>
        <Box>
          <h2>Information We Collect</h2>
          <h3>Personal Information</h3>
          <p>
            When you register for an account on Blue Collar Apps, we collect information such as
            your name, email address, phone number, and company details. Buyers may provide
            project-related information for matching with sellers, including job location, project
            details, and specifications. Sellers may provide details of their services, assets,
            qualifications, and pricing. We may also collect payment and billing information for
            processing transactions.
          </p>
          <h3>Usage Information</h3>
          <p>
            We may automatically collect information about how you interact with our platform,
            including your IP address, device type, browser type, operating system, location data,
            and usage patterns.
          </p>
          <h3>Cookies and Similar Technologies</h3>
          <p>
            We use cookies and similar technologies to enhance the user experience, analyze usage
            patterns, and provide targeted advertising. You can control cookie preferences through
            your browser settings.
          </p>
        </Box>
        <Box>
          <h2>How We Use Your Information</h2>
          <h3>Provide and Improve Services</h3>
          <p>
            We use your information to facilitate connections between buyers and sellers of
            excavation services. Your data helps us improve the platform's functionality,
            performance, and user experience.
          </p>
          <h3>Communication</h3>
          <p>
            We may use your contact information to send transactional emails, service updates, and
            marketing communications. You can opt-out of marketing communications at any time.
          </p>
          <h3>Payment Processing</h3>
          <p>
            We may use your payment information to process transactions for services obtained
            through Blue Collar Apps.
          </p>
          <h3>Compliance and Legal Obligations</h3>
          <p>
            We may use your information to comply with legal requirements and respond to law
            enforcement requests.
          </p>
        </Box>
        <Box>
          <h2>Data Security</h2>
          <p>
            We employ industry-standard security measures to protect your personal information from
            unauthorized access, loss, or alteration. Despite our efforts, no data transmission over
            the internet or method of electronic storage is entirely secure, and we cannot guarantee
            absolute security.
          </p>
          Data Retention
          <p>
            We retain your personal information only for as long as necessary to fulfill the
            purposes outlined in this Privacy Policy and to comply with legal obligations.
          </p>
        </Box>
        <Box>
          <h2>Third-Party Services</h2>
          <p>
            We may integrate with third-party services. These services have their own privacy
            policies, and we are not responsible for their practices.
          </p>
        </Box>
        <Box>
          <h2>Children's Privacy</h2>
          <p>
            Blue Collar Apps is not intended for use by individuals under the age of 18. We do not
            knowingly collect personal information from children.
          </p>
        </Box>
        <Box>
          <h2>Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices
            or for legal, regulatory, or operational reasons. We will notify you of any material
            changes through email or prominent notice on our platform.
          </p>
        </Box>
        <Box>
          <h2>Contact Us</h2>
          <p>
            If you have any questions, concerns, or inquiries about this Privacy Policy or our data
            practices, please contact us at <ContactUs />.
          </p>
        </Box>
      </Stack>
    </Card>
  );
};
