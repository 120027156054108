import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Color } from '@theme/palette';

import { Card, CardProps } from '.';

type BasicInfoCardProps = {
  /**
   * Value to show.
   */
  value: string | number;

  /**
   * Label to show.
   */
  label: string;

  /**
   * [Optional] icon to show.
   */
  icon?: {
    value: React.ElementType;
    color: string;
    backgroundColor: string;
  };
};

/**
 * Return card with basic info.
 * eg. Total jobs, Total revenue, etc. with icon & single value.
 */
export const BasicInfoCard = ({
  icon,
  value,
  label,
  ...cardProps
}: BasicInfoCardProps & Omit<CardProps, 'children'>) => (
  <Card sx={{ padding: '30px 45px' }} {...cardProps}>
    <Stack direction="column" gap={1.5} alignItems="center">
      {icon && (
        <icon.value
          sx={{
            display: { xs: 'none', sm: 'flex' }, // Hide on mobile
            fontSize: '3rem',
            color: icon.color,
            backgroundColor: icon.backgroundColor,
            borderRadius: '50%',
            padding: '12px',
          }}
        />
      )}

      <Typography variant="h1" color={Color.Black}>
        {value}
      </Typography>

      <Typography variant="h5" color={Color.Gray1}>
        {label}
      </Typography>
    </Stack>
  </Card>
);
