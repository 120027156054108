import { useFormContext } from 'react-hook-form';

import { getUniqueId } from '@utils/id';

import { TextFieldProps } from '..';

// [Optional] - React-hooks form support

/**
 * Returns props for react-hook-form needed for MUI TextField.
 */
export const getReactHookFormProps = ({
  id = getUniqueId(),
  options,
  setEmptyAsNull,
  ...muiProps
}: TextFieldProps) => {
  const { register } = useFormContext() || {};

  const { ref, ...restOfRegistrationProps } = register(id, {
    ...options,

    // Sets value as null when it's an empty string
    setValueAs: setEmptyAsNull ? (x) => (x === '' ? null : x) : (x) => x,

    // Sets value as number instead of string
    // Bug within react-hook-form causes wrongful boolean type check
    valueAsNumber: (muiProps.type === 'number') as any,
  });

  return {
    inputRef: ref,
    ...restOfRegistrationProps,
  };
};
