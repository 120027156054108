import Stack from '@mui/material/Stack';

import { FormChipSelect } from '@components/Select/ChipSelect';
import { useVendors } from '@hooks/Vendor';
import { CustomerField } from '@lib/firebase';

type SelectCustomerVendorsProps = {
  isLoading?: boolean;
};

export const SelectCustomerVendors = ({ isLoading }: SelectCustomerVendorsProps) => {
  const { data: vendors = [] } = useVendors();

  const mappedVendors = vendors.map(({ id, operating_name, legal_business_name }) => ({
    value: id,
    label: operating_name || legal_business_name,
  }));

  const availableVendors = [
    // Allow "All vendors". This will be excluded when filtering in firestore.
    {
      value: 'all',
      label: ' All Vendors',
    },
    ...(mappedVendors ?? []),
  ];

  return (
    <Stack direction="column" gap={1}>
      <Stack direction="column" gap={2}>
        <FormChipSelect
          id={CustomerField.VendorIds}
          label="Available operators"
          placeholder="Select one or more operator(s)"
          type="text"
          options={availableVendors}
          isLoading={isLoading}
        />
      </Stack>
    </Stack>
  );
};
