import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Card } from '@components/Card';
import { Form } from '@components/Form';
import { PrimaryButton } from '@components/PrimaryButton';
import { SecondaryButton } from '@components/SecondaryButton';
import { useCustomer } from '@hooks/Customer';
import { useVehicleSelection } from '@hooks/Customer/useVendorSelection';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { useSnackbar } from '@hooks/useSnackbar';
import { firebaseAuth, CustomerField } from '@lib/firebase';
import { customerRoutes } from '@routes/customer';

import { SelectCustomerVendors } from '../components/SelectVendors';
import { IVendorsSelectionForm } from '../fields';

export const Profile = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    currentUser: { name, email, profilePictureURL },
  } = useCurrentUser();

  // Load customer from firestore if already exists
  const { data: customer, isLoading } = useCustomer();
  const { submit: submitVehicleSelection } = useVehicleSelection();

  const selectVendorsForm = useForm<IVendorsSelectionForm>({
    mode: 'onSubmit',
    defaultValues: { [CustomerField.VendorIds]: [] },
  });

  // Use isDirty to determine if form has changed
  const { isDirty: hasFormChanged } = selectVendorsForm.formState;

  /**
   * Performs logout on Firebase Auth and redirects to login page.
   */
  const handleLogout = async () => {
    await firebaseAuth.signOut();
    navigate(customerRoutes.login.path);
  };

  const updateVehicleSelection = async (values: IVendorsSelectionForm) => {
    if (values.locked_vendor_ids.length === 0) {
      return enqueueSnackbar('Please select at least 1 operator', { variant: 'error' });
    }

    // Check whether preferred vendor was unselected.
    if (
      customer?.preferred_vendor_id &&
      !values.locked_vendor_ids.includes(customer?.preferred_vendor_id)
    ) {
      return enqueueSnackbar(
        'Your last used operator cannot be removed. Make a new booking using a different vendor first.',
        {
          variant: 'error',
        },
      );
    }

    // Sends API request to store changes
    await submitVehicleSelection(values, {
      onCompleted: () => {
        enqueueSnackbar('Succesfully saved changes', { variant: 'success' });

        // Reset form values (so dirty flag is reset)
        selectVendorsForm.reset(values);
      },
      onError: (error: Error) => {
        enqueueSnackbar(`An error occurred trying to save your changes: ${error.message}`, {
          variant: 'error',
        });
      },
    });
  };

  // Ensure form values are set when customer info gets loaded
  useEffect(() => {
    selectVendorsForm.reset(customer);
  }, [customer]);

  return (
    <Box>
      <Stack direction="column" gap={2}>
        <Card>
          <Stack direction="row" gap={2}>
            {profilePictureURL && (
              <Box
                component="img"
                src={profilePictureURL}
                alt="Profile "
                sx={{ borderRadius: '50%' }}
              />
            )}
            <Stack direction="column" gap={0.5}>
              <Typography variant="h5">{name}</Typography>
              <Box component="span">{email}</Box>
              <Box component="span">
                <a href={customerRoutes.termsOfService.getPath()}>Terms of Service</a>
              </Box>
              <Box component="span">
                <a href={customerRoutes.privacyPolicy.getPath()}>Privacy Policy</a>
              </Box>
            </Stack>
          </Stack>
        </Card>

        <Card>
          <FormProvider {...selectVendorsForm}>
            <Form onSubmit={selectVendorsForm.handleSubmit(updateVehicleSelection)}>
              <Stack direction="column" gap={2}>
                <SelectCustomerVendors isLoading={isLoading} />

                {hasFormChanged && (
                  <Box>
                    <PrimaryButton type="submit">Save changes</PrimaryButton>
                  </Box>
                )}
              </Stack>
            </Form>
          </FormProvider>
        </Card>

        <Box>
          <SecondaryButton onClick={handleLogout}>Logout</SecondaryButton>
        </Box>
      </Stack>
    </Box>
  );
};
