import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '@hooks/useAuth';
import { JobPopulated, JobStatus, UserType } from '@lib/firebase';
import { getMyJobs } from '@lib/firestore/Job';

import { BookingFilters } from '../components/BookingFilters';

export const BookingOverview = ({
  BookingItem,
  userType,
}: {
  BookingItem: FC<{ booking: JobPopulated }>;
  userType: UserType;
}) => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<JobStatus>(
    (searchParams.get('status') as JobStatus) || JobStatus.Pending,
  );
  const [jobs, setJobs] = useState<JobPopulated[]>([]);
  const { currentUser } = useAuth();

  /**
   * @todo - Move into its own hook!
   */
  useEffect(() => {
    const getJobs = async () => {
      if (!currentUser?.uid) return;
      const newJobs = await getMyJobs(currentUser.uid, userType, { status: [status] });
      setJobs(newJobs);
    };
    getJobs();
  }, [status, currentUser]);

  return (
    <Box>
      <BookingFilters onStatusUpdate={(newStatus) => setStatus(newStatus)} />
      <Stack gap={2}>
        {jobs.map((job) => (
          <BookingItem key={job.id} booking={job} />
        ))}
      </Stack>
    </Box>
  );
};
