import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ContactUs } from '@components/Contact';
import { ConfirmationModal } from '@components/Modal/Confirm';
import { SecondaryButton } from '@components/SecondaryButton';
import { useChangeJobStatus } from '@hooks/Job/useChangeJobStatus';
import { JobStatus } from '@lib/firebase';
import { customerRoutes } from '@routes/customer';

export type BookingActionsProps = {
  id: string;
  status: JobStatus;
  scheduledTime: Timestamp;
};

/** Returns booking actions eg. Accept, Reject, Start, Complete, Cancel */
export const BookingActions = ({ id, status, scheduledTime }: BookingActionsProps) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [modalContent, setModalContent] = useState<{
    title: string;
    description: React.ReactElement;
  }>({
    title: '',
    description: <></>,
  });
  const { submit: changeJobStatus, isLoading: isSubmitting } = useChangeJobStatus();
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Returns whether scheduled time is within 24 hours.
   */
  const isWithinTwentyFourHours = () => {
    const currentTimestamp = Timestamp.now();
    const remainingHours = (scheduledTime.seconds - currentTimestamp.seconds) / 60 / 60;

    return remainingHours <= 24;
  };

  /**
   * Updates status of booking
   */
  const changeStatus = (newStatus: JobStatus) => async () => {
    await changeJobStatus({ id, status: newStatus });
    const targetBookingPath = customerRoutes.bookingDetail.getPath(id);
    const currentPath = location?.pathname;

    if (currentPath !== targetBookingPath) {
      return navigate(customerRoutes.bookingDetail.getPath(id));
    }

    navigate(0);
  };

  /**
   * Modal closing/cancellation.
   */
  const onCancellationCancel = async () => {
    setConfirmOpen(false);
  };

  const handleChangeClick = (newStatus: JobStatus) => async () => {
    // Confirmation required for cancelling job
    if (newStatus === JobStatus.UserCancelled) {
      // Set dynamic modal description
      isWithinTwentyFourHours()
        ? setModalContent({
            title:
              "It looks like you're trying to cancel an order that starts in less than 24 hours",
            description: (
              <Box>
                Please note that cancelling this order will forfeit the deposit. Select "Accept" to
                confirm or <ContactUs label="contact us" /> if you have any questions.
              </Box>
            ),
          })
        : setModalContent({
            title: "It looks like you're trying to cancel an order that's already confirmed.",
            description: (
              <Box>
                Select "Accept" if you're sure and we'll initiate return of your deposit less any
                processing fees. Please <ContactUs label="contact us" /> if you have any questions.
              </Box>
            ),
          });

      return setConfirmOpen(true);
    }
  };

  return (
    <Stack direction="row" gap={1}>
      {/* Actions when Pending */}
      {status === JobStatus.Pending && (
        <>
          <SecondaryButton
            onClick={handleChangeClick(JobStatus.UserCancelled)}
            sx={{ width: '150px' }}
            loading={isSubmitting}
          >
            Cancel
          </SecondaryButton>
        </>
      )}

      {/* Actions when Accepted */}
      {status === JobStatus.Accepted && (
        <>
          <SecondaryButton
            onClick={handleChangeClick(JobStatus.UserCancelled)}
            sx={{ width: '150px' }}
            loading={isSubmitting}
          >
            Cancel
          </SecondaryButton>
        </>
      )}

      {/* Should be different per user status! For now this works, but refactor to pass along dynamic status */}
      <ConfirmationModal
        open={confirmOpen}
        onConfirm={changeStatus(JobStatus.UserCancelled)}
        onCancel={onCancellationCancel}
        confirmButtonText="Accept"
        title={modalContent.title}
        description={modalContent.description}
      />
    </Stack>
  );
};
