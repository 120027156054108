import { Box, Stack } from '@mui/material';

import { Color } from '@theme/palette';

/**
 * Returns booking field showing extra info eg. Date & Time, Customer,...
 * @todo - Move into its own component if needed outside of this page
 */
export const BookingField = ({
  label,
  value,
}: {
  label: string;
  value: string | React.ReactElement;
}) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    flexWrap="wrap"
    sx={{
      padding: '4px 10px',
      backgroundColor: Color.BackgroundColor,
      borderRadius: '10px',
    }}
  >
    <Box component="span" sx={{ fontWeight: 400 }}>
      {label}
    </Box>
    <Box component="span" sx={{ fontWeight: 600 }}>
      {value}
    </Box>
  </Stack>
);
