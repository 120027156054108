import { useSubmitData } from '@hooks/useData';
import { IEditTaxRate, editTaxRate } from '@lib/firestore/Job';

/**
 * Returns hook for editing a job's tax rate.
 * Can only be performed by vendor.
 */
export const useEditTaxRate = () =>
  useSubmitData(async (values: IEditTaxRate) => {
    await editTaxRate(values);
  });
