import React, { useEffect } from 'react';

import { useSnackbar } from '@hooks/useSnackbar';

type FormProps = {
  children: React.ReactChild | React.ReactNode;
  id?: string;
  onSubmit?(): void;
  error?: Error;
  className?: string;

  fullWidth?: boolean;
};

export const Form: React.FC<FormProps> = ({ children, className, onSubmit, error, id }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [error]);

  return (
    <form id={id} className={className} onSubmit={onSubmit} style={{ width: '100%' }}>
      {children}
    </form>
  );
};
