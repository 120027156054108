import Stack from '@mui/material/Stack';

import { FormDateTimePicker } from '@components/DateTimePicker';
import { JobField } from '@lib/firebase';

export const BookingDateTime = () => (
  <Stack direction="row" gap={2} flexWrap="wrap">
    <FormDateTimePicker
      id={JobField.ScheduledTime}
      label="Start Service date"
      required
      sx={{
        width: { xs: '100%', md: '48%' },
      }}
    />
    <FormDateTimePicker
      id={JobField.ScheduledEndTime}
      label="End Service date"
      required
      sx={{
        width: { xs: '100%', md: '48%' },
      }}
    />
  </Stack>
);
