import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { Card } from '@components/Card';
import { SecondaryButton } from '@components/SecondaryButton';
import { JobPopulated, JobStatus, RateMode } from '@lib/firebase';
import { BookingField } from '@pages/Booking/components/BookingField';
import { BookingStatus } from '@pages/Booking/components/BookingStatus';
import { BookingVendorId } from '@pages/Booking/components/BookingVendorId';
import { customerRoutes } from '@routes/customer';
import { Color } from '@theme/palette';

import { BookingActions } from './BookingActions';

export interface BookingListItemProps {
  booking: JobPopulated;
}

/**
 * Returns Booking list item
 */
export const BookingListItem = ({ booking }: BookingListItemProps) => {
  const navigate = useNavigate();
  const { vendor } = booking;
  const name = `${vendor?.operating_name} - ${vendor?.first_name} ${vendor?.last_name}`;

  const { address1, address2, city, state, country, postal_code } = booking.location;
  const address = address2 ? `${address2}-${address1}` : address1;
  const location = [address, city, state, country, postal_code].join(', ');

  return (
    <Card>
      <Stack gap={2}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={2}
          alignItems={{ xs: 'start', sm: 'center' }}
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
            {booking.status !== JobStatus.Pending && (
              <BookingVendorId id={booking.id} vendor_order_id={booking.vendor_order_id} />
            )}
            <Typography variant="h4">{name}</Typography>
          </Stack>
          <BookingStatus status={booking.status} />
        </Stack>

        {/* Hourly Rate */}
        <Stack direction="column" gap={1} alignItems="flex-start">
          <Box component="span" sx={{ fontWeight: 600, color: Color.LightBlack }}>
            {/** Hourly rate selected */}
            {booking.invoice.rate_mode === RateMode.Hourly && (
              <> ${booking.invoice?.hourly_cost}/hr </>
            )}

            {/** Daily rate selected */}
            {booking.invoice.rate_mode === RateMode.Daily && (
              <> ${booking.invoice?.daily_cost}/day </>
            )}

            {/* Additional cost - Chris asked to be hidden for now! */}
            {/*   {booking.invoice?.additional_cost > 0 && (
              <> + ${booking.invoice?.additional_cost.toFixed(2)}</>
            )} */}
          </Box>
          <Box component="span">{booking.notes[booking.notes.length - 1]?.text}</Box>
        </Stack>

        {/* Booking details */}
        <Stack direction="column" spacing={1} alignItems="stretch" sx={{ marginBottom: 1 }}>
          <BookingField
            label="Rate choice"
            value={booking.invoice?.rate_mode === RateMode.Hourly ? 'Hourly' : 'Daily'}
          />

          <BookingField
            label="Scheduled Time"
            value={format(booking.scheduled_time.toDate(), 'dd MMM, yyyy - hh:mm aaa')}
          />

          {booking.scheduled_end_time && (
            <BookingField
              label="Scheduled End Time"
              value={format(booking.scheduled_end_time.toDate(), 'dd MMM, yyyy - hh:mm aaa')}
            />
          )}

          <BookingField label="Customer" value={name} />

          {/* Only shown after accepting */}
          {booking.status == JobStatus.Pending && location && (
            <BookingField label="Address" value={location} />
          )}
        </Stack>
        <BookingActions
          id={booking.id}
          status={booking.status}
          taxProvince={booking.invoice?.tax_jurisdiction}
        />
        <SecondaryButton onClick={() => navigate(customerRoutes.bookingDetail.getPath(booking.id))}>
          View details
        </SecondaryButton>
      </Stack>
    </Card>
  );
};
