import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { SelectProps } from '@mui/material/Select';

import { withController } from '@utils/form';

import { Select, UncontrolledSelectProps } from './';

/**
 * Returns enhanced MUI select Chip component for multi-selection.
 */
export const ChipSelect = (props: UncontrolledSelectProps) => (
  <Select
    {...props}
    multiple
    renderValue={(selected) => {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {(selected as any).map((selectedValue: string) => {
            const label = props.options?.find((option) => option.value === selectedValue)?.label;

            return <Chip key={selectedValue} label={label} />;
          })}
        </Box>
      );
    }}
  />
);

/**
 * Returns a Chip Select component with a react-hook-form controller wrapped around.
 * This is the recommended way to use react-hook-form with MUI components.
 */
export const FormChipSelect = withController<
  Omit<SelectProps<string | number>, 'variant'> & UncontrolledSelectProps
>(ChipSelect);
