import { DbCollection, DbJobField, JobStatus } from '@uvac-apps/db-models';
import { collection, getDocs, query, or, where, and } from 'firebase/firestore';

import { firebaseDb } from '@lib/firebase';

/**
 * Returns total revenue for a vendor.
 * @param vendorId - Currently logged in vendor ID.
 */
export const getVendorTotalRevenue = async (vendorId: string): Promise<number> => {
  const allowedStatusForCount = or(
    ...[JobStatus.Completed, JobStatus.Paid].map((status) =>
      where(DbJobField.Status, '==', status),
    ),
  );
  const collectionRef = collection(firebaseDb, DbCollection.Jobs);
  const docSnapshots = await getDocs(
    query(collectionRef, and(where(DbJobField.VendorId, '==', vendorId), allowedStatusForCount)),
  );
  return docSnapshots.docs.reduce((acc, doc) => {
    const {
      invoice: { total },
    } = doc.data();
    return acc + total;
  }, 0);
};
