import {
  DbCollection,
  DbCustomer,
  DbCustomerProfile,
  DbCustomerProfileField,
} from '@uvac-apps/db-models';
import { Timestamp, where } from 'firebase/firestore';

import {
  createDbDocument,
  getDbDocumentById,
  getDbDocuments,
  removeDbDocumentById,
  updateDbDocumentById,
} from '@lib/firestore';

import { ICreateCustomerProfile, IExtendedCustomerProfile } from './types';

/**
 * Returns custom pricing with customer details.
 */
const addCustomerDetails = async (price: DbCustomerProfile): Promise<IExtendedCustomerProfile> => {
  const customer = await getDbDocumentById<DbCustomer>(DbCollection.Customers, price.customer_id);
  return {
    ...price,
    customer: {
      uid: price.customer_id,
      email: customer?.email ?? 'No email',
      operatingName: customer?.operating_name ?? 'No operating name',
      legalBusinessName: customer?.legal_business_name ?? 'No legal business name',
    },
  };
};

/**
 * Returns custom profule by id.
 * @param id - Unique custom profile ID.
 */
export const getCustomerProfileById = async (
  id: string,
): Promise<IExtendedCustomerProfile | null> => {
  const price = await getDbDocumentById<DbCustomerProfile>(DbCollection.CustomerProfiles, id);

  if (price) {
    return addCustomerDetails(price);
  }

  return null;
};

/**
 * Returns all customer profiles from DB for specific vendor.
 * @param vendorId - Unique vendor ID.
 */
export const getCustomerProfileList = async (
  vendorId: string,
): Promise<IExtendedCustomerProfile[]> => {
  const pricing = await getDbDocuments<DbCustomerProfile>(
    DbCollection.CustomerProfiles,
    where(DbCustomerProfileField.VendorId, '==', vendorId),
  );

  // TODO: Once we move to a production-ready DB, this should be a join (or populate) query.
  return Promise.all(pricing.map(addCustomerDetails));
};

/**
 * Returns customer profile by vendor & customer ID.
 * Only one profile should exist for each customer.
 * @param vendorId - Unique vendor ID.
 * @param customerId - Unique customer ID.
 */
export const getCustomerProfileByCustomerId = async (
  vendorId: string,
  customerId: string,
): Promise<DbCustomerProfile | null> => {
  const profiles = await getDbDocuments<DbCustomerProfile>(
    DbCollection.CustomerProfiles,
    where(DbCustomerProfileField.VendorId, '==', vendorId),
    where(DbCustomerProfileField.CustomerId, '==', customerId),
  );

  return profiles[0];
};

/**
 * Creates customer profile.
 * @param values - Values to insert.
 */
export const createCustomerProfile = (values: ICreateCustomerProfile) => {
  const now = new Date();

  return createDbDocument(DbCollection.CustomerProfiles, {
    ...values,
    created_at: Timestamp.fromDate(now),
    updated_at: Timestamp.fromDate(now),
  });
};

/**
 * Updates customer profile from DB by id.
 * @param id - Unique vendor ID.
 * @param values - Values to update.
 */
export const updateCustomerProfile = (id: string, values: Partial<DbCustomerProfile>) => {
  const now = new Date();

  return updateDbDocumentById(DbCollection.CustomerProfiles, id, {
    ...values,
    updated_at: Timestamp.fromDate(now),
  });
};

/**
 * Removes customer profile from DB by id.
 * @param id - Unique pricing ID.
 */
export const removeCustomerProfile = (id: string) =>
  removeDbDocumentById(DbCollection.CustomerProfiles, id);

/**
 * Makes all types available from this module.
 */
export * from './types';
