import { useAuth } from '@hooks/useAuth';
import { useEffectFetchData, useSubmitData } from '@hooks/useData';
import { useVendor } from '@hooks/Vendor';
import { CustomerProfileField } from '@lib/firebase';
import {
  ICreateCustomerProfile,
  IEditCustomerProfile,
  IExtendedCustomerProfile,
  createCustomerProfile,
  getCustomerProfileByCustomerId,
  getCustomerProfileById,
  getCustomerProfileList,
  removeCustomerProfile,
  updateCustomerProfile,
} from '@lib/firestore/CustomerProfile';

/**
 * Returns hook for fetching custom profiles
 * from Firestore for the current vendor.
 */
export const useCustomerProfileList = () => {
  const currentVendor = useVendor();
  const vendorId = currentVendor.data?.id;

  return useEffectFetchData<IExtendedCustomerProfile[]>(() => {
    return vendorId ? getCustomerProfileList(vendorId) : [];
  }, [vendorId]);
};

/**
 * Returns hook for fetching customer profile by id.
 * @param id - Unique profile ID.
 */
export const useCustomerProfile = (id: string) => {
  return useEffectFetchData<IExtendedCustomerProfile | null>(() => getCustomerProfileById(id));
};

/**
 * Returns hook for fetching customer profile by customer ID.
 * Uses currently logged in Vendor ID.
 * @todo - Jeeeez Brent, clean this up. Should be a clean lazy-load.
 * @param id - Customer ID.
 */
export const useUniqueCustomerProfile = () => {
  const currentVendor = useVendor();
  const vendorId = currentVendor.data?.id;

  // Vendor ID gets automatically set to current user. No need to pass it in.
  return useSubmitData(async (customerId: string) =>
    vendorId ? getCustomerProfileByCustomerId(vendorId, customerId) : null,
  );
};

/**
 * Returns hook for creating custom profile for customer.
 */
export const useCreateCustomerProfile = () => {
  const { currentUser } = useAuth();

  // Vendor ID gets automatically set to current user. No need to pass it in.
  return useSubmitData(
    async (values: Omit<ICreateCustomerProfile, CustomerProfileField.VendorId>) => {
      if (!currentUser?.uid) {
        return;
      }

      await createCustomerProfile({ ...values, vendor_id: currentUser.uid });
    },
  );
};

/**
 * Returns hook for editing custom profile by id.
 */
export const useEditCustomerProfile = () => {
  const { currentUser } = useAuth();

  return useSubmitData(async ({ id, ...values }: IEditCustomerProfile) => {
    if (!currentUser?.uid) {
      return;
    }

    await updateCustomerProfile(id, values);
  });
};

/**
 * Returns hook for removing custom profile by id.
 */
export const useRemoveCustomerPricing = () => {
  const { currentUser } = useAuth();

  return useSubmitData(async ({ id }: { id: string }) => {
    if (!currentUser?.uid) {
      return;
    }

    await removeCustomerProfile(id);
  });
};
