import React from 'react';
import { Outlet } from 'react-router-dom';

import { ErrorBoundary } from '@components/ErrorBoundary';

/**
 * The overall application layout used across all pages.
 */
export const Layout = () => {
  return (
    <div>
      <ErrorBoundary>
        {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
        <Outlet />
      </ErrorBoundary>
    </div>
  );
};
