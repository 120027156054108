import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Card } from '@components/Card';
import { Loader } from '@components/Loader';
import { Logo } from '@components/Logo';
import { MainContent } from '@components/MainContent';
import { OnboardingCompany, OnboardingCompanyAddress } from '@components/Onboarding/Company';
import { IPersonalOnboardingForm, IInvoicingOnboardingForm } from '@components/Onboarding/fields';
import {
  OnboardingInvoicingAddress,
  OnboardingInvoicingContactInfo,
  OnboardingInvoicingIndiginous,
} from '@components/Onboarding/Invoicing';
import { OnboardingPersonal } from '@components/Onboarding/Personal';
import { Stepper } from '@components/Stepper';
import { useCustomer } from '@hooks/Customer';
import { useOnboardInvoicing, useOnboardPersonal } from '@hooks/Customer/useOnboarding';
import { useVehicleSelection } from '@hooks/Customer/useVendorSelection';
import { useAuth } from '@hooks/useAuth';
import { CustomerField, UserOnboardingState } from '@lib/firebase';
import { customerRoutes } from '@routes/customer';
import { Color } from '@theme/palette';

import { SelectCustomerVendors } from '../components/SelectVendors';
import { IVendorsSelectionForm } from '../fields';

// Render first step on load
// Allows easier testing of each step without going through entire flow
const ACTIVE_STEP = 0;

/**
 * Returns onboarding page
 */
export const Onboarding = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  // Define typed forms
  const personalForm = useForm<IPersonalOnboardingForm>({ mode: 'onSubmit' });
  const invoicingForm = useForm<IInvoicingOnboardingForm>({ mode: 'onSubmit' });
  const selectVendorsForm = useForm<IVendorsSelectionForm>({
    mode: 'onSubmit',
    defaultValues: { [CustomerField.VendorIds]: [] },
  });

  // Hooks for submitting information
  const { submit: submitPersonalInfo } = useOnboardPersonal();
  const { submit: submitInvoicingInfo } = useOnboardInvoicing();
  const { submit: submitVehicleSelection } = useVehicleSelection();

  // Load customer from firestore if already exists
  const { data: customer, isLoading } = useCustomer();

  // If already completed certain steps
  const hasCompletedStep1 = customer?.onboarding_state === UserOnboardingState.FinishedStep1;
  const hasCompletedStep2 = customer?.onboarding_state === UserOnboardingState.FinishedStep2;
  const hasCompletedStep3 = customer?.onboarding_state === UserOnboardingState.FinishedStep3;

  // Skips first step when already completed step 1 or 2 in the past.
  // No need for useState as we're awaiting for vendor to load
  const getActiveStep = () => {
    if (hasCompletedStep1) {
      return 1;
    }

    if (hasCompletedStep2) {
      return 2;
    }

    return ACTIVE_STEP;
  };

  const activeStep = getActiveStep();

  // If already completed step 1 or 2, prefill personal form (step 1)
  useEffect(() => {
    if (hasCompletedStep1 || hasCompletedStep2) {
      personalForm.reset(customer);
    }
  }, [customer]);

  // If already onboarded step 3 on loading page, skip to home
  if (hasCompletedStep3) {
    navigate(customerRoutes.home.path);
  }

  if (!currentUser) {
    navigate(customerRoutes.login.path);
  }

  // After all steps are completed, navigate to home
  const onFinish = () => {
    navigate(customerRoutes.home.path);
  };

  /**
   * Steps definition for onboarding.
   * Includes which components to render when.
   */
  const steps = [
    {
      label: 'Personal',
      form: {
        useForm: personalForm,
        onSubmit: submitPersonalInfo,
      },
      component: (
        <Stack direction="column" gap={3} sx={{ marginTop: 1 }}>
          <OnboardingPersonal />
          <OnboardingCompany />
          <OnboardingCompanyAddress showAddressAutocomplete={!hasCompletedStep1} />
        </Stack>
      ),
    },
    {
      label: 'Invoicing',
      form: {
        useForm: invoicingForm,
        onSubmit: submitInvoicingInfo,
      },
      component: (
        <Stack direction="column" gap={3} sx={{ marginTop: 1 }}>
          <OnboardingInvoicingAddress />
          <OnboardingInvoicingContactInfo />
          <OnboardingInvoicingIndiginous />
        </Stack>
      ),
    },
    {
      label: 'Operator(s)',
      form: {
        useForm: selectVendorsForm,
        onSubmit: submitVehicleSelection,
      },
      component: (
        <Stack direction="column" gap={3} sx={{ marginTop: 1 }}>
          <SelectCustomerVendors />
        </Stack>
      ),
    },
  ];

  return (
    <MainContent backgroundColor={Color.Gray4}>
      <Card sx={{ maxWidth: 1000, margin: '0 auto' }}>
        <Stack gap={2} alignItems="center">
          <Logo />
          <Typography variant="h3">Tell us about you</Typography>

          {isLoading ? (
            <Loader />
          ) : (
            <Stepper activeStep={activeStep} steps={steps} onFinish={onFinish} />
          )}
        </Stack>
      </Card>
    </MainContent>
  );
};
