import { getStatesOfCountry } from 'country-state-city/lib/state';

import { USE_MOCKED_ADDRESSES } from '@config/address';
import { Address, AddressCoordinateField, AddressCoordinates, AddressField } from '@lib/firebase';
import { getCoordinatesFromAddress } from '@lib/googleApi';

/**
 * Returns a formatted address string based on address object.
 * Best results using Google API and easiest to display.
 * @param address - Address object containing all information.
 */
export const getFormattedAddressString = (address: Omit<Address, 'lat' | 'lng' | 'geohash'>) =>
  `${address.address1}, ${address.city}, ${address.state}, ${address.postal_code}, ${address.country}`;

/**
 * Returns a list of states (or provinces) of a country
 * Uses label/value so it can be used in a select input component.
 * @param {string} countryCode - ISO Country code.
 */
export const getStates = (countryCode: string) => {
  const states = getStatesOfCountry(countryCode);

  return states.map(({ isoCode, name }) => ({
    value: isoCode,
    label: name,
  }));
};

/**
 * Returns address with coordinates based on basic address.
 * @param values - Basic address eg. street name, city,...
 */
export const getAddressWithCoordinates = async (
  values: Omit<Address, keyof AddressCoordinates>,
): Promise<Address | null> => {
  const coordinates = await getCoordinatesFromAddress(values, USE_MOCKED_ADDRESSES);

  // If no coordinates are found, the address is meaningless for us to query anything and should be treated as an error
  if (!coordinates) {
    return null;
  }

  // TODO: Not 100% sure why we'd wanna use empty strings rather than error out but TBD
  return {
    [AddressField.address1]: values.address1 || '',
    [AddressField.address2]: values.address2 || '',
    [AddressField.city]: values.city || '',
    [AddressField.state]: values.state || '',
    [AddressField.postalCode]: values.postal_code || '',
    [AddressField.country]: values.country || '',
    [AddressCoordinateField.longitude]: coordinates.lng,
    [AddressCoordinateField.latitude]: coordinates.lat,
    [AddressCoordinateField.geoHash]: coordinates.geohash,
  };
};
