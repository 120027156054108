import { DbCategory, DbCollection } from '@uvac-apps/db-models';

import { getDbDocumentById, getDbDocuments } from '@lib/firestore';

/**
 *
/**
 * Returns category from DB by id.
 * @param id - Unique category ID.
 */
const getCategory = (id: string) => getDbDocumentById<DbCategory>(DbCollection.Categories, id);

/**
 * Returns all categories from DB.
 * Auto-populates parent categories by their name
 */
export const getCategories = async () => {
  // Need to manually cast parent due to improper firestore types
  const categories = await getDbDocuments<DbCategory & { parent?: { id: string } }>(
    DbCollection.Categories,
  );

  return Promise.all(
    categories.map(async ({ id, name, parent }) => {
      const parentCategory = parent ? await getCategory(parent.id) : null;
      const parentName = parentCategory?.name || null;

      return { id, name, parent: parentName };
    }),
  );
};
