import { IAddressForm } from '@components/Address/fields';
import { AddressCoordinates, Category, JobField, PaymentTerm } from '@lib/firebase';

// Location
export type ILocationForm = IAddressForm & {
  [JobField.ScheduledTime]: Date;
  [JobField.ScheduledEndTime]: Date;
};

export type ILocationCoordinates = AddressCoordinates;

// Service(s)
export interface IServiceForm {
  [JobField.SelectedCategories]: Category[];
  [JobField.PaymentTerms]: PaymentTerm;
  [JobField.IsUnionized]: boolean;
  [JobField.Notes]: string;
  [JobField.DumpOnsite]: boolean;
  [JobField.WaterOnSite]: boolean;
  [JobField.RemoteHose]: boolean;
  [JobField.OtherConditions]?: string;
}

// Selected Hydrovac
export const SHOW_HOURLY_RATE_FORM_FIELD = 'showHourlyRate';
export interface IVehicleForm {
  [JobField.VehicleId]: string;
  [SHOW_HOURLY_RATE_FORM_FIELD]: boolean;
}
