import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { Card } from '@components/Card';
import { SecondaryButton } from '@components/SecondaryButton';
import { useCurrentUser } from '@hooks/useCurrentUser';
import { firebaseAuth } from '@lib/firebase';
import { vendorRoutes } from '@routes/vendor';

export const Profile = () => {
  const navigate = useNavigate();
  const {
    currentUser: { name, email, profilePictureURL },
  } = useCurrentUser();

  /**
   * Performs logout on Firebase Auth and redirects to login page.
   */
  const handleLogout = async () => {
    await firebaseAuth.signOut();
    navigate(vendorRoutes.login.path);
  };

  return (
    <Box>
      <Stack direction="column" gap={2}>
        <Card>
          <Stack direction="row" gap={2}>
            {profilePictureURL && (
              <Box
                component="img"
                src={profilePictureURL}
                alt="Profile "
                sx={{ borderRadius: '50%' }}
              />
            )}
            <Stack direction="column" gap={0.5}>
              <Typography variant="h5">{name}</Typography>
              <Box component="span">{email}</Box>
              <Box component="span">
                <a href={vendorRoutes.termsOfService.getPath()}>Terms of Service</a>
              </Box>
              <Box component="span">
                <a href={vendorRoutes.privacyPolicy.getPath()}>Privacy Policy</a>
              </Box>
            </Stack>
          </Stack>
        </Card>

        <Box>
          <SecondaryButton onClick={handleLogout}>Logout</SecondaryButton>
        </Box>
      </Stack>
    </Box>
  );
};
