import { useState, useEffect } from 'react';

import { useAuth } from '@hooks/useAuth';
import { JobPopulated, UserType } from '@lib/firebase';
import { getMyJobs, MyJobsOptions } from '@lib/firestore/Job';

export interface IMyJobsHook {
  jobs: JobPopulated[];
  loading: boolean;
  error: unknown;
}

export const useMyJobs = (userType: UserType, options: MyJobsOptions): IMyJobsHook => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);
  const [jobs, setJobs] = useState<JobPopulated[]>([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!currentUser?.uid) {
        return { jobs, loading, error };
      }

      try {
        const jobs = await getMyJobs(currentUser.uid, userType, options);
        setJobs(jobs);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
        console.error(error);
      }
    };
    fetchData();
  }, [currentUser]);
  return { jobs, loading, error };
};
