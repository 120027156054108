import PercentIcon from '@mui/icons-material/Percent';
import Stack from '@mui/material/Stack';
import { CustomerAutocomplete } from '@Vendor/components/CustomerAutocomplete';

import { FormTextField } from '@components/TextField';
import { CustomerProfileField } from '@lib/firebase';

import { CreateCustomPricingField } from '../Create';
import { EditCustomPricingField } from '../Edit';

type BasicPricingProps = {
  /**
   * Indicates whether data is loading
   */
  isLoading?: boolean;

  /**
   * Indicates whether the customer field is disabled.
   */
  isCustomerDisabled?: boolean;
};

/**
 * Create mode allows auto-complete for customers
 * Edit mode shows disabled text field to avoid weird side effects when changing customers
 */
export const BasicPricing = ({ isLoading, isCustomerDisabled }: BasicPricingProps) => (
  <Stack direction="column" gap={2}>
    {isCustomerDisabled ? (
      <FormTextField
        id={EditCustomPricingField.Customer}
        label="Customer"
        type="text"
        isLoading={isLoading}
        disabled
      />
    ) : (
      <CustomerAutocomplete id={CreateCustomPricingField.Customer} isLoading={isLoading} />
    )}

    <FormTextField
      id={CustomerProfileField.DiscountRate}
      label="Total discount %"
      placeholder="Fill out a discount percentage"
      type="number"
      isLoading={isLoading}
      icon={{
        value: PercentIcon,
        position: 'end',
      }}
      inputProps={{
        step: 0.01,
        min: 0,
        max: 100,
      }}
    />
  </Stack>
);
