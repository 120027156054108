import Box from '@mui/material/Box';
import MUIModal, { ModalProps as MUIModalProps } from '@mui/material/Modal';

import { Color } from '@theme/palette';

export type ModalProps = MUIModalProps;

/**
 * Returns enhanced Modal component.
 */
export const Modal = ({ children, ...muiProps }: ModalProps) => (
  <MUIModal {...muiProps}>
    <Box
      sx={{
        outline: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 600,
        borderRadius: '6px',
        bgcolor: Color.White,
        boxShadow: 24,
        padding: '20px',
      }}
    >
      {children}
    </Box>
  </MUIModal>
);
