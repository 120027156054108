import { DbCollection, DbJob } from '@uvac-apps/db-models';

import { Job } from '@lib/firebase';
import { updateDbDocumentById } from '@lib/firestore';
import { getJobById } from '@lib/firestore/Job';

/**
 * Updates job status in DB
 * Relies on Cloud Functions to update timestamps & invoice.
 * @param id - Unique job ID.
 * @param values - Values to update.
 */
export const updateJobStatus = async (id: string, values: Partial<Job>): Promise<void> => {
  const job = await getJobById(id);
  const previousTimestamps = job?.timestamps || {};

  // Only update document if they previously existed
  if (previousTimestamps) {
    await updateDbDocumentById<DbJob>(DbCollection.Jobs, id, { status: values.status });
  }
};
