import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Card } from '@components/Card';
import { Form } from '@components/Form';
import { PrimaryButton } from '@components/PrimaryButton';
import { useCustomerProfile, useEditCustomerProfile } from '@hooks/CustomerProfile';
import { useSnackbar } from '@hooks/useSnackbar';
import { CustomerProfileField } from '@lib/firebase';

import { BasicPricing } from '../components/BasicPricing';

export enum EditCustomPricingField {
  Customer = 'customerName',
}

const DEFAULT_DISCOUNT_RATE = 0;

type IEditCustomerProfileForm = {
  [EditCustomPricingField.Customer]: string;
  [CustomerProfileField.DiscountRate]: number;
};

export const EditCustomerProfile = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { data: customPricing, isLoading } = useCustomerProfile(id as string);

  const form = useForm<IEditCustomerProfileForm>({
    mode: 'onSubmit',
  });

  const { submit: editProfile } = useEditCustomerProfile();

  /**
   * Set default values for form
   */

  useEffect(() => {
    const customerName =
      customPricing?.customer?.operatingName ?? customPricing?.customer?.legalBusinessName;

    form.reset({
      customerName: customerName,
      discount_rate: customPricing?.discount_rate || DEFAULT_DISCOUNT_RATE,
    });
  }, [customPricing]);

  /**
   * Submit form - update pricing
   */
  const onSubmit = (values: IEditCustomerProfileForm) => {
    const data = {
      id: id as string,
      discount_rate: values.discount_rate || DEFAULT_DISCOUNT_RATE,
    };

    editProfile(data, {
      onCompleted: () => {
        enqueueSnackbar('Customer profile updated successfully', { variant: 'success' });
      },
    });
  };

  return (
    <>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack direction="column" gap={2}>
            <Card>
              <BasicPricing isLoading={isLoading} isCustomerDisabled />
            </Card>

            <Stack direction="row" alignSelf="flex-start">
              <PrimaryButton type="submit">Update Profile</PrimaryButton>
            </Stack>
          </Stack>
        </Form>
      </FormProvider>
    </>
  );
};
