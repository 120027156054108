import { useMemo } from 'react';

import { JobStatus } from '@lib/firebase';
import { getJobStatusStyling } from '@utils/status';

/**
 * Returns memoized function returning styling for job status.
 * @param status - Job status.
 */
export const useJobStatusStyle = (status: JobStatus) =>
  useMemo(() => getJobStatusStyling(status), [status]);
