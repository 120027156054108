import Stack from '@mui/material/Stack';

import { FormTextField } from '@components/TextField';

import { TaxesField } from '../fields';

export const BookingTaxRate = () => (
  <Stack direction="column" gap={2}>
    <FormTextField
      id={TaxesField.TaxRate}
      label="Total Tax Rate (in %)"
      placeholder="eg. 10, 12"
      type="number"
      required
    />

    <FormTextField
      id={TaxesField.TaxJurisdiction}
      label="Tax Jurisdiction"
      placeholder="eg. New York City"
      type="text"
      required
    />
  </Stack>
);
