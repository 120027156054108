import { Timestamp } from 'firebase/firestore';

import { IInvoicingOnboardingForm, IPersonalOnboardingForm } from '@components/Onboarding/fields';
import { useAuth } from '@hooks/useAuth';
import { useSubmitData } from '@hooks/useData';
import {
  UserOnboardingState,
  InvoicingProfileField,
  InvoicingAddressField,
  PersonalProfileField,
} from '@lib/firebase';
import { updateVendorProfile } from '@lib/firestore/Vendor';

/**
 * Returns hook for submitting personal vendor onboarding form.
 */
export const useOnboardPersonal = () => {
  const { currentUser } = useAuth();

  return useSubmitData(async (values: IPersonalOnboardingForm) => {
    if (!currentUser?.uid || !currentUser?.email) {
      return;
    }

    await updateVendorProfile(currentUser.uid, {
      ...values,
      [PersonalProfileField.PersonalEmail]: currentUser.email,
      onboarding_state: UserOnboardingState.FinishedStep1,
      created_at: Timestamp.fromDate(new Date()),
    });
  });
};

/**
 * Returns hook for submitting invoicing vendor onboarding form.
 */
export const useOnboardInvoicing = () => {
  const { currentUser } = useAuth();

  return useSubmitData(async (values: IInvoicingOnboardingForm) => {
    if (!currentUser?.uid || !currentUser?.email) {
      return;
    }

    await updateVendorProfile(currentUser.uid, {
      ...values,
      [InvoicingProfileField.SeperateInvoiceAddress]: values.seperate_invoice_address || false,
      [InvoicingProfileField.InvoicePhone]: values.invoice_phone || '',
      [InvoicingProfileField.InvoiceEmail]: values.invoice_email || '',
      [InvoicingProfileField.IndigenousOwned]: values.indigenous_owned || false,
      [InvoicingProfileField.IndigenousId]: values.indigenous_id || '',
      [InvoicingAddressField.Address1]: values.invoice_address1 || '',
      [InvoicingAddressField.Address2]: values.invoice_address2 || '',
      [InvoicingAddressField.City]: values.invoice_city || '',
      [InvoicingAddressField.State]: values.invoice_state || '',
      [InvoicingAddressField.PostalCode]: values.invoice_postal_code || '',
      [InvoicingAddressField.Country]: values.invoice_country || '',
      onboarding_state: UserOnboardingState.FinishedStep2,
    });
  });
};
