import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

import { Card } from '@components/Card';
import { Job } from '@lib/firebase';

import { BookingField } from './BookingField';

export interface BookingTimestampsProps {
  scheduled_time: Timestamp;
  timestamps: Job['timestamps'];
}

export const formatTimestamp = (timestamp?: Timestamp) => {
  if (!timestamp) return 'No timestamp yet';
  return format(timestamp.toDate(), 'dd MMM, hh:mm');
};

export const BookingTimestamps = ({ timestamps }: BookingTimestampsProps) => {
  // [JobTimeStampField.PaidAt]?: Timestamp;

  return (
    <Card>
      <Stack gap={2}>
        <Typography variant="h2">Timestamps</Typography>

        <Typography variant="h3">Booking</Typography>
        <BookingField label="Booking Requested" value={formatTimestamp(timestamps?.pending_at)} />

        {/* Should only be able to be rejected or accepted */}
        {!timestamps?.rejected_at && (
          <BookingField label="Accepted" value={formatTimestamp(timestamps?.accepted_at)} />
        )}
        {timestamps?.rejected_at && (
          <BookingField label="Booking Rejected" value={formatTimestamp(timestamps?.rejected_at)} />
        )}

        <BookingField label="Job Started" value={formatTimestamp(timestamps?.initiated_at)} />
        <BookingField label="Job Completed" value={formatTimestamp(timestamps?.completed_at)} />

        {/* Should only be able to be cancelled by one party */}
        {timestamps?.user_cancelled_at && (
          <BookingField
            label="Job Cancelled (by user)"
            value={formatTimestamp(timestamps?.completed_at)}
          />
        )}
        {timestamps?.vendor_cancelled_at && (
          <BookingField
            label="Job Cancelled (by vendor)"
            value={formatTimestamp(timestamps?.completed_at)}
          />
        )}

        <BookingField label="Job Paid" value={formatTimestamp(timestamps?.paid_at)} />
      </Stack>
    </Card>
  );
};
