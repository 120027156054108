import { DbCollection, DbCustomer, UserOnboardingState } from '@uvac-apps/db-models';
import { Timestamp, collection, getDocs, limit, query } from 'firebase/firestore';

import { firebaseDb } from '@lib/firebase';
import { getDbDocumentById, getDbFieldValueById, updateDbDocumentById } from '@lib/firestore';

import { BasicCustomer } from './types';

/**
 * Makes all types available from this module.
 */
export * from './types';

/**
 * Returns customer from DB by id.
 * @param id - Unique customer ID.
 */
export const getCustomerById = (id: string) =>
  getDbDocumentById<DbCustomer>(DbCollection.Customers, id);

/**
 * Returns onboarding state of customer from DB by id.
 * @param id - Unique customer ID.
 */
export const getCustomerOnboardingState = (id: string) =>
  getDbFieldValueById<DbCustomer, UserOnboardingState>(
    DbCollection.Customers,
    id,
    'onboarding_state',
  );

/**
 * Returns list with basic customer properties.
 * E.g. UID, email, operating name and legal business name for listing.
 */
export const getBasicCustomerList = async (): Promise<BasicCustomer[]> => {
  const collectionRef = collection(firebaseDb, DbCollection.Customers);
  const docSnapshots = await getDocs(query(collectionRef, limit(100)));
  return docSnapshots.docs.map((doc) => {
    const data = doc.data();
    return {
      uid: doc.id,
      email: data.email,
      operatingName: data.operating_name,
      legalBusinessName: data.legal_business_name,
    } as BasicCustomer;
  });
};

/**
 * Updates the customer profile.
 * @param id - Unique customer ID.
 * @param values - Values to update.
 */
export const updateCustomerProfile = (id: string, values: Partial<DbCustomer>) => {
  const now = new Date();

  return updateDbDocumentById(DbCollection.Customers, id, {
    ...values,
    updated_at: Timestamp.fromDate(now),
  });
};
