import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';

import { FormDateTimePicker } from '@components/DateTimePicker';
import { SecondaryButton } from '@components/SecondaryButton';
import { FormTextField } from '@components/TextField';
import { VehicleAvailabilityField } from '@lib/firebase';

type VehicleAvailabilityProps = {
  /**
   * Indicates whether the form data is loading.
   */
  isLoading?: boolean;
};

export const VehicleAvailability = ({ isLoading }: VehicleAvailabilityProps) => {
  const { setValue } = useFormContext();

  /**
   * Clear the unavailable date.
   */
  const clearUnavailable = () => {
    setValue<any>(`${VehicleAvailabilityField.UnavailableDate}.start`, undefined);
    setValue<any>(`${VehicleAvailabilityField.UnavailableDate}.end`, undefined);
    setValue<any>(`${VehicleAvailabilityField.UnavailableDate}.reason`, '');
  };

  return (
    <Stack gap={1}>
      <Typography variant="h5">Unavailability</Typography>
      <Divider />

      <Stack direction="column" gap={3} justifyContent="flex-start">
        <Box>
          You can make a vehicle unavailable between two dates for e.g. maintenance. These will
          become unavailable for customers to book during this period.
        </Box>
        <Stack direction="row" gap={1} alignItems="center">
          <FormDateTimePicker
            id={`${VehicleAvailabilityField.UnavailableDate}.start`}
            label="From"
            sx={{ width: '100%' }}
          />
          <FormDateTimePicker
            id={`${VehicleAvailabilityField.UnavailableDate}.end`}
            label="To"
            sx={{ width: '100%' }}
          />
        </Stack>

        <Stack>
          <FormTextField
            id={`${VehicleAvailabilityField.UnavailableDate}.reason`}
            label="Reason"
            placeholder="Provide a reason for setting the vehicle unavailable"
            type="text"
            minRows={2}
            isLoading={isLoading}
            multiline
          />
        </Stack>
        <SecondaryButton onClick={clearUnavailable} sx={{ alignSelf: 'start' }}>
          Clear unavailability
        </SecondaryButton>
      </Stack>
    </Stack>
  );
};
