import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { areIntervalsOverlapping } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import { Card } from '@components/Card';
import { StatusTag, StatusTagType } from '@components/StatusTag';
import { JobField, RateMode, VehicleWithDistance } from '@lib/firebase';
import { Color } from '@theme/palette';
import { getVehicleBookingPrice } from '@utils/price';

/**
 * Returns booking field showing extra info eg. Date & Time, Customer,...
 * @todo - Move into its own component if needed outside of this page
 */
const VehicleField = ({ label, value }: { label: string; value: string | JSX.Element }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    flexWrap="wrap"
    sx={{
      padding: '4px 10px',
      backgroundColor: Color.BackgroundColor,
      borderRadius: '10px',
    }}
  >
    <Box component="span" sx={{ fontWeight: 400 }}>
      {label}
    </Box>
    <Box component="span" sx={{ fontWeight: 600 }}>
      {value}
    </Box>
  </Stack>
);

/**
 * Returns label showing price with potential discount
 */
export const getVehiclePriceLabel = (price: number, discountedPrice: number) => {
  if (price > discountedPrice) {
    return (
      <Stack direction="row" gap={1} alignItems="baseline">
        <Box>$ {`${discountedPrice.toFixed(2)}`}</Box>
        <Box
          sx={{ fontSize: '0.8rem', textDecoration: 'line-through' }}
        >{`${price.toFixed(2)}`}</Box>
      </Stack>
    );
  }

  return <span>$ {`${price.toFixed(2)}`}</span>;
};

type VehicleListItemProps = {
  /**
   * Vehicle to display incl distance from job location.
   */
  vehicle: VehicleWithDistance;

  /**
   * Scheduled time for job.
   * Needed to see whether vehicle is available at this time.
   */
  scheduledTime: Date;

  /**
   * Scheduled end time for job.
   * Needed to see whether vehicle is available at this time.
   */
  scheduledEndTime: Date;

  /**
   * Indicates whether to show daily rates.
   * Defaults to hourly flat rate.
   */
  showDayRate?: boolean;
};

/**
 * Returns whether vehicle is unavailable at scheduled time.
 * Vehicles can be put on hold by vendors.
 **/
const isUnavailable = ({
  scheduledTime,
  scheduledEndTime,
  vehicle,
}: {
  scheduledTime: Date;
  scheduledEndTime: Date;
  vehicle: VehicleWithDistance;
}) => {
  const unavailableDate = vehicle.unavailable_date;

  if (!unavailableDate) {
    return false;
  }

  // Check if scheduled time is within unavailable date range
  return areIntervalsOverlapping(
    { start: unavailableDate.start.toDate(), end: unavailableDate.end.toDate() },
    { start: scheduledTime, end: scheduledEndTime },
  );
};

export const VehicleListItem = ({
  vehicle,
  scheduledTime,
  scheduledEndTime,
  showDayRate,
}: VehicleListItemProps) => {
  const { setValue, watch } = useFormContext();

  const { additional_flat_price: additionalPrice = 0 } = vehicle;
  const vehiclePrice = getVehicleBookingPrice(vehicle, showDayRate);

  const selectCard = (id: string) => {
    setValue(JobField.VehicleId, id);
  };

  const updatedValue = watch(JobField.VehicleId);
  const isActiveCard = vehicle.id === updatedValue;

  // Checks whether vehicle is available.
  const isVehicleUnavailable = isUnavailable({ scheduledTime, scheduledEndTime, vehicle });

  // All possible distance statuses with their colours schemes mapped to dispaly in status tags.
  const distanceStatuses = [
    {
      type: StatusTagType.Error,
      match: () => vehicle.distanceInKm > 50,
    },
    {
      type: StatusTagType.Warn,
      match: () => vehicle.distanceInKm >= 20 && vehicle.distanceInKm <= 50,
    },
    { type: StatusTagType.Success, match: () => vehicle.distanceInKm < 20 },
  ];

  /**
   * If vehicle is unavailable, don't display it.
   */
  if (isVehicleUnavailable) {
    return null;
  }

  return (
    <Card sx={{ border: isActiveCard ? `1px solid ${Color.Gray1}` : 'none' }}>
      <Stack gap={2} direction="column">
        <Stack justifyContent="space-between" gap={1} direction={{ xs: 'column', sm: 'row' }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <StatusTag
              conditions={distanceStatuses}
              text={`${(Math.round(vehicle.distanceInKm * 100) / 100).toFixed(1)} km`}
            />
            <Box component="span" sx={{ fontWeight: 700 }}>
              {vehicle.name}
            </Box>
          </Stack>

          {isActiveCard ? (
            <StatusTag
              color={Color.PrimaryGreen}
              backgroundColor={isActiveCard ? Color.BackgroundGreen : Color.BackgroundGrey}
              text="Selected vehicle"
            />
          ) : (
            <StatusTag
              color={Color.Black}
              backgroundColor={Color.White}
              border={`1px solid ${Color.Gray2}`}
              text="Select vehicle"
              onClick={() => selectCard(vehicle.id)}
              sx={{
                cursor: 'pointer',
              }}
            />
          )}
        </Stack>

        {vehiclePrice.price ? (
          <Stack gap={2} direction="column">
            {vehiclePrice.type === RateMode.Daily ? (
              <VehicleField
                label="Daily rate"
                value={getVehiclePriceLabel(vehiclePrice.price, vehiclePrice.salePrice)}
              />
            ) : (
              <VehicleField
                label="Hourly rate"
                value={getVehiclePriceLabel(vehiclePrice.price, vehiclePrice.salePrice)}
              />
            )}

            {/** Additional cost - Chris asked to be hidden for now!  */}
            {/*     {additionalPrice > 0 && (
              <VehicleField label="Additional cost" value={`$ ${additionalPrice.toFixed(2)}`} />
            )} */}
          </Stack>
        ) : (
          <Box>No pricing provided for this vehicle</Box>
        )}
      </Stack>
    </Card>
  );
};
