import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { Card } from '@components/Card';
import { PrimaryButton } from '@components/PrimaryButton';
import { JobPopulated, JobStatus } from '@lib/firebase';
import { BookingField } from '@pages/Booking/components/BookingField';
import { BookingStatus } from '@pages/Booking/components/BookingStatus';
import { BookingVendorId } from '@pages/Booking/components/BookingVendorId';
import { vendorRoutes } from '@routes/vendor';
import { Color } from '@theme/palette';

import { BookingActions } from './BookingActions';

export interface BookingListItemProps {
  booking: JobPopulated;
}

/**
 * Returns Booking list item
 */
export const BookingListItem = ({ booking }: BookingListItemProps) => {
  const { vendor } = booking;

  const navigate = useNavigate();
  const name = `${vendor?.operating_name} - ${vendor?.first_name} ${vendor?.last_name}`;

  const { address1, address2, city, state, country, postal_code } = booking.location;
  const address = address2 ? `${address2}-${address1}` : address1;
  const location = [address, city, state, country, postal_code].join(', ');

  return (
    <Card>
      <Stack gap={2}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            {booking.status !== JobStatus.Pending && booking.vendor_order_id && (
              <BookingVendorId id={booking.id} vendor_order_id={booking.vendor_order_id} disabled />
            )}
            <Typography variant="h4">{name}</Typography>
          </Stack>
          <BookingStatus status={booking.status} />
        </Stack>

        {/* Hourly/daily Rate */}
        <Stack direction="column" gap={1} alignItems="flex-start">
          <Box component="span" sx={{ fontWeight: 600, color: Color.LightBlack }}>
            {booking.invoice?.hourly_cost && <> {booking.invoice?.hourly_cost.toFixed(2)}/hr</>}
            {booking.invoice?.daily_cost && <> {booking.invoice?.daily_cost.toFixed(2)}/day</>}

            {/* Additional cost - Chris asked to be hidden for now! */}
            {/*             {booking.invoice?.additional_cost > 0 && (
              <> + ${booking.invoice?.additional_cost.toFixed(2)}</>
            )} */}
          </Box>
          <Box component="span">{booking.notes[booking.notes.length - 1]?.text}</Box>
        </Stack>

        {/* Booking details */}
        <Stack direction="column" spacing={1} alignItems="stretch" sx={{ marginBottom: 1 }}>
          <BookingField
            label="Scheduled Start Time"
            value={format(booking.scheduled_time.toDate(), 'dd MMM, yyyy - hh:mm aaa')}
          />
          {booking.scheduled_end_time && (
            <BookingField
              label="Scheduled End Time"
              value={format(booking.scheduled_end_time.toDate(), 'dd MMM, yyyy - hh:mm aaa')}
            />
          )}
          <BookingField label="Vendor" value={name} />

          <BookingField label="Address" value={location} />
        </Stack>
        <BookingActions
          id={booking.id}
          status={booking.status}
          scheduledTime={booking.scheduled_time}
        />
        <PrimaryButton onClick={() => navigate(vendorRoutes.bookingDetail.getPath(booking.id))}>
          Details
        </PrimaryButton>
      </Stack>
    </Card>
  );
};
