import CalendarIcon from '@mui/icons-material/CalendarMonth';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { BookingListItem } from '@Vendor/pages/Booking/components/BookingListItem';
import { useNavigate } from 'react-router-dom';

import { BasicInfoCard } from '@components/Card/InfoCard';
import { Loader } from '@components/Loader';
import { PrimaryButton } from '@components/PrimaryButton';
import { SecondaryButton } from '@components/SecondaryButton';
import { useMyJobs } from '@hooks/Job/useMyJobs';
import { useVendorDashboard } from '@hooks/useDashboard';
import { useMyVehicles } from '@hooks/Vehicle/useMyVehicles';
import { JobStatus, UserType } from '@lib/firebase';
import { vendorRoutes } from '@routes/vendor';
import { Color } from '@theme/palette';

import TruckIcon from '../../../../icons/truck.svg';

export const Home = () => {
  const { data: vehicles = [], isLoading: vehiclesLoading } = useMyVehicles();
  const { data: dashboard } = useVendorDashboard();
  const navigate = useNavigate();
  const { jobs: activeBookings } = useMyJobs(UserType.Vendor, {
    status: [JobStatus.Accepted, JobStatus.Initiated],
  });

  if (vehiclesLoading) {
    return (
      <Stack direction="row">
        <Loader />
      </Stack>
    );
  }

  if (vehicles.length === 0) {
    return (
      <Stack alignItems="center" justifyContent="center" minHeight={400} spacing={3}>
        <Box
          component="img"
          alt="HydroVac Silhouette"
          src={TruckIcon}
          sx={{
            height: 944,
            width: 256,
            maxHeight: { xs: 94, md: 94 },
            maxWidth: { xs: 256, md: 256 },
          }}
        />
        <Typography align="center" fontSize={24}>
          Please add at least one vehicle to get started!
        </Typography>
        <PrimaryButton onClick={() => navigate(vendorRoutes.addVehicle.getPath())}>
          Add Vehicle
        </PrimaryButton>
      </Stack>
    );
  }

  return (
    <Box>
      <Stack direction="column" gap={4}>
        {/** Overview items  */}
        <Stack direction="column" gap={2}>
          <Typography variant="h3">Overview</Typography>

          <Stack direction="row" gap={2} flexWrap="wrap">
            <BasicInfoCard
              label="Total Revenue"
              value={`$${dashboard.totalRevenue.toFixed(2)}`}
              icon={{
                value: CalendarIcon,
                color: Color.Blue,
                backgroundColor: Color.BackgroundBlue,
              }}
            />

            <BasicInfoCard
              label="Total Bookings"
              value={dashboard.jobCount}
              icon={{
                value: CalendarIcon,
                color: Color.Green,
                backgroundColor: Color.BackgroundGreen,
              }}
            />
          </Stack>
        </Stack>

        {/** Recent bookings */}
        <Stack direction="column" gap={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h3">Ongoing bookings</Typography>
            <SecondaryButton onClick={() => navigate(vendorRoutes.bookings.path)}>
              View all
            </SecondaryButton>
          </Stack>

          <Stack gap={2}>
            {activeBookings.map((booking) => (
              <BookingListItem key={booking.id} booking={booking} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
