import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Color } from '../../theme/palette';

type LoaderProps = {
  /**
   * Optional text to display next to loader.
   */
  text?: string;
};

/**
 * Returns a loading spinner.
 */
export const Loader = ({ text }: LoaderProps) => {
  const speed = 1;
  const stroke = Color.Green;
  const strokeWidth = 2;

  return (
    <Box>
      <Stack
        direction="row"
        gap={1}
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <svg
          {...{
            stroke,
            speed,
            strokeWidth,
            height: 30,
            width: 30,
          }}
          viewBox="0 0 38 38"
          overflow="visible"
        >
          <g transform="translate(1 1)" fill="none" fillRule="evenodd" {...{ stroke, strokeWidth }}>
            <circle strokeOpacity={0.5} cx={18} cy={18} r={18} />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur={`${1 / speed}s`}
                repeatCount="indefinite"
              />
            </path>
          </g>
        </svg>
        {text && <Box>{text}</Box>}
      </Stack>
    </Box>
  );
};
