import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AddressAutocomplete } from '@components/AddressAutocomplete';
import { FormSelect } from '@components/Select';
import { FormTextField } from '@components/TextField';
import { USE_MOCKED_ADDRESSES } from '@config/address';
import { AddressField } from '@lib/firebase';
import { AddressAutocompleteGeoResult } from '@lib/googleApi';
import { getStates } from '@utils/address';

// Currently only support for these countries
// Use eg. https://www.npmjs.com/package/i18n-iso-countries to get the full list
const AVAILABLE_COUNTRIES = [
  { label: 'Canada', value: 'CA' },
  { label: 'United States', value: 'US' },
];

const DEFAULT_COUNTRY = 'CA';

interface AddressProps {
  /**
   * Indicates whether to use the Google Places Autocomplete component.
   */
  useAutoComplete?: boolean;

  /**
   * Optional prefix for the form field IDs.
   * Allows using multiple address forms on the same page.
   */
  prefix?: string;

  /**
   * Indicates whether external data is being loaded.
   */
  isLoading?: boolean;
}

/**
 * Returns a form for entering an address.
 * Supports Google Places Autocomplete optionally.
 * Supports multiple address forms on the same page using `prefix`.
 */
export const FormAddress = ({ useAutoComplete, isLoading, prefix = '' }: AddressProps) => {
  type IAddressForm = { [key in `${typeof prefix}${AddressField}`]: string };

  // Hide form when using Addresses autocomplete
  const [showForm, setShowForm] = useState(!useAutoComplete);
  const [availableStates, setAvailableStates] = useState<{ label: string; value: string }[]>([]);

  const { reset, watch, setValue, getValues } = useFormContext<IAddressForm>();

  // Watch for changes to update available states/provinces.
  const country = watch(`${prefix}${AddressField.country}`);

  // Set available states/provinces when country changes.
  useEffect(() => {
    const latestStates = getStates(country || DEFAULT_COUNTRY);
    setAvailableStates(latestStates);
  }, [country]);

  // Handle onChange manually instead of `watch` to avoid auto-complete from triggering a country change.
  // If autocomplete triggers a country change, it will not autocomplete state/province.
  const handleCountryChange = (countryCode: string) => {
    // Updates the country code in the form.
    setValue(`${prefix}${AddressField.country}`, countryCode);

    // Clears state/province to avoid mismatch.
    // TODO: Should we clear zipcode/city as well when country changes?
    setValue(`${prefix}${AddressField.state}`, '');
  };

  /**
   * Handle when address changes from autocomplete.
   */
  const onAutocompleteAddressChange = (address: AddressAutocompleteGeoResult | null) => {
    // If address was found, autofill form.
    if (address) {
      // Avoid other form values from being reset.
      const previousValues = getValues();
      const { streetAddress, streetNumber, countryCode, city, postalCode, stateCode } = address;
      reset({
        ...previousValues,
        [`${prefix}${AddressField.address1}`]: `${streetNumber} ${streetAddress}`,
        [`${prefix}${AddressField.country}`]: countryCode,
        [`${prefix}${AddressField.state}`]: stateCode,
        [`${prefix}${AddressField.postalCode}`]: postalCode,
        [`${prefix}${AddressField.city}`]: city,
      });
    }

    // Show form when address gets selected.
    setShowForm(true);
  };

  return (
    <Stack direction="column" gap={2}>
      {/** Show optional autocomplete when no form is shown */}
      {useAutoComplete && !showForm && (
        <AddressAutocomplete
          onSelected={onAutocompleteAddressChange}
          useMockedAddresses={USE_MOCKED_ADDRESSES}
          required
        />
      )}

      {showForm && (
        <>
          <FormTextField
            id={`${prefix}${AddressField.address1}`}
            label="Street"
            placeholder="Street name and number"
            type="text"
            isLoading={isLoading}
            required
          />

          <FormTextField
            id={`${prefix}${AddressField.address2}`}
            label="Apartment, suite, etc. (optional)"
            placeholder="Address 2"
            isLoading={isLoading}
            type="text"
          />

          <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
            <FormSelect
              id={`${prefix}${AddressField.country}`}
              label="Country"
              placeholder="Select country"
              type="text"
              onChange={(event) => handleCountryChange(event.target.value as string)}
              defaultValue={DEFAULT_COUNTRY}
              options={AVAILABLE_COUNTRIES}
              isLoading={isLoading}
              required
            />

            {availableStates.length > 0 && (
              <FormSelect
                id={`${prefix}${AddressField.state}`}
                label="State/Province"
                placeholder="Select state or province"
                type="text"
                options={availableStates}
                defaultValue={''}
                isLoading={isLoading}
                required
              />
            )}
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
            <FormTextField
              id={`${prefix}${AddressField.city}`}
              label="City"
              placeholder="City"
              type="text"
              isLoading={isLoading}
              required
            />

            <FormTextField
              id={`${prefix}${AddressField.postalCode}`}
              label="Zip/Postal Code"
              placeholder="Zip/Postal Code"
              type="text"
              isLoading={isLoading}
              required
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
