import { useAuth } from '@hooks/useAuth';
import { useSubmitData } from '@hooks/useData';
import { Field, JobField, JobStatus } from '@lib/firebase';
import { updateJobStatus } from '@lib/firestore/Job';

interface IChangeJobStatus {
  [Field.Id]: string;
  [JobField.Status]: JobStatus;
}

/**
 * Returns hook for updating job status for job.
 */
export const useChangeJobStatus = () => {
  const { currentUser } = useAuth();

  return useSubmitData(async ({ id, ...values }: IChangeJobStatus) => {
    if (!currentUser?.uid) {
      return;
    }

    await updateJobStatus(id, values);
  });
};
