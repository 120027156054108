import { AddressAutocompleteResult } from './interfaces';

export const ADDRESS_FIRST_PLACE_ID =
  'EjQ3MTAgV2lsbG93IFBhcmsgRHJpdmUgU291dGhlYXN0LCBDYWxnYXJ5LCBBQiwgQ2FuYWRhIjESLwoUChIJ0fD-sypxcVMRSMhlDxxNh9YQxgUqFAoSCZ-olOIscXFTEUiYaOJDrMmY';

/**
 * Returns list of mocked address autocomplete results.
 */
export const addressAutocompleteMocks: AddressAutocompleteResult[] = [
  {
    description: '710 Willow Park Drive Southeast, Calgary, AB, Canada',
    matched_substrings: [
      {
        length: 15,
        offset: 0,
      },
    ],
    place_id: ADDRESS_FIRST_PLACE_ID,
    reference: '',
    structured_formatting: {
      main_text: '710 Willow Park Drive Southeast',
      main_text_matched_substrings: [
        {
          length: 15,
          offset: 0,
        },
      ],
      secondary_text: 'Calgary, AB, Canada',
    },
    terms: [
      {
        offset: 0,
        value: '710 Willow Park Drive Southeast',
      },
      {
        offset: 33,
        value: 'Calgary',
      },
      {
        offset: 42,
        value: 'AB',
      },
      {
        offset: 46,
        value: 'Canada',
      },
    ],
    types: ['street_address', 'geocode'],
  },
  {
    description: '25 Ordnance Street, Toronto, ON, Canada',
    matched_substrings: [
      {
        length: 2,
        offset: 0,
      },
      {
        length: 6,
        offset: 3,
      },
    ],
    place_id: 'ChIJjbjYRhw1K4gRXdq2DaHA6dE',
    reference: '',
    structured_formatting: {
      main_text: '25 Ordnance Street',
      main_text_matched_substrings: [
        {
          length: 2,
          offset: 0,
        },
        {
          length: 6,
          offset: 3,
        },
      ],
      secondary_text: 'Toronto, ON, Canada',
    },
    terms: [
      {
        offset: 0,
        value: '25',
      },
      {
        offset: 3,
        value: 'Ordnance Street',
      },
      {
        offset: 20,
        value: 'Toronto',
      },
      {
        offset: 29,
        value: 'ON',
      },
      {
        offset: 33,
        value: 'Canada',
      },
    ],
    types: ['street_address', 'geocode'],
  },
];

export const addressGeoMocks = {
  [ADDRESS_FIRST_PLACE_ID]: [
    {
      address_components: [
        {
          long_name: '710',
          short_name: '710',
          types: ['street_number'],
        },
        {
          long_name: 'Willow Park Drive Southeast',
          short_name: 'Willow Park Dr SE',
          types: ['route'],
        },
        {
          long_name: 'Southeast Calgary',
          short_name: 'Southeast Calgary',
          types: ['neighborhood', 'political'],
        },
        {
          long_name: 'Calgary',
          short_name: 'Calgary',
          types: ['locality', 'political'],
        },
        {
          long_name: 'Alberta',
          short_name: 'AB',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: 'Canada',
          short_name: 'CA',
          types: ['country', 'political'],
        },
        {
          long_name: 'T2J',
          short_name: 'T2J',
          types: ['postal_code', 'postal_code_prefix'],
        },
      ],
      formatted_address: '710 Willow Park Dr SE, Calgary, AB T2J, Canada',
      geometry: {
        location: {
          lat: () => 50.9598522,
          lng: () => -114.0476536,
        },
        location_type: 'RANGE_INTERPOLATED',
        viewport: {
          south: 50.95831731970851,
          west: -114.0489747302915,
          north: 50.96101528029151,
          east: -114.0462767697085,
        },
      },
      place_id:
        'EjQ3MTAgV2lsbG93IFBhcmsgRHJpdmUgU291dGhlYXN0LCBDYWxnYXJ5LCBBQiwgQ2FuYWRhIjESLwoUChIJ0fD-sypxcVMRSMhlDxxNh9YQxgUqFAoSCZ-olOIscXFTEUiYaOJDrMmY',
      types: ['street_address'],
    },
  ],
  ChIJjbjYRhw1K4gRXdq2DaHA6dE: [
    {
      address_components: [
        {
          long_name: '25',
          short_name: '25',
          types: ['street_number'],
        },
        {
          long_name: 'Ordnance Street',
          short_name: 'Ordnance St',
          types: ['route'],
        },
        {
          long_name: 'Old Toronto',
          short_name: 'Old Toronto',
          types: ['political', 'sublocality', 'sublocality_level_1'],
        },
        {
          long_name: 'Toronto',
          short_name: 'Toronto',
          types: ['locality', 'political'],
        },
        {
          long_name: 'Toronto',
          short_name: 'Toronto',
          types: ['administrative_area_level_3', 'political'],
        },
        {
          long_name: 'Toronto',
          short_name: 'Toronto',
          types: ['administrative_area_level_2', 'political'],
        },
        {
          long_name: 'Ontario',
          short_name: 'ON',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: 'Canada',
          short_name: 'CA',
          types: ['country', 'political'],
        },
        {
          long_name: 'M6K 1A1',
          short_name: 'M6K 1A1',
          types: ['postal_code'],
        },
      ],
      formatted_address: '25 Ordnance St, Toronto, ON M6K 1A1, Canada',
      geometry: {
        location: {
          lat: () => 43.6390672,
          lng: () => -79.4100811,
        },
        viewport: {
          south: 43.63788581970849,
          west: -79.4114911302915,
          north: 43.64058378029149,
          east: -79.40879316970849,
        },
      },
      place_id: 'ChIJjbjYRhw1K4gRXdq2DaHA6dE',
      plus_code: {
        compound_code: 'JHQQ+JX Toronto, ON, Canada',
        global_code: '87M2JHQQ+JX',
      },
      types: ['street_address'],
    },
  ],
  // Force conversion as they're LatLong objects etc. Not worth the effort to mock
} as unknown as { [key: string]: google.maps.GeocoderResult[] };
