import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { RefCallBack } from 'react-hook-form';

import { withController } from '@utils/form';

type UncontrolledCheckboxProps = {
  id?: string;

  /**
   * Renders optional display label.
   */
  label?: React.ReactNode | string;

  /**
   * Allows to pass ref to the select when used together with react-hook-form.
   * Only use this when using react-hook-form.
   */
  innerRef?: RefCallBack;
};

export const Checkbox = ({
  id,
  label,
  innerRef,
  ...rest
}: UncontrolledCheckboxProps & CheckboxProps) => (
  <FormGroup>
    <FormControlLabel
      control={
        <MUICheckbox
          id={id}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checked={!!rest.value as boolean}
          ref={innerRef}
          sx={{
            width: '20px',
            padding: 0,
            margin: '0 8px 0 11px',
            cursor: 'pointer',
          }}
          {...rest}
        />
      }
      label={<Typography variant="body2">{label}</Typography>}
    />
  </FormGroup>
);

/**
 * Returns a Checkbox component with a react-hook-form controller wrapped around.
 * This is the recommended way to use react-hook-form with MUI components.
 */
export const FormCheckbox = withController<CheckboxProps & UncontrolledCheckboxProps>(Checkbox);
