import { useState } from 'react';

/**
 * Hook for using local storage in browser.
 * @param {string} keyName - Unique key name.
 * @param {object} defaultValue - Default value when initializing hook.
 */
export const useLocalStorage = (keyName: string, defaultValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: any) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (error: any) {
      console.error(`Error setting local storage value ${error.message}`);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
