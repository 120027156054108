import { useAuth } from '@hooks/useAuth';
import { useEffectFetchData } from '@hooks/useData';
import { getMyVehicles } from '@lib/firestore/Vehicle';

/**
 * Returns hook for getting vehicles for current user.
 */
export const useMyVehicles = () => {
  const { currentUser } = useAuth();

  return useEffectFetchData(() => {
    if (!currentUser?.uid) {
      return undefined;
    }

    return getMyVehicles(currentUser.uid);
  }, [currentUser]);
};
