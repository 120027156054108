import {
  FormControlLabel,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  Typography,
} from '@mui/material';
import React from 'react';
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form';

import { getUniqueId } from '@utils/id';

export type SwitchProps = {
  label?: string;
  options?: RegisterOptions<any, any>;
};

export const FormSwitch: React.FC<MuiSwitchProps & SwitchProps> = ({
  id = getUniqueId(),
  label,
  options = {},
  ...muiProps
}) => {
  // React-hook-form support (best practices)
  // Even if register is not used it has to be called since it's a React hook
  const { register } = useFormContext<any>() || {};
  const formValue = useWatch({
    name: id,
    defaultValue: false,
  });

  // React-hooks form support
  const registerProps = () => {
    const { ref, ...restOfRegistrationProps } = register(id, options);

    return {
      inputRef: ref,
      checked: formValue,
      ...restOfRegistrationProps,
    };
  };

  return (
    <FormControlLabel
      control={<MuiSwitch id={id} size="small" {...registerProps()} {...muiProps} />}
      label={
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.primary,
            fontWeight: 400,
            fontSize: '0.875rem',
          })}
        >
          {label || ''}
        </Typography>
      }
    />
  );
};
