import { AuthorizedRoute as AuthorizedCustomerRoute } from '@Customer/layouts/AuthorizedRoute';
import { NotFound as CustomerNotFound } from '@Customer/pages/NotFound';
import { AuthorizedRoute as AuthorizedVendorRoute } from '@Vendor/layouts/AuthorizedRoute';
import { NotFound as VendorNotFound } from '@Vendor/pages/NotFound';
import { useEffect } from 'react';
import { Route, Routes as ReactRoutes, useLocation } from 'react-router-dom';

import { logScreenView } from '@lib/analytics';

import { Layout } from '../layouts/Layout';
import { customerRoutes } from './customer';
import { vendorRoutes } from './vendor';

/**
 * Render Vendor element based on public route & authentication.
 * @param Component - Component to render.
 * @param isPublic - Indicates whether it's a public route.
 */
const renderVendorElement = (Component: React.FC<Record<string, never>>, isPublic = false) =>
  isPublic ? (
    <Component />
  ) : (
    <AuthorizedVendorRoute>
      <Component />
    </AuthorizedVendorRoute>
  );

/**
 * Render Customer element based on public route & authentication.
 * @param Component - Component to render.
 * @param isPublic - Indicates whether it's a public route.
 */
const renderCustomerElement = (Component: React.FC<Record<string, never>>, isPublic = false) =>
  isPublic ? (
    <Component />
  ) : (
    <AuthorizedCustomerRoute>
      <Component />
    </AuthorizedCustomerRoute>
  );

/**
 * Returns routes for either customer or vendor.
 * @param outputType - Output type of the app.
 */
const routeGroup = (outputType: 'customer' | 'vendor') => {
  if (outputType === 'customer') {
    return (
      <Route path="/" element={<Layout />}>
        {Object.values(customerRoutes).map(({ path, component: Component, public: isPublic }) => (
          <Route key={path} path={path} element={renderCustomerElement(Component, isPublic)} />
        ))}

        {/* Customer - 404 */}
        <Route path="*" element={<CustomerNotFound />} />
      </Route>
    );
  }

  return (
    <Route path="/" element={<Layout />}>
      {Object.values(vendorRoutes).map(({ path, component: Component, public: isPublic }) => (
        <Route key={path} path={path} element={renderVendorElement(Component, isPublic)} />
      ))}

      {/* Vendor - 404 */}
      <Route path="*" element={<VendorNotFound />} />
    </Route>
  );
};

/**
 * Returns usable routes within app.
 * Depending on OUTPUT_TYPE environment variable, it will return either customer or vendor routes.
 */
export const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    logScreenView({ path: location.pathname, search: location.search });
  }, [location.pathname]);

  const outputType = process.env.OUTPUT_TYPE;

  return <ReactRoutes>{routeGroup(outputType)}</ReactRoutes>;
};
