import { DependencyList, useEffect, useState } from 'react';

export interface IFetchResponse<T> {
  data?: T;
  isLoading: boolean;
  error: Error | null;
}

export interface ISubmitResponse<T, K = undefined> {
  data?: K;
  isLoading: boolean;
  error: Error | null;
  submit: (data: T, props?: ISubmitProps<K | undefined | void>) => Promise<void>;
}

export interface ISubmitProps<T = undefined | void> {
  onCompleted?: (response: T) => void;
  onError?: (error: Error) => void;
}

/**
 * Returns hook for fetching data from external source with useEffect.
 * @param queryFn - Function that returns a promise with data.
 * @param [deps] - Dependency list for useEffect.
 */
export const useEffectFetchData = <T,>(
  queryFn: () => Promise<T> | T,
  deps: DependencyList | undefined = [],
): IFetchResponse<T> => {
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const apiResponse = await queryFn();

        // Response can be undefined if no data is found
        if (apiResponse) {
          setData(apiResponse);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);

        setError(error as Error);
        setIsLoading(false);

        // Debugging purposes
        // TODO: Integrate service such as Sentry
        // TODO: Do we want to support onError callback for fetching too?
        console.error(error);
      }
    };
    fetchData();
  }, deps);

  return { data, isLoading, error };
};

/**
 * Returns hook for submitting data to external source.
 * @param submitFn - Function which will submit data.
 */
export const useSubmitData = <T, K>(
  submitFn: (data: T) => Promise<K | undefined | void> | K | undefined | void,
): ISubmitResponse<T, K> => {
  const [data, setData] = useState<K>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const submit = async (
    data: T,
    { onCompleted, onError }: ISubmitProps<K | undefined | void> = {},
  ) => {
    setIsLoading(true);

    try {
      const apiResponse = await submitFn(data);

      // Response can be undefined if no data is found
      if (apiResponse) {
        setData(apiResponse);
      }
      setIsLoading(false);

      // Callback function to indicate function has succesfully completed
      if (onCompleted) {
        onCompleted(apiResponse || undefined);
      }
    } catch (error) {
      console.error('Error submitting data: ', error);

      // TODO: Integrate service such as Sentry
      setError(error as Error);
      setIsLoading(false);

      // If no error callback is provided, throw error
      if (onError) {
        onError(error as Error);
      } else {
        throw error;
      }
    }
  };

  return { submit, data, isLoading, error };
};
