import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { JobStatus } from '@lib/firebase';
import { Color } from '@theme/palette';

const DEFAULT_FILTER = JobStatus.Pending;

const tabs: { value: JobStatus; label: string }[] = [
  { value: JobStatus.Pending, label: 'Pending' },
  { value: JobStatus.Accepted, label: 'Accepted' },
  { value: JobStatus.Rejected, label: 'Rejected' },
  { value: JobStatus.Initiated, label: 'Initiated' },
  { value: JobStatus.Completed, label: 'Completed' },
  { value: JobStatus.Paid, label: 'Paid' },
  { value: JobStatus.UserCancelled, label: 'User Cancelled' },
  { value: JobStatus.VendorCancelled, label: 'Cancelled' },
];

export const BookingFilters = ({
  onStatusUpdate,
}: {
  onStatusUpdate: (value: JobStatus) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams({ status: DEFAULT_FILTER });

  // See whether query parameter is a valid status filter
  const queryStatusFilter = searchParams.get('status');
  const bookingStatus = tabs.find((x) => x.value === queryStatusFilter)?.value || DEFAULT_FILTER;

  const [value, setValue] = useState(bookingStatus);

  const handleChange = (_event: React.SyntheticEvent, newValue: JobStatus) => {
    setValue(newValue);
    setSearchParams({ status: newValue });
    onStatusUpdate(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      aria-label="Booking statuses"
      variant="scrollable"
      sx={{
        borderBottom: `${Color.Gray3} 1px solid`,
        marginBottom: '20px',
      }}
      allowScrollButtonsMobile
    >
      {tabs.map(({ value, label }) => (
        <Tab
          key={label}
          label={label}
          value={value}
          sx={{ color: Color.LightBlack, fontWeight: 400, padding: '20px 20px' }}
        />
      ))}
    </Tabs>
  );
};
