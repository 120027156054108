import AddBoxIcon from '@mui/icons-material/AddBox';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

import { Loader } from '@components/Loader';
import { SecondaryButton } from '@components/SecondaryButton';
import { useMyVehicles } from '@hooks/Vehicle/useMyVehicles';
import { vendorRoutes } from '@routes/vendor';

import { VehicleListItem } from '../components/VehicleListItem';

export const VehicleOverview = () => {
  const navigate = useNavigate();
  const { data: vehicles = [], isLoading } = useMyVehicles();

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <SecondaryButton
          onClick={() => navigate(vendorRoutes.addVehicle.path)}
          startIcon={<AddBoxIcon />}
          sx={{ marginBottom: '25px' }}
        >
          Add Hydrovac
        </SecondaryButton>
      </Stack>

      {isLoading ? (
        <Loader text="Loading your vehicles" />
      ) : (
        <Stack gap={2}>
          {vehicles.map((vehicle) => (
            <VehicleListItem key={vehicle.id} vehicle={vehicle} />
          ))}
        </Stack>
      )}
    </Box>
  );
};
