import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { BookingListItem } from '@Vendor/pages/Booking/components/BookingListItem';
import { useState } from 'react';
import { EventWrapperProps } from 'react-big-calendar';

import { VendorEvent } from '@hooks/Calendar';

type EventPopoverWrapperProps = {
  /**
   * Children to wrap.
   * Will show/hide popover on click.
   */
  children?: React.ReactNode;

  /**
   * Popover to show on click.
   */
  popover: JSX.Element;
};

/**
 * Returns Event popover wrapper used within react-big-calendar.
 */
const EventPopoverWrapper = ({ children, popover }: EventPopoverWrapperProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box component="div" aria-describedby={id} onClick={handleClick}>
        {children}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popover}
      </Popover>
    </>
  );
};

/**
 * Returns Popover rendered when clicking on provided event.
 * Currently display BookingListItem (same as in BookingOverview).
 */
export const EventPopover = (props: EventWrapperProps<VendorEvent>) => (
  <EventPopoverWrapper {...props} popover={<BookingListItem booking={props.event.resource} />} />
);
