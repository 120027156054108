import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { ChipTypeMap } from '@mui/material/Chip';
import { Controller, useFormContext } from 'react-hook-form';

import { Color } from '@theme/palette';

/**
 * Returns an Autocomplete component with a react-hook-form controller wrapped around.
 */
export const FormAutocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, false, ChipComponent>,
) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.id ?? 'autocomplete'}
      render={({ field: { ref, onChange, ...field } }) => (
        <Autocomplete
          {...props}
          onChange={(_, data) => onChange(data)}
          renderInput={(params) => props.renderInput({ ...params, ...field, inputRef: ref } as any)}
          sx={{
            marginTop: '8px', // Compensate for the label
            '& label': {
              color: Color.Black,
            },
          }}
        />
      )}
    />
  );
};
