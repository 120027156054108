import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { MainContent } from '@components/MainContent';
import { Menu } from '@components/Menu';
import { MenuItem } from '@components/Menu/MenuItem';
import { PrimaryButton } from '@components/PrimaryButton';
import { customerRoutes } from '@routes/customer';

interface NavigationProps {
  children: ReactNode;
}

export const CustomerNavigation = ({ children }: NavigationProps) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Side menu */}
      <Menu profilePath={customerRoutes.profile.path}>
        <PrimaryButton
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => navigate(customerRoutes.requestBooking.path)}
          fullWidth
          sx={{ marginBottom: 2 }}
        >
          Request Service
        </PrimaryButton>
        <MenuItem label="Dashboard" path={customerRoutes.home.path} icon={DashboardIcon} />
        <MenuItem label="Bookings" path={customerRoutes.bookings.path} icon={EventAvailableIcon} />
      </Menu>

      {/* Main content */}
      <MainContent>{children}</MainContent>
    </Box>
  );
};
