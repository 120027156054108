import { Stack, Typography } from '@mui/material';

import { Card } from '@components/Card';
import { Invoice, RateMode } from '@lib/firebase';

import { BookingField } from './BookingField';

export interface BookingInvoicingProps {
  id: string;
  invoice: Invoice;
}

export const BookingInvoice = ({ invoice }: BookingInvoicingProps) => {
  const TBD = 'Awaiting job progress';
  const {
    hourly_cost,
    daily_cost,
    additional_cost,
    tax_rate,
    tax_jurisdiction,
    total_hours,
    subtotal,
    tax_amount,
    total,
    rate_mode,
  } = invoice || {};

  return (
    <Card>
      <Stack gap={2}>
        <Typography variant="h2">Invoice</Typography>
        <BookingField
          label="Rate choice"
          value={rate_mode === RateMode.Hourly ? 'Hourly' : 'Daily'}
        />

        {/** Hourly rate selected */}
        {rate_mode === RateMode.Hourly && (
          <BookingField
            label="Hourly Cost ($/h)"
            value={hourly_cost ? `$${hourly_cost.toFixed(2)} / hour` : TBD}
          />
        )}

        {/** Daily rate selected */}
        {rate_mode === RateMode.Daily && (
          <BookingField
            label="Daily Cost ($/day)"
            value={daily_cost ? `$${daily_cost.toFixed(2)} / day` : TBD}
          />
        )}

        <BookingField
          label="Addtional Cost ($/booking)"
          value={additional_cost ? `$${additional_cost.toFixed(2)}` : TBD}
        />
        <BookingField
          label="Sales Tax Rate (%)"
          value={tax_rate ? `${(tax_rate * 100).toFixed(2)}%` : TBD}
        />

        <BookingField label="Tax Jurisdiction" value={tax_jurisdiction || TBD} />

        {/* Calcualted After Completion */}
        <BookingField label="Total Hours (h)" value={total_hours ? total_hours.toFixed(2) : TBD} />
        <BookingField label="Subtotal ($)" value={subtotal ? `$${subtotal.toFixed(2)}` : TBD} />
        <BookingField
          label="Tax Total ($)"
          value={tax_amount ? `$${tax_amount.toFixed(2)}` : TBD}
        />
        <BookingField label="Total ($)" value={total ? `$${total.toFixed(2)}` : TBD} />
      </Stack>
    </Card>
  );
};
