import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContactsIcon from '@mui/icons-material/Contacts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';

import { MainContent } from '@components/MainContent';
import { Menu } from '@components/Menu';
import { MenuItem } from '@components/Menu/MenuItem';
import { vendorRoutes } from '@routes/vendor';

interface NavigationProps {
  children: ReactNode;
}

export const VendorNavigation = ({ children }: NavigationProps) => (
  <Box sx={{ display: 'flex' }}>
    {/* Side menu */}
    <Menu profilePath={vendorRoutes.profile.path}>
      <MenuItem label="Dashboard" path={vendorRoutes.home.path} icon={DashboardIcon} />
      <MenuItem label="Bookings" path={vendorRoutes.bookings.path} icon={EventAvailableIcon} />
      <MenuItem label="Schedule" path={vendorRoutes.calendar.path} icon={CalendarMonthIcon} />
      <MenuItem label="Vehicles" path={vendorRoutes.vehicles.path} icon={LocalShippingIcon} />
      <MenuItem label="Customers" path={vendorRoutes.customerProfiles.path} icon={ContactsIcon} />
    </Menu>

    {/* Main content */}
    <MainContent>{children}</MainContent>
  </Box>
);
