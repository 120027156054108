import { DbCollection, DbJob, DbJobNote } from '@uvac-apps/db-models';

import { updateDbDocumentById } from '@lib/firestore';
import { getJobById } from '@lib/firestore/Job';

/**
 * Adds job note in DB
 * @param id - Unique job ID.
 * @param values - Values to update.
 */
export const addJobNote = async (id: string, values: DbJobNote) => {
  const previousNotes = (await getJobById(id))?.notes || [];

  return updateDbDocumentById<DbJob>(DbCollection.Jobs, id, {
    notes: [values, ...previousNotes],
  });
};
