import Stack from '@mui/material/Stack';

import { FormAutocomplete } from '@components/Autocomplete';
import { Loader } from '@components/Loader';
import { StyledTextField } from '@components/TextField';
import { useCategories } from '@hooks/Category';
import { getParentCategory } from '@utils/category';

export type ServiceAutoCompleteProps = {
  /**
   * Form ID used for react-hook-form.
   */
  id: string;

  /**
   * Indicate if external data is loading and should block rendering the available price list.
   * Mainly used to block rendering when the vehicle categories are loading during edit mode.
   */
  isLoading?: boolean;
};

export const ServiceAutocomplete = ({ id, isLoading }: ServiceAutoCompleteProps) => {
  const { data: categories, isLoading: isLoadingAvailableCategories, error } = useCategories();

  if (error) {
    return <div>Cannot load categories! Please contact support!</div>;
  }

  if (isLoading || isLoadingAvailableCategories || !categories) {
    return <Loader text="Loading services..." />;
  }

  return (
    <Stack direction="column">
      <FormAutocomplete
        id={id}
        options={categories.sort(
          (a, b) => -getParentCategory(b).localeCompare(getParentCategory(a)),
        )}
        groupBy={(option) => getParentCategory(option)}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label="Available services"
            placeholder="Search for the service(s) you are looking to book"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        )}
        multiple
      />
    </Stack>
  );
};
