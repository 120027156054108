import { useAuth } from '@hooks/useAuth';

interface ICurrentUser {
  userId: string;
  name: string;
  email: string;
  profilePictureURL?: string | null;
}

/**
 * Returns currently logged in user information.
 * @throws {Error} - User could not be found. This should never happen.
 */
export const useCurrentUser = (): { currentUser: ICurrentUser } => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    throw new Error('User not found when trying to fetch current user');
  }

  const { displayName, email, photoURL, uid } = currentUser;

  // TODO: Fetch information from Firestore and combine to generate a full user object if needed.
  return {
    currentUser: {
      userId: uid,
      name: displayName ?? 'No name',
      email: email ?? 'No email',
      profilePictureURL: photoURL,
    },
  };
};
