import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

type TextFieldTooltipProps = {
  /**
   * Tooltip text to show potential extra information
   */
  tooltip?: string;
};

export const TextFieldTooltip = ({ tooltip }: TextFieldTooltipProps) => {
  return tooltip ? (
    <Tooltip
      title={tooltip}
      sx={{
        fontSize: '1rem',
        cursor: 'pointer',
        marginLeft: 3,
        marginTop: -3,
      }}
    >
      <InfoOutlinedIcon />
    </Tooltip>
  ) : null;
};
