import { ProvinceCA } from '@uvac-apps/db-models';

// Sources: https://www.retailcouncil.org/resources/quick-facts/sales-tax-rates-by-province/
// https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/gst-hst-businesses/charge-collect-rates.html
const TAX_RATES = {
  [ProvinceCA.AB]: 0.05,
  [ProvinceCA.BC]: 0.12,
  [ProvinceCA.MB]: 0.12,
  [ProvinceCA.NB]: 0.15,
  [ProvinceCA.NL]: 0.15,
  [ProvinceCA.NT]: 0.05,
  [ProvinceCA.NS]: 0.15,
  [ProvinceCA.NU]: 0.05,
  [ProvinceCA.ON]: 0.13,
  [ProvinceCA.PE]: 0.15,
  [ProvinceCA.QC]: 0.14975,
  [ProvinceCA.SK]: 0.11,
  [ProvinceCA.YT]: 0.05,
  [ProvinceCA.Unknown]: 0,
};

// All lowercase options to match against user inputed provinces to try to capture edge cases
const PROVINCE_MATCHING = {
  [ProvinceCA.AB]: ['alberta', 'ab'],
  [ProvinceCA.BC]: ['british columbia', 'bc'],
  [ProvinceCA.MB]: ['manitoba', 'mb'],
  [ProvinceCA.NB]: ['new brunswick', 'nb'],
  [ProvinceCA.NL]: ['newfoundland and labrador', 'newfoundland', 'labrador', 'nl'],
  [ProvinceCA.NT]: ['northwest territories', 'northwest', 'nt'],
  [ProvinceCA.NS]: ['nova scotia', 'ns'],
  [ProvinceCA.NU]: ['nunavut', 'nu'],
  [ProvinceCA.ON]: ['ontario', 'on'],
  [ProvinceCA.PE]: ['prince edward island', 'prince edward', 'pei', 'pe'],
  [ProvinceCA.QC]: ['quebec', 'qc'],
  [ProvinceCA.SK]: ['saskatchewan', 'sk'],
  [ProvinceCA.YT]: ['yukon', 'the yukon', 'yt'],
};

const getProvince = (rawProvince: string): ProvinceCA => {
  // Reduce the complexity of the check by making the input lowercase
  const province = rawProvince.toLowerCase();

  // Iterate through the province matching object and return the key if the value array includes the province
  for (const [key, value] of Object.entries(PROVINCE_MATCHING)) {
    if (value.includes(province)) {
      return key as ProvinceCA;
    }
  }

  // If no match is found, return null
  return ProvinceCA.Unknown;
};

/**
 * Returns the tax rate for a given province
 *
 * @param rawProvince The province to get the tax rate for in a raw format
 * @returns The tax rate for the given province or 0 if no province is found along with the province key
 */
export const getTaxRate = (rawProvince: string): [number, ProvinceCA] => {
  const province = getProvince(rawProvince);
  return [TAX_RATES[province], province];
};
