import {
  VehicleStatus,
  DbVehicleField,
  DbCollection,
  DbAddressCoordinateField,
  DbCustomerProfileField,
} from '@uvac-apps/db-models';
import {
  collection as firestoreCollection,
  query,
  orderBy,
  startAt,
  endAt,
  getDocs,
  DocumentData,
  where,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { geohashQueryBounds, distanceBetween, Geopoint } from 'geofire-common';
import * as R from 'ramda';

import { firebaseDb } from '@lib/firebase';
import { getCustomerProfileByCustomerId } from '@lib/firestore/CustomerProfile';

// TODO: Rewrite this entire file! Super important functionality but it's gotten out of hand.

/**
 * Returns geo field information based on latitute, longitude and radius in KM
 */
const getGeoFields = ({
  lat,
  lng,
  radiusInKm,
}: {
  lat: number;
  lng: number;
  radiusInKm: number;
}) => {
  const center: Geopoint = [lat, lng];
  const radiusInM = radiusInKm * 1000;

  // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
  // a separate query for each pair. There can be up to 9 pairs of bounds
  // depending on overlap, but in most cases there are 4.
  const bounds = geohashQueryBounds(center, radiusInM);

  return {
    bounds,
    center,
    radiusInM,
  };
};

/**
 * Returns matching documents (includes filter by category and false positives)
 * @param documents - List of documents to filter
 */
const getMatchingDocuments = <T>(
  documents: QueryDocumentSnapshot<DocumentData>[],
  { addressField, radiusInKm, lat, lng }: GeoQueryVehicleOptions,
) => {
  const { center, radiusInM } = getGeoFields({ lat, lng, radiusInKm });

  const result = documents
    .map((doc) => {
      const lat = doc.get(`${addressField}.${DbAddressCoordinateField.latitude}`);
      const lng = doc.get(`${addressField}.${DbAddressCoordinateField.longitude}`);

      // We have to filter out a few false positives due to GeoHash
      // accuracy, but most will match
      const distanceInKm = distanceBetween([lat, lng], center);
      const distanceInM = distanceInKm * 1000;
      if (distanceInM <= radiusInM) {
        return {
          ...doc.data(),
          id: doc.id,
          distanceInKm: distanceInKm,
          distanceInM: distanceInM,
        };
      }
    })
    // Removes undefined/falsy values
    .filter(Boolean) as T[];

  // Sort by distance (closest first)
  return R.sortBy(R.prop('distanceInM'))(result);
};

/**
 * Returns a list of documents within a given radius of a center point
 * Returns only active vehicles
 * @param collection - Firestore collection
 */
export const getDocumentsWithinRadius = async <
  T extends DocumentData & { id: string; distanceInKm: number; distanceInM: number },
>(
  collection: DbCollection,
  options: GeoQueryVehicleOptions,
): Promise<T[]> => {
  const { lng, lat, radiusInKm, addressField, currentCustomerId, vendorIds = [] } = options;
  const { bounds } = getGeoFields({ lat, lng, radiusInKm });

  // Optionally filter by protected vendor
  const showAllVehicles = vendorIds.includes('all', 0);
  const filterVehicleQuery = showAllVehicles
    ? where(DbVehicleField.Status, '==', VehicleStatus.Active) // Random filter which is a duplicate
    : where(DbVehicleField.VendorId, 'in', vendorIds);

  // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
  // a separate query for each pair. There can be up to 9 pairs of bounds
  // depending on overlap, but in most cases there are 4.
  const promises = bounds.map((bound) => {
    const q = query(
      firestoreCollection(firebaseDb, collection),
      where(DbVehicleField.Status, '==', VehicleStatus.Active),
      filterVehicleQuery,
      orderBy(`${addressField}.${DbAddressCoordinateField.geoHash}`),
      startAt(bound[0]),
      endAt(bound[1]),
    );

    return getDocs(q);
  });

  // Collect all the query results together into a single list
  const snapshots = await Promise.all(promises);
  const documents = snapshots.flatMap((snapshot) => snapshot.docs);

  // Return matching documents (includes filter by category and false positives)
  const matchingDocs: T[] = getMatchingDocuments(documents, options);

  // Fetch custom pricing for each vehicle
  // TODO: Should be moved into proper SQL (or similar) queries post-firestore.
  return Promise.all(
    matchingDocs.map(async (doc: T) => {
      const customerProfile = await getCustomerProfileByCustomerId(
        doc[DbVehicleField.VendorId],
        currentCustomerId,
      );

      return {
        ...doc,
        discountRate: customerProfile?.[DbCustomerProfileField.DiscountRate] ?? 0,
      };
    }),
  );
};

/**
 * Returns the closest document within a given radius of a center point for a specific vendor
 * @param collection - Firestore collection
 * @deprecated - Use getDocumentsWithinRadius with vendorIds instead.
 */
export const getClosestDocumentWithinRadiusForVendor = async <
  T extends DocumentData & { id: string; distanceInKm: number; distanceInM: number },
>(
  collection: DbCollection,
  options: GeoQueryVehicleOptions,
): Promise<T | null> => {
  const { lng, lat, radiusInKm, addressField, currentCustomerId, vendorId } = options;
  const { bounds } = getGeoFields({ lat, lng, radiusInKm });

  if (!vendorId) {
    return null;
  }

  // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
  // a separate query for each pair. There can be up to 9 pairs of bounds
  // depending on overlap, but in most cases there are 4.
  const promises = bounds.map((bound) => {
    const q = query(
      firestoreCollection(firebaseDb, collection),
      orderBy(`${addressField}.${DbAddressCoordinateField.geoHash}`),
      where(DbVehicleField.VendorId, '==', vendorId),
      where(DbVehicleField.Status, '==', VehicleStatus.Active),
      startAt(bound[0]),
      endAt(bound[1]),
    );

    return getDocs(q);
  });

  // Collect all the query results together into a single list
  const snapshots = await Promise.all(promises);
  const documents = snapshots.flatMap((snapshot) => snapshot.docs);

  // Return matching documents (includes filter by category, status and false positives)
  const matchingDocs: T[] = getMatchingDocuments(documents, options);

  // If not filtering by customer, return regular prices by default
  if (!currentCustomerId) {
    const preferredVehicle = matchingDocs.length > 0 ? matchingDocs[0] : null;

    if (!preferredVehicle) {
      return null;
    }

    const customerProfile = await getCustomerProfileByCustomerId(vendorId, currentCustomerId);

    return {
      ...preferredVehicle,
      discountRate: customerProfile?.[DbCustomerProfileField.DiscountRate] ?? 0,
    };
  }

  // TODO: Add rate discount calculation!
  return null;
};

export interface GeoQueryVehicleOptions {
  lng: number;
  lat: number;
  currentCustomerId: string; // Required for discounted prices
  vendorId?: string;
  vendorIds?: string[];
  radiusInKm: number;
  addressField: string;
  categoryId: string;
}
