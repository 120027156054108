import Box from '@mui/material/Box';

type TextFieldErrorProps = {
  /**
   * Error message to show.
   */
  errorMessage?: string;
};

export const TextFieldError = ({ errorMessage }: TextFieldErrorProps) => (
  <Box
    sx={{
      // color: theme.palette.error.main,
      fontSize: '0.8rem',
      fontWeight: 400,
      marginTop: '5px',
    }}
  >
    {errorMessage}
  </Box>
);
