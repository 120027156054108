import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { RefCallBack } from 'react-hook-form';

import { FormLabel } from '@components/Label';
import { Color } from '@theme/palette';
import { withController } from '@utils/form';
import { getUniqueId } from '@utils/id';

type PartialProps<T> = {
  id?: string;

  /**
   * Built-in loading support
   */
  isLoading?: boolean;

  /**
   * Automatically render basic menu items with value/label
   */
  options?: {
    value: T;
    label: string;
  }[];

  /**
   * Renders custom list itmes if this is preferred instead of options.
   */
  children?: React.ReactNode;

  /**
   * Allows to set default value for the select.
   */
  defaultValue?: T;

  /**
   * Displays helper text when no options selected
   */
  placeholder?: string;

  /**
   * Allows to pass ref to the select when used together with react-hook-form.
   * Only use this when using react-hook-form.
   */
  innerRef?: RefCallBack;
};

export type UncontrolledSelectProps = PartialProps<string | number> &
  Omit<SelectProps<string | number>, 'variant'>;

/**
 * Returns enhanced MUI select component.
 */
export const Select = ({
  id = getUniqueId(),
  label,
  className,
  isLoading = false,
  placeholder = 'Select an option',
  children,
  options,
  innerRef,
  ...rest
}: UncontrolledSelectProps) => {
  return (
    <FormControl variant="outlined" size="small" className={className} sx={{ width: '100%' }}>
      {/* Optional label above input field */}
      {label && <FormLabel id={id} label={label} />}

      {isLoading ? (
        <Skeleton height="36px" sx={{ transform: 'none' }} />
      ) : (
        <MuiSelect
          id={id}
          ref={innerRef}
          labelId={id}
          renderValue={(selected) => {
            if (!selected) {
              return <span className="placeholder">{placeholder}</span>;
            }

            return options?.find(({ value }) => value === selected)?.label ?? selected;
          }}
          sx={{
            fontSize: 'inherit',
            '& .placeholder': {
              color: Color.Gray1,
            },
          }}
          displayEmpty
          fullWidth
          {...rest}
        >
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>

          {/* Default label/value items */}
          {options?.map(({ label, value }) => (
            <MenuItem value={value} key={value}>
              {label}
            </MenuItem>
          ))}

          {children}
        </MuiSelect>
      )}
    </FormControl>
  );
};

/**
 * Returns a Select component with a react-hook-form controller wrapped around.
 * This is the recommended way to use react-hook-form with MUI components.
 */
export const FormSelect = withController<
  Omit<SelectProps<string | number>, 'variant'> & UncontrolledSelectProps
>(Select);
