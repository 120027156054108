import { DbCollection } from '@uvac-apps/db-models';
import {
  DocumentData,
  DocumentSnapshot,
  doc,
  getDoc,
  collection as firestoreCollection,
  deleteDoc,
  setDoc,
  addDoc,
  getDocs,
  CollectionReference,
  query,
  QueryConstraint,
  collection,
  orderBy,
  limit,
  QueryDocumentSnapshot,
} from 'firebase/firestore';

import { firebaseDb as db } from '@lib/firebase';

/**
 * TODO: Below should preferably also be in the db-models package.
 * Throws an "Error fetching data:  FirebaseError:
 * Expected first argument to collection() to be a CollectionReference,
 * a DocumentReference or FirebaseFirestore" error
 * This is mainly due to how Firebase uses singletons and JS references instead of a clean functional approach.
 * Advised to moved this over once we switch DB to e.g. Postgres.
 */

// Allows us to convert Firestore data to a typed object
// Provides type safety and ensures we don't forget to convert the data
const getDbConverter = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
});

/**
 * Returns a document snapshot from the given collection and path.
 * @param collection - The collection to query.
 * @param path - A slash-separated path to a document.
 */
export const getDocSnapshot = <T extends DocumentData>(
  collection: DbCollection,
  path: string,
): Promise<DocumentSnapshot<T>> => {
  const converter = getDbConverter<T>();
  const docRef = doc(db, collection, path).withConverter(converter);
  return getDoc(docRef);
};

/**
 * Returns a document from the given collection and path.
 * @param collection - The collection to query.
 * @param path - A slash-separated path to a document.
 */
export const getDbDocumentById = async <T extends DocumentData & { id: string }>(
  collection: DbCollection,
  path: string,
): Promise<T | undefined> => {
  const docSnap = await getDocSnapshot<T>(collection, path);
  return { id: path, ...docSnap.data() } as T;
};

/**
 * Returns all documents from the given collection .
 * @param collection - The collection to query.
 * @param filters - Support for optional query filters.
 * @todo - Add support for nested properties (eg. parent on category)
 */
export const getDbDocuments = async <AppModelType, T extends DocumentData = any>(
  collection: DbCollection,
  ...filters: QueryConstraint[]
): Promise<(AppModelType & { id: string })[]> => {
  const collectionRef = firestoreCollection(db, collection) as CollectionReference<AppModelType, T>;

  // TODO: Add convert to guarantee type safety!
  const docSnap = await getDocs<AppModelType, T>(
    filters.length > 0 ? query(collectionRef, ...filters) : query(collectionRef),
  );

  return (docSnap || []).docs.map((doc) => {
    return {
      ...doc.data(),
      id: doc.id,
    };
  });
};

/**
 * Returns a field from a document in the given collection and path.
 * @param collection - The collection to query.
 * @param path - A slash-separated path to a document.
 * @param field - The field to return.
 */
export const getDbFieldValueById = async <T extends DocumentData, K = keyof T>(
  collection: DbCollection,
  path: string,
  field: keyof T, // TODO: Fix so Promise does not require K but checks that K is a key of T
): Promise<K | undefined> => {
  const docSnap = await getDocSnapshot<T>(collection, path);
  return docSnap.get(field as string);
};

/**
 * Adds a new document in the given collection.
 * @param collection - The collection to query.
 * @param values - Values to create.
 */
export const createDbDocument = async <T extends DocumentData>(
  collection: DbCollection,
  values: Omit<T, 'id'>, // ID should not be provided when creating a new document
) => {
  const collectionRef = firestoreCollection(db, collection);
  return addDoc(collectionRef, values);
};

/**
 * Updates a document in the given collection and path.
 * Will create document if it does not already exist.
 * @param collection - The collection to query.
 * @param path - A slash-separated path to a document.
 * @param values - Values to update. Gets merged with existing values.
 */
export const updateDbDocumentById = async <T extends DocumentData>(
  collection: DbCollection,
  path: string,
  values: Partial<T>,
) => {
  const converter = getDbConverter<T>();
  const docRef = doc(db, collection, path).withConverter(converter);
  return setDoc(docRef, values, { merge: true });
};

/**
 * Removes a document from the given collection and path.
 * @param collection - The collection to query.
 * @param path - A slash-separated path to a document.
 */
export const removeDbDocumentById = async (
  collection: DbCollection,
  path: string,
): Promise<void> => {
  const collectionRef = firestoreCollection(db, collection);
  const docRef = doc(collectionRef, path);
  await deleteDoc(docRef);
};

/**
 * Returns new incremental ID for a collection & specific field.
 */
export const getNewIncrementalId = async (
  collectionPath: DbCollection,
  incrementalKey = 'incremental_id',
): Promise<number> => {
  // Create a query against the collection to get the max incremental_id
  const q = query(collection(db, collectionPath), orderBy(incrementalKey, 'desc'), limit(1));

  const querySnapshot = await getDocs(q);

  // If there are no documents in the collection, return 1 as the first document
  if (querySnapshot.docs.length === 0) {
    return 1;
  }

  // Return the max incremental_id so far found + 1 to product the next incremental_id
  return querySnapshot.docs[0].data()[incrementalKey] + 1;
};
