import { useEffect, useState } from 'react';

import { getCurrentRoute } from '@utils/router';

/**
 * Returns current page title
 */
export const useGetPageTitle = () => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    const currentRoute = getCurrentRoute();

    return setTitle(currentRoute?.name || '');
  }, [window.location.pathname]);

  return { title };
};
