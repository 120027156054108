import { ServiceAutocomplete } from '@Customer/components/ServiceAutocomplete';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FormCheckbox } from '@components/Checkbox';
import { FormSelect } from '@components/Select';
import { FormTextField } from '@components/TextField';
import { JobField, PaymentTerm } from '@lib/firebase';

type BookingServicesProps = {
  /**
   * Indicate if external data is loading and should block rendering the available services.
   */
  isLoading?: boolean;
};

const allowedPaymentTerms = [
  { label: 'Net 0', value: PaymentTerm.Net0 },
  { label: 'Net 30', value: PaymentTerm.Net30 },
  { label: 'Net 60', value: PaymentTerm.Net60 },
];

export const BookingServices = ({ isLoading }: BookingServicesProps) => (
  <Stack direction="column" gap={2}>
    <ServiceAutocomplete id={JobField.SelectedCategories} isLoading={isLoading} />

    <FormSelect
      id={JobField.PaymentTerms}
      label="Payment Terms"
      placeholder="Select payment term"
      type="text"
      options={allowedPaymentTerms}
      defaultValue={PaymentTerm.Net30}
      isLoading={isLoading}
      required
    />

    <Stack direction="column" gap={1}>
      <Typography variant="h4">Job Site Information</Typography>

      <FormCheckbox id={JobField.IsUnionized} label="Unionized Crew Required" />

      <FormCheckbox id={JobField.DumpOnsite} label="Disposal is available onsite" />

      <FormCheckbox id={JobField.WaterOnSite} label="Water is available onsite" />

      <FormCheckbox
        id={JobField.RemoteHose}
        label="Remote hose is required (if Yes, please include amount in 'Notes')"
      />

      <FormTextField
        id={JobField.OtherConditions}
        label="Notes"
        placeholder="Any other conditions? (Ie. Confined space, heights, etc...)"
        type="text"
        minRows={3}
        isLoading={isLoading}
        multiline
      />
    </Stack>
  </Stack>
);
