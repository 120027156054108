import { matchPath } from 'react-router-dom';

import { customerRoutes } from '@routes/customer';
import { vendorRoutes } from '@routes/vendor';

import { IRouteConfig } from '../interfaces';

const outputType = process.env.OUTPUT_TYPE;

/**
 * Returns current route configuration based on path name.
 * Matches partial & exact routes (eg. /:id)
 */
export const getCurrentRoute = (): IRouteConfig | null => {
  // Get routes based on type of app
  const routes = outputType === 'customer' ? customerRoutes : vendorRoutes;

  // Try exact routing match
  const existingRoute = Object.values(routes).find((route) =>
    matchPath(route.path, window.location.pathname) ? route : false,
  );

  return existingRoute ?? null;
};
