import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import format from 'date-fns/format';

import { Card } from '@components/Card';
import { getFormattedAddressString } from '@utils/address';

import { ILocationForm, IServiceForm } from '../../fields';

type BookingOverviewProps = {
  /**
   * Requested service information
   */
  service?: IServiceForm;

  /**
   * Requested location & date information
   */
  locationDate?: ILocationForm;
};

export const BookingOverview = ({ service, locationDate }: BookingOverviewProps) => {
  // This should be impossible to happen, but just in case
  // Loads synchronously from form - no need for loading state
  if (!service || !locationDate) {
    return <div>Something went wrong loading your booking information.</div>;
  }

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack gap={2} direction="column">
            <Stack>
              <Box component="span" sx={{ fontWeight: 600 }}>
                Date & Time
              </Box>
              <Box component="span">
                {format(locationDate.scheduled_time, 'eee, dd MMM yyyy - hh:mm aaa')}
              </Box>
            </Stack>

            <Stack>
              <Box component="span" sx={{ fontWeight: 600 }}>
                Location
              </Box>
              <Box component="span">{getFormattedAddressString(locationDate)}</Box>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack gap={2} direction="column">
            <Stack>
              <Box component="span" sx={{ fontWeight: 600 }}>
                Requested Service(s)
              </Box>

              <ul>
                {(service.selected_categories || []).map((category, index) => (
                  <li key={`category-${index}`}>{category.name}</li>
                ))}
              </ul>
            </Stack>

            <Stack>
              <Box component="span" sx={{ fontWeight: 600 }}>
                Notes
              </Box>
              <Box component="span">{service.notes || 'No notes'}</Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};
