import { RateMode, VehicleWithDistance } from '@lib/firebase';

/**
 * Return price after pplying optional discount.
 * Discount is stored as a percentage (0-100).
 */
export const getDiscountedPrice = (price: number, discount: number = 0): number => {
  return ((100 - discount) * price) / 100;
};

/**
 * Returns vehicle price based on daily or hourly rate.
 * Used mainly by customers during booking.
 * @param values - Vehicle with distance.
 * @param showDailyRate - Whether to show daily rate or hourly rate.
 */
export const getVehicleBookingPrice = (
  { daily_price: dailyPrice, hourly_flat_price: hourlyPrice, discountRate = 0 }: VehiclePriceProps,
  showDailyRate?: boolean,
) => {
  // If daily rate is set, use it
  if (showDailyRate) {
    const price = dailyPrice ?? hourlyPrice * 8; // Defaults to 8 hours if daily rate is not set
    return {
      type: RateMode.Daily,
      price,
      discountRate,
      salePrice: getDiscountedPrice(price, discountRate),
    };
  }

  return {
    type: RateMode.Hourly,
    price: hourlyPrice,
    discountRate,
    salePrice: getDiscountedPrice(hourlyPrice, discountRate),
  };
};

type VehiclePriceProps = {
  daily_price: number | null;
  hourly_flat_price: number;
  discountRate?: number;
};
