import '@config/sentry';
import { StyledEngineProvider } from '@mui/material/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './providers/AuthProvider';
import { SnackBarProvider } from './providers/SnackBarProvider';
import { reportWebVitals } from './reportWebVitals';
import { Routes } from './routes';
import * as serviceWorker from './serviceWorker';
import { Theme } from './theme';

const rootElement = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <Theme>
        <AuthProvider>
          <SnackBarProvider>
            <StyledEngineProvider injectFirst>
              <Routes />
            </StyledEngineProvider>
          </SnackBarProvider>
        </AuthProvider>
      </Theme>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO: Check whether this increases load time on production even if not turned on!
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
